import { DatePicker, Flex, Selector, Toggle, Typography } from '@nstrlabs/ixel';
import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import styles from './index.module.css';

// TODO manage with timeZone settings
const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

type SecretCreateConfigurationProps = {
  secretExpirationDateError: string | undefined;
  onChangeFieldsErrors: (field: string, value: string | undefined) => void;
  requiredFieldsNotEmpty: (field: string, fieldValue: boolean) => void;
};

const SecretCreateConfiguration = ({
  secretExpirationDateError,
  onChangeFieldsErrors,
  requiredFieldsNotEmpty,
}: SecretCreateConfigurationProps) => {
  const { t } = useTranslation('translation');
  const [secretExpirationDate, setSecretExpirationDate] = useState<string>();
  const [secretExpirationDateActive, setSecretExpirationDateActive] =
    useState<boolean>(true);
  const [secretActive] = useState<'ACTIVE' | 'DISABLED'>('ACTIVE');
  const nodeRef = useRef(null);

  return (
    <Flex flexDirection="column" gap="xl">
      <Flex flexDirection="column" gap="xs">
        <Flex justifyContent="spaceBetween">
          <Typography variant="body" bold>
            {t(
              'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CONFIGURATION.EXPIRATION_DATE_LABEL',
            )}
          </Typography>
          <Toggle
            checked={secretExpirationDateActive}
            onChange={(event) => {
              setSecretExpirationDateActive(event.target.checked);
              setSecretExpirationDate('');
              requiredFieldsNotEmpty('expirationDate', !event.target.checked);
            }}
            data-cy="toggle-expiration-date"
          />
        </Flex>
        <Typography
          className={styles.description}
          variant="body"
          color="tertiary"
        >
          {t(
            'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CONFIGURATION.EXPIRATION_DATE_DESCRIPTION',
          )}
        </Typography>
        <CSSTransition
          in={secretExpirationDateActive}
          nodeRef={nodeRef}
          timeout={200}
          appear
          classNames="transit"
        >
          <div ref={nodeRef} className={styles.transitContainer}>
            <DatePicker
              disabled={!secretExpirationDateActive}
              placement="bottom-start"
              date={secretExpirationDate}
              onChangeDate={(date) => {
                const TZ = utcToZonedTime(new Date(date), TIME_ZONE);
                const dateUtc: string = format(TZ, "yyyy-MM-dd'T'HH:mm:ssXXX");
                onChangeFieldsErrors('expirationDate', undefined);
                setSecretExpirationDate(dateUtc);
                requiredFieldsNotEmpty('expirationDate', true);
              }}
              triggerElement={
                <Selector
                  disabled={!secretExpirationDateActive}
                  id="expirationDate"
                  selectorName="expirationDate"
                  value={secretExpirationDate}
                  renderValue={() =>
                    secretExpirationDate
                      ? format(new Date(secretExpirationDate), 'dd/MM/yyyy')
                      : ''
                  }
                  format="regular"
                  helperText=""
                  label={secretExpirationDate ? 'Date' : 'Select date'}
                  onChange={() => {}}
                  type="text"
                  error={
                    typeof secretExpirationDateError === 'string'
                      ? secretExpirationDateError
                      : undefined
                  }
                />
              }
              isOpen={false}
            />
          </div>
        </CSSTransition>
      </Flex>
      <Flex flexDirection="column" gap="xs">
        <Flex justifyContent="spaceBetween">
          <Typography variant="body" bold>
            {t(
              'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CONFIGURATION.SECRET_ACTIVE_LABEL',
            )}
          </Typography>
          <Toggle
            disabled
            checked={secretActive === 'ACTIVE'}
            data-cy="toggle-secret-active"
          />
        </Flex>
        <Typography
          className={styles.description}
          variant="body"
          color="tertiary"
        >
          {t(
            'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CONFIGURATION.SECRET_ACTIVE_DESCRIPTION',
          )}
        </Typography>
      </Flex>
    </Flex>
  );
};

export default SecretCreateConfiguration;
