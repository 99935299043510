import type { ActivityTimestamp } from '../context/auth/domain/ActivityTimestamp';

export const SESSION_TIMEOUT_LOGOUT = 60 * 60 * 1000;
export const DEBOUNCE_ACTIVITY_TIME = 2 * 1000;

export const lastActivityTimestampExpired = (
  activityTimestamp: ActivityTimestamp | null,
) =>
  activityTimestamp == null ||
  Date.now() - activityTimestamp + DEBOUNCE_ACTIVITY_TIME >=
    SESSION_TIMEOUT_LOGOUT;
