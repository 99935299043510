import type {
  Filter,
  FilterProviderState,
  FilterToSelect,
} from './FiltersProvider';

type AddRemoveSaveFilter = {
  filter: Filter;
  filtersToSelect?: FilterToSelect[];
  filters?: Filter[];
};

/**
 * Function to add a filter, if we allow duplicated we don't need
 * to remove it on the array of filters to select
 * @param AddRemoveSaveFilter
 * @returns { FilterToSelect, Filters }
 */
export const addFilter = ({
  filter,
  filtersToSelect = [],
  filters = [],
}: AddRemoveSaveFilter) => {
  const filtersToSelectToSave = filtersToSelect.filter(
    ({ field }) => field !== filter.field,
  );
  const filtersToSave: Filter[] = [
    ...filters,
    { ...filter, id: crypto.randomUUID() },
  ];
  return {
    filtersToSelect: filter.allowDuplicates
      ? filtersToSelect
      : filtersToSelectToSave,
    filters: filtersToSave,
  };
};

/**
 * Function to remove a filter by id, if we allow duplicated we don't need
 * to include again on the array of filters to select
 * @param AddRemoveSaveFilter
 * @returns  { FilterToSelect, Filters }
 */
export const removeFilter = ({
  filter,
  filtersToSelect = [],
  filters = [],
}: AddRemoveSaveFilter): FilterProviderState => {
  const valueRangeData = filter.type === 'date' ? { value: filter.value } : {};
  const dataCategory =
    filter.type === 'category' ? { ...filter, value: [] } : {};
  const optionsSelector =
    filter.type === 'selector' ? { options: filter.options } : {};

  const filtersToSelectToSave = {
    field: filter.field,
    label: filter.label,
    type: filter.type,
    iconName: filter.iconName,
    allowDuplicates: filter.allowDuplicates ?? false,
    isOpen: filter.isOpen ?? true,
    isFixed: filter.isFixed ?? false,
    isSuggested: filter.isSuggested ?? false,
    ...valueRangeData,
    ...dataCategory,
    ...optionsSelector,
  } as FilterToSelect;

  const filtersToSave = filters.filter(({ id }) => id !== filter.id);

  return {
    filtersToSelect: filter.allowDuplicates
      ? filtersToSelect
      : [...filtersToSelect, filtersToSelectToSave],
    filters: filtersToSave,
  };
};

/**
 * Function to save configuration on filter previously added
 * @param AddRemoveSaveFilter
 * @returns  { FilterToSelect, Filters }
 */
export const saveFilterConfiguration = ({
  filter,
  filtersToSelect = [],
  filters = [],
}: AddRemoveSaveFilter): FilterProviderState => {
  const result = filters.map((filterSaved) => {
    if (filterSaved.id === filter.id) {
      return { ...filter };
    }
    return filterSaved;
  });
  return {
    filtersToSelect,
    filters: result,
  };
};

export const resetFilters = (filters: Filter[]) => {
  const filtersToSelectToSave = filters.map((filter) => {
    const valueRangeData =
      filter.type === 'date' ? { value: filter.value } : {};
    const dataCategory =
      filter.type === 'category' ? { data: filter.data } : {};
    const optionsSelector =
      filter.type === 'selector' ? { options: filter.options } : {};

    const filtersToSelectToSave = {
      field: filter.field,
      label: filter.label,
      type: filter.type,
      allowDuplicates: filter.allowDuplicates ?? false,
      isOpen: filter.isOpen ?? true,
      isFixed: filter.isFixed ?? false,
      isSuggested: filter.isSuggested ?? false,
      ...valueRangeData,
      ...dataCategory,
      ...optionsSelector,
    } as FilterToSelect;

    return filtersToSelectToSave;
  });

  return {
    filtersToSelect: filtersToSelectToSave,
    filters: [],
  };
};

/**
 *
 * GENERIC FUNCS
 *
 */

export const findFilter = (filterToFind: string, filters: Filter[]) => {
  const filter = filters.find((arr) => arr.field === filterToFind);
  if (filter) {
    return filter?.value;
  }
  return null;
};

export const findFilterAndOperation = (
  filterToFind: string,
  filters: Filter[],
) => {
  const filter = filters.find((arr) => arr.field === filterToFind);
  if (filter) {
    return {
      value: filter.value,
      operation: (filter as { operation?: string })?.operation,
    };
  }
  return null;
};

export const getRangeData = (filters: Filter[]) =>
  findFilter('rangeData', filters);
