import { get, set } from 'lodash';
import code_errors from './code_errors.json';
import translation from './translation.json';

type Translation = {
  [key: string]: Translation | string;
};

const I18N_LANGUAGES = ['en', 'es'];

// the translations
const resources = {
  en: {
    translation: {},
  },
  es: {
    translation: {},
  },
};

function isI18nLabel(child: Translation) {
  let isI18n = true;
  const childKeys = Object.keys(child);
  if (I18N_LANGUAGES.length !== childKeys.length) return false;
  childKeys.forEach((key) => {
    isI18n =
      isI18n &&
      I18N_LANGUAGES.indexOf(key) >= 0 &&
      typeof child[key] === 'string';
  });
  return isI18n;
}

function addToResourcesObject(path: string[], key: string, child: Translation) {
  I18N_LANGUAGES.forEach((lang) => {
    set(resources, [lang, 'translation', ...path, key], child[lang]);
  });
}

function loadResourcesFromTree(tree: Translation, path: string[] = []) {
  const node = get(tree, path, tree);
  Object.keys(node).forEach((key) => {
    const child = node[key];
    if (isI18nLabel(child)) addToResourcesObject(path, key, child);
    else loadResourcesFromTree(tree, [...path, key]);
  });
}

loadResourcesFromTree(translation as unknown as Translation);
loadResourcesFromTree(code_errors as unknown as Translation);

export default resources;
