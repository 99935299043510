import clsx from 'clsx';
import type { ReactNode } from 'react';

import styles from './index.module.css';

export type MediaProps = {
  media: ReactNode;
  content: ReactNode;
  className?: string;
};
const Media = ({ media, content, className }: MediaProps) => (
  <div className={clsx(styles.container, className)}>
    <div className={styles.media}>{media}</div>
    <div className={styles.content}>{content}</div>
  </div>
);

export default Media;
