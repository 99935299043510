import { Icon } from '@nstrlabs/ixel';
import { useCanAccess } from '@nstrlabs/sdk';
import type { ReactNode } from 'react';

export type MenuOption = {
  to?: string;
  translateKey: string;
  icon?: ReactNode;
  activeOn?: string;
  resource: string;
  options?: MenuOptions;
  featureFlag?: string;
};

export type MenuOptions = MenuOption[];

/**
 * The definition of the menu with the specified resource to have access to.
 * If no resource defined, the option menu have to be created.
 */
export const resourceOptions: MenuOptions = [
  {
    translateKey: 'MENU_TITLE',
    resource: '',
    options: [
      {
        icon: <Icon name="home" />,
        to: '/home',
        translateKey: 'MENU.HOME',
        activeOn: '/home',
        resource: '',
      },
      {
        icon: <Icon name="source" />,
        to: '/listeners',
        translateKey: 'MENU.LISTENERS',
        activeOn: '/listeners',
        resource: 'listener',
      },
      {
        icon: <Icon name="workflow" />,
        to: '/pipelines',
        translateKey: 'MENU.PIPELINES',
        activeOn: '/pipelines',
        resource: 'pipeline',
      },
      {
        icon: <Icon name="destination" />,
        to: '/datasinks',
        translateKey: 'MENU.DATASINKS',
        activeOn: '/datasinks',
        resource: 'datasink',
      },
      {
        icon: <Icon name="alert" />,
        to: '/alerts',
        translateKey: 'MENU.ALERTS',
        activeOn: '/alerts',
        resource: 'datasink',
        featureFlag: 'alerts',
      },
    ],
  },
  {
    translateKey: 'VAULT_TITLE',
    resource: '',
    options: [
      {
        icon: <Icon name="enrichement" />,
        to: '/enrichments',
        translateKey: 'MENU.ENRICHMENT',
        activeOn: '/enrichments',
        resource: 'datasink',
        featureFlag: 'enrichment',
      },
      {
        icon: <Icon name="data" />,
        to: '/datahistory',
        translateKey: 'MENU.DATA_HISTORY',
        activeOn: '/datahistory',
        resource: 'datasink',
        featureFlag: 'kronograph',
      },
      {
        icon: <Icon name="action" />,
        to: '/actions',
        translateKey: 'MENU.ACTIONS',
        activeOn: '/actions',
        resource: 'action',
        featureFlag: 'update-actions',
      },
    ],
  },
];

/**
 * Extracted functionality to handle the testing purposes
 * Return the list of all main menu options available for the current user with the current
 * role and access settings.
 * @param canAccess hook to know whether the user can access to the action-resource
 * @returns list of all menu options available for the current user
 */
export const buildAccessOptions = (
  availableOptions: MenuOptions,
  canAccess: (accessObj: { action: string; resource: string }) => boolean,
) => {
  const accessOptions: MenuOptions = [];

  availableOptions.forEach((option) => {
    if (option.options) {
      accessOptions.push({
        ...option,
        options: buildAccessOptions(option.options, canAccess),
      });
    } else if (
      !option.resource ||
      canAccess({ action: 'read', resource: option.resource })
    ) {
      accessOptions.push({ ...option });
    }
  });
  return accessOptions;
};

/**
 * Returns the list of menu options for the current user checking
 * the allowed resources of the current user
 * */
export const useMenuOptions = (): MenuOptions => {
  const { canAccess } = useCanAccess();

  return buildAccessOptions(resourceOptions, canAccess);
};
