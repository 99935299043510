import { useLogger } from '@nstrlabs/sdk';
import { isEmptyString } from '@nstrlabs/utils';
import { setUser as setSentryUser } from '@sentry/browser';
import { type ReactNode, createContext, useContext, useMemo } from 'react';

export const permissionsAreEqual = (value: AccessMap, other: AccessMap) => {
  const accessMapRolesAreEqual =
    value.Roles.length === other.Roles.length
      ? value.Roles.every((tenant) => other.Roles.includes(tenant))
      : false;

  const resourcesOld = Object.keys(value.Resources);
  const resourcesNew = Object.keys(other.Resources);
  const accessMapResourcesAreEqual =
    resourcesOld.length === resourcesNew.length
      ? resourcesOld.every((resource) =>
          value.Resources[resource]?.every((access) =>
            other.Resources[resource]?.includes(access),
          ),
        )
      : false;

  return accessMapRolesAreEqual && accessMapResourcesAreEqual;
};

export const mergeUsers = (oldUser: User | null, newUser: User) => {
  if (oldUser === null) return newUser;

  const tenantsAreEqual =
    oldUser.Tenants.length === newUser.Tenants.length
      ? oldUser.Tenants.every((tenant) => newUser.Tenants.includes(tenant))
      : false;

  const accessMapAreEqual = permissionsAreEqual(
    oldUser.AccessMap,
    newUser.AccessMap,
  );

  if (
    oldUser.PkId !== newUser.PkId ||
    oldUser.TenantId !== newUser.TenantId ||
    oldUser.UID !== newUser.UID ||
    oldUser.UserName !== newUser.UserName
  )
    return {
      ...newUser,
      Tenants: tenantsAreEqual ? oldUser.Tenants : newUser.Tenants,
      AccessMap: accessMapAreEqual ? oldUser.AccessMap : newUser.AccessMap,
    };

  if (!tenantsAreEqual || !accessMapAreEqual)
    return {
      ...oldUser,
      Tenants: tenantsAreEqual ? oldUser.Tenants : newUser.Tenants,
      AccessMap: accessMapAreEqual ? oldUser.AccessMap : newUser.AccessMap,
    };

  return oldUser;
};

type Resources = Record<string, string[]>;
type AccessMap = {
  Roles: string[];
  Resources: Resources;
};

export type User = {
  Email: string;
  PkId: string;
  TenantId: string;
  Tenants: string[];
  UID: string;
  UserName: string;
  AccessMap: AccessMap;
};

export type UserContextType = User & {
  isLoggedIn: boolean;
};

export const EMPTY_USER: UserContextType = {
  Email: '',
  PkId: '',
  TenantId: '',
  Tenants: [],
  UID: '',
  UserName: '',
  isLoggedIn: false,
  AccessMap: {
    Roles: [''],
    Resources: {},
  },
};

export const isValidUser = ({
  Email,
  PkId,
  TenantId,
  UID,
  UserName,
}: User): boolean =>
  !isEmptyString(Email) &&
  !isEmptyString(PkId) &&
  !isEmptyString(TenantId) &&
  !isEmptyString(UID) &&
  !isEmptyString(UserName);

export const UserContext = createContext<UserContextType>(EMPTY_USER);

export const UseUserProvider = (props: {
  children?: ReactNode;
  value: User | null;
}) => {
  const logger = useLogger();
  const user = useMemo(
    () =>
      props.value == null
        ? EMPTY_USER
        : {
            ...props.value,
            isLoggedIn: true,
          },
    [props.value],
  );

  // @ts-ignore
  logger.tenant = user.TenantId;
  // @ts-ignore
  logger.userId = user.UID;
  // @ts-ignore
  logger.userEmail = user.Email;
  const sessionId = crypto.randomUUID();
  // @ts-ignore
  logger.sessionId = sessionId;
  setSentryUser({ email: user.Email, id: sessionId });

  return <UserContext.Provider {...props} value={user} />;
};

export class UseUserError extends Error {
  constructor() {
    super('useAuth should be used within a provider');
  }
}

export const useUser = (): UserContextType => {
  const container = useContext(UserContext);

  if (container === null) {
    throw new UseUserError();
  }

  return container;
};
