import { Accordion, Button, Flex, Icon, Tag, Typography } from '@nstrlabs/ixel';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { match } from 'ts-pattern';
import { handleOpenItem } from '.';
import type { UseSecretUsages } from '../../../../hooks/organizationSettings/secrets/useSecretUsages';
import type { ReleasedVersionProps } from '../../../molecules/ReleasedVersion';
import styles from './index.module.css';

const handleOpenPipeline = (id: string, version: number) => {
  handleOpenItem(`/pipelines/${id}/${version}`);
};

export const PipelineListComponent = ({
  pipelines,
}: { pipelines: UseSecretUsages['usages']['pipelines'] }) => {
  const { t } = useTranslation();

  return (
    <Accordion
      as="section"
      title={capitalize(t('GLOBAL.PIPELINES'))}
      items={pipelines.length}
    >
      <Flex flexDirection="column" gap="md">
        {pipelines.map((pipeline) => {
          const { id, name, versions } = pipeline;

          return (
            <Flex key={id} flexDirection="column" gap="xs">
              <Typography bold style={{ marginLeft: '8px' }}>
                {name}
              </Typography>
              <Flex flexDirection="column" className={styles.versionList}>
                {versions.map((version) => (
                  <Flex
                    key={`${id}-${version.version}`}
                    gap="md"
                    flexDirection="row"
                    alignContent="center"
                    justifyContent="spaceBetween"
                    className={styles.itemRow}
                  >
                    <Flex gap="md" alignItems="center">
                      <Typography color="secondary">
                        {version.versionName}
                      </Typography>
                      <Button
                        icon={<Icon name="open" />}
                        size="small"
                        mode="primary"
                        variant="link"
                        onClick={() => handleOpenPipeline(id, version.version)}
                      />
                    </Flex>
                    <Flex gap="md" alignItems="center">
                      <Tag
                        variant={match<
                          ReleasedVersionProps['status'],
                          React.ComponentProps<typeof Tag>['variant']
                        >(version.status)
                          .with('RUNNING', () => 'published')
                          .with('STOPPED', () => 'destructive')
                          .otherwise(() => 'generic')}
                        text={t(
                          match<ReleasedVersionProps['status']>(version.status)
                            .with('RUNNING', () => 'PIPELINES.STATUS.RUNNING')
                            .with('STOPPED', () => 'PIPELINES.STATUS.PAUSED')
                            .otherwise(() => 'PIPELINES.STATUS.DRAFT'),
                        )}
                      />
                    </Flex>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Accordion>
  );
};
