import {
  type DomainCause,
  type Either,
  type Value,
  domainError,
} from '@nstrlabs/utils';
import {
  type InvalidIdPrefix,
  type InvalidIdUuid,
  idValue,
} from '../../shared/domain/IdValue';

export type TenantId = Value<string, 'tenant_id'>;

export const invalidTenantId = domainError<
  DomainCause<InvalidIdPrefix | InvalidIdUuid>
>()('tenant:id', 'WEB-00079', 'The id is invalid');

export type InvalidTenantId = ReturnType<typeof invalidTenantId>;

export const makeTenantId = (
  value: string,
): Either<InvalidTenantId, TenantId> =>
  idValue('TN', value).mapLeft((cause) => invalidTenantId({ cause }));
