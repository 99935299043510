import { Either } from '@nstrlabs/utils';

import { makeActivityTimestamp } from '../domain/ActivityTimestamp';

import type { AuthRepository } from '../domain/AuthRepository';

const SESSION_STORAGE_KEY = 'ActivityTimestamp';

export class NoAuthActivityTimestampFoundError extends Error {
  constructor() {
    super('No activity timestamp found');
  }
}

export const getActivityTimestamp: AuthRepository['getActivityTimestamp'] =
  async () => {
    const storedActivityTimestampStr =
      localStorage.getItem(SESSION_STORAGE_KEY);

    if (typeof storedActivityTimestampStr !== 'string') {
      return Either.toPromise(makeActivityTimestamp(null));
    }

    return Either.toPromise(
      makeActivityTimestamp(Number(storedActivityTimestampStr)),
    );
  };

export const saveActivityTimestamp: AuthRepository['saveActivityTimestamp'] =
  () => {
    const activityTimestampValue = Date.now().toString();
    localStorage.setItem(SESSION_STORAGE_KEY, activityTimestampValue);
  };

export const removeActivityTimestamp: AuthRepository['removeActivityTimestamp'] =
  () => localStorage.removeItem(SESSION_STORAGE_KEY);
