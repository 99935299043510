import { Button, Flex, Scroll } from '@nstrlabs/ixel';
import { normalizeIncludes } from '@nstrlabs/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/atoms/Separator';
import SettingsHeader from '../../../components/atoms/SettingsHeader';
import HomesSearch from '../../../components/organisms/HomesSearch';
import SecretListItem from '../../../components/organisms/SecretListItem';
import { useSecrets } from '../../../hooks/organizationSettings/secrets/useSecrets';
import SecretCreate from '../SecretCreate';
import SecretsZeroCase from './SecretsZeroCase';
import styles from './index.module.css';

const SecretsManagement = () => {
  const { secrets, isLoading } = useSecrets();
  const { t } = useTranslation('translation', {
    keyPrefix: 'SETTINGS.ORGANIZATION_SETTINGS',
  });
  const [search, setSearch] = useState('');
  const [isOpenCreateSecret, setIsOpenCreateSecret] = useState(false);

  const handleClickCreate = () => {
    setIsOpenCreateSecret(true);
  };

  return (
    <>
      <Scroll className={styles.scrollContainer} paddingX="xl">
        <Flex
          flexDirection="column"
          alignItems="center"
          className={styles.container}
        >
          <Flex flexDirection="column" className={styles.content}>
            <SettingsHeader
              squareClassName={styles.square}
              title={t('TITLE')}
              description={t('DESCRIPTION')}
              icon="lock"
            />
            <Separator className={styles.separator} />
            {secrets.length === 0 && !isLoading ? (
              <SecretsZeroCase onCreateClick={handleClickCreate} />
            ) : (
              <>
                <Flex
                  justifyContent="spaceBetween"
                  className={styles.searchHeader}
                >
                  <HomesSearch
                    value={search}
                    alwaysActive
                    onSearch={(event) => setSearch(event.target.value)}
                  />
                  <Button
                    onClick={handleClickCreate}
                    data-cy="create-new-secret-button"
                  >
                    {t('SECRETS_MANAGEMENT.NEW_SECRET')}
                  </Button>
                </Flex>
                <Flex flexDirection="column" gap="md">
                  {secrets.map(
                    ({ id, name, description, expirationDate, tags, status }) =>
                      normalizeIncludes(name, search) && (
                        <SecretListItem
                          key={name}
                          id={id}
                          name={name}
                          description={description}
                          expirationDate={expirationDate}
                          tags={tags}
                          status={status}
                        />
                      ),
                  )}
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Scroll>
      {isOpenCreateSecret ? (
        <SecretCreate handleCloseStackedview={setIsOpenCreateSecret} />
      ) : null}
    </>
  );
};

export default SecretsManagement;
