import { Flex } from '@nstrlabs/ixel';
import { useMeasure } from '@nstrlabs/ixel/dist/hooks/useMeasure';
import clsx from 'clsx';
import {
  type CSSProperties,
  type ComponentPropsWithoutRef,
  type ReactNode,
  useMemo,
} from 'react';
import styles from './index.module.css';

type HeaderProps = {
  header?: ReactNode;
  content: ReactNode;
  contentClassName?: string;
  footerClassName?: string;
  footer?: ReactNode;
} & Omit<ComponentPropsWithoutRef<'article'>, 'content'>;

const ModalHeader = ({
  className,
  contentClassName,
  footerClassName,
  header,
  content,
  footer,
  ...props
}: HeaderProps) => {
  const [headerRect, headerRef] = useMeasure();
  const [footerRect, footerRef] = useMeasure();

  const contentHeight = useMemo(
    (): CSSProperties => ({
      height: `calc(100% - ${headerRect.totalHeight ?? 0}px - ${
        footerRect.totalHeight ?? 0
      }px)`,
    }),
    [headerRect, footerRect],
  );

  return (
    <Flex
      as="article"
      flexDirection="column"
      className={clsx('ModalHeader-Root', className, styles.modal)}
      {...props}
    >
      {header ? (
        <header
          ref={headerRef}
          className={clsx('ModalHeader-Header', styles.header)}
        >
          {header}
        </header>
      ) : null}
      <div
        style={contentHeight}
        className={clsx(
          'ModalHeader-Content',
          contentClassName,
          styles.content,
        )}
      >
        {content}
      </div>
      {footer ? (
        <div
          ref={footerRef}
          className={clsx('ModalHeader-Footer', styles.footer, footerClassName)}
        >
          {footer}
        </div>
      ) : null}
    </Flex>
  );
};

export default ModalHeader;
