import { type ReactNode, createContext, useContext } from 'react';
import type { ToastCommand } from '../components/providers/ToastRoot/parseToastCommand';

export type UseToastContextType = {
  publish: (event: ToastCommand) => void;
  subscribe: (fn: (toast: ToastCommand) => void) => () => void;
};

const UseToastContext = createContext<UseToastContextType | null>(null);

export const UseToastProvider = (props: {
  children?: ReactNode;
  value: UseToastContextType;
}) => <UseToastContext.Provider {...props} />;

export class UseToastError extends Error {
  constructor() {
    super('useToast should be used within a provider');
  }
}

export const useToast = (): UseToastContextType => {
  const context = useContext(UseToastContext);

  if (context === null) {
    throw new UseToastError();
  }

  return context;
};
