import { Flex, Icon, PasswordField, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { VALIDATOR, validationBuilder } from '../../../utils/validations';
import styles from './index.module.css';

type NewPasswordProps = {
  newPassword: string;
  onNewPassword: React.ChangeEventHandler<HTMLInputElement>;
  confirmNewPassword: string;
  onConfirmNewPassword: React.ChangeEventHandler<HTMLInputElement>;
  applyShowConfirmError: () => void;
  showConfirmError: boolean;
  errors?: {
    MIN_LENGTH: boolean;
    ERROR_NUMBER: boolean;
    ERROR_CAP: boolean;
    ERROR_LOWER: boolean;
    ERROR_SPECIAL: boolean;
  };
};

export const isNewPasswordSecure = (
  newPassword: string,
  confirmNewPassword: string,
  passwordIsSecureErrors: Array<unknown>,
) =>
  newPassword !== '' &&
  confirmNewPassword !== '' &&
  newPassword === confirmNewPassword &&
  passwordIsSecureErrors.length === 0;

export const STRONG_PASSWORD_VALIDATION = [
  validationBuilder(VALIDATOR.HAS_STRING_VALID_MIN_LENGTH(8), 'MIN_LENGTH'),
  validationBuilder(VALIDATOR.HAS_SOME_NUMBER, 'ERROR_NUMBER'),
  validationBuilder(VALIDATOR.HAS_SOME_CAP, 'ERROR_CAP'),
  validationBuilder(VALIDATOR.HAS_SOME_LOWER_CASE, 'ERROR_LOWER'),
  validationBuilder(VALIDATOR.HAS_SOME_SPECIAL_CHAR, 'ERROR_SPECIAL'),
  validationBuilder(VALIDATOR.HAS_STRING_VALID_MAX_LENGTH(100), 'MAX_LENGTH'),
];

const NewPassword = ({
  newPassword,
  onNewPassword,
  confirmNewPassword,
  onConfirmNewPassword,
  applyShowConfirmError,
  showConfirmError,
  errors = {
    MIN_LENGTH: true,
    ERROR_NUMBER: true,
    ERROR_CAP: true,
    ERROR_LOWER: true,
    ERROR_SPECIAL: true,
  },
}: NewPasswordProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'SETTINGS.MAIL_PASSWORD',
  });

  const validations = Object.values(errors);
  const passedValidations = validations.filter(Boolean);

  const passPercentage = (passedValidations.length * 100) / validations.length;
  const isValid = passedValidations.length === validations.length;

  return (
    <>
      <PasswordField
        id="user_new_password"
        label={t('NEW_PASSWORD_INPUT_LABEL')}
        value={newPassword}
        minLength={8}
        maxLength={100}
        onChange={onNewPassword}
      />
      <PasswordField
        id="user_confirm_new_password"
        label={t('CONFIRM_PASSWORD_INPUT_LABEL')}
        value={confirmNewPassword}
        minLength={8}
        maxLength={100}
        onChange={onConfirmNewPassword}
        onBlur={applyShowConfirmError}
        error={
          showConfirmError && newPassword !== confirmNewPassword
            ? t('CONFIRM_PASSWORD_ERROR')
            : undefined
        }
      />
      <div
        className={clsx(
          styles.percentage,
          isValid ? styles.valid : styles.invalid,
        )}
      >
        <div
          style={{
            maxWidth: `${passPercentage}%`,
          }}
        />
      </div>
      <Flex justifyContent="spaceBetween">
        <Flex flexDirection="column" gap="xs">
          {Object.keys(errors).map((key) => {
            const valid = errors[key as keyof typeof errors];

            return (
              <Flex gap="xs" key={key}>
                <Icon
                  className={valid ? styles.valid : styles.invalid}
                  name={valid ? 'check' : 'uncheck'}
                />
                <Typography>{t(key)}</Typography>
              </Flex>
            );
          })}
        </Flex>
        <Typography bold>
          {t(isValid ? 'STRONG_PASSWORD' : 'WEAK_PASSWORD')}
        </Typography>
      </Flex>
    </>
  );
};

export default NewPassword;
