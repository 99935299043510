import { type EventBus, emitResponse, onAsk } from '@nstrlabs/sdk';
import { z } from 'zod';
import { type UseCasesKey, container } from './index';

type Resolve =
  | {
      success: unknown;
      command: unknown;
    }
  | {
      failure: unknown;
      command: unknown;
    };

const eventSchema = z.object({
  uuid: z.string(),
  command: z.any(),
});

const loadQueryResolvers = <
  Resolvers extends {
    [target: string]: (command: unknown) => Promise<unknown>;
  },
>(
  resolvers: Resolvers,
  instance?: EventBus,
) => {
  Object.keys(resolvers).forEach((target) => {
    onAsk(target, (event) => {
      const parsed = eventSchema.safeParse(event);
      if (!parsed.success) return;

      void resolvers[target](parsed.data.command)
        .then(
          (success) => ({ command: parsed.data.command, success }) as Resolve,
        )
        .catch(
          (failure) =>
            ({
              command: parsed.data.command,
              failure: failure as unknown,
            }) as Resolve,
        )
        .then((resolve) =>
          emitResponse({ target, resolve, uuid: parsed.data.uuid }, instance),
        );
    });
  }, instance);
};

const buildQueryResolver = (useCase: UseCasesKey) => (command: any) =>
  container.call(useCase, command);

export const init = (instance?: EventBus) => {
  loadQueryResolvers(
    {
      'listener:id': buildQueryResolver('listeners:findById'),
      getAuthToken: buildQueryResolver('auth:getAuthToken'),
      'pipelines:internalPipeline:create': buildQueryResolver(
        'pipelines:createInternalPipeline',
      ),
      'pipelines:internalPipeline:delete': buildQueryResolver(
        'pipelines:deleteInternalPipeline',
      ),
      'pipelines:internalPipeline:getLogs': buildQueryResolver(
        'pipelines:getInternalPipelineLogsSamples',
      ),
      'datasinks:find': buildQueryResolver('datasinks:findById'),
      'secrets:get': buildQueryResolver(
        'organizationSettings:secret:getSecrets',
      ),
      'users:find': buildQueryResolver('users:findById'),
    },
    instance,
  );
};
