import type { EventBus } from '@nstrlabs/sdk';
import type { UseCase } from '@nstrlabs/utils';
import { lastActivityTimestampExpired } from '../../../utils/activityTimestamp';
import logger from '../../shared/infrastructure/logger';
import {
  type AuthRepository,
  type ErrorAccountNotAuthorized,
  errorAccountNotAuthorized,
} from '../domain/AuthRepository';
import type { Token } from '../domain/Token';
import { makeTokenTenantUpdateEventFromEvent } from '../domain/TokenTenantUpdatedEvent';
// Variables used for logger
const moduleName = 'context/auth/application/onIdentityProviderTokenChange.ts';
const functionName = 'onIdentityProviderTokenChange';

type OnIdentityProviderTokenChangeCommand = {
  observer: (
    token: Token | null,
    error?: Error | ErrorAccountNotAuthorized,
  ) => void;
};

const ERROR_UNAUTHORIZED = errorAccountNotAuthorized();

// Reauthenticate before internal token expires (1h)
const REAUTHENTICATE_INTERVAL = 45 * 60 * 1000;
const AUTHENTICATION_DEBOUNCE_TIME = 300;
let reauthenticateIntervalId: NodeJS.Timeout;
let authenticationDebounceTimerId: NodeJS.Timeout;

export const isUnauthorizedError = (
  error: Error | ErrorAccountNotAuthorized,
): error is ErrorAccountNotAuthorized =>
  (error as ErrorAccountNotAuthorized) &&
  error.message === ERROR_UNAUTHORIZED.message;

export const onIdentityProviderTokenChange =
  (
    doOnIdentityProviderTokenChange: AuthRepository['onIdentityProviderTokenChange'],
    authenticate: AuthRepository['authenticate'],
    saveToken: AuthRepository['saveToken'],
    logout: AuthRepository['logout'],
    getActivityTimestamp: AuthRepository['getActivityTimestamp'],
    getTenant: AuthRepository['getTenant'],
    eventBus: () => EventBus,
  ): UseCase<OnIdentityProviderTokenChangeCommand, () => void> =>
  ({ observer }) => {
    logger.debug('onIdentityProviderTokenChange invoked', {
      module: moduleName,
      function: functionName,
    });
    const callback = (thirdPartyToken: Token | null) => {
      clearTimeout(authenticationDebounceTimerId);
      clearInterval(reauthenticateIntervalId);

      logger.debug('onIdentityProviderTokenChange internal observer invoked', {
        module: moduleName,
        function: functionName,
        context: { thirdPartyToken },
      });

      if (thirdPartyToken === null) {
        observer(null);
        return;
      }

      const authenticationProcess = async () => {
        try {
          logger.debug(
            'onIdentityProviderTokenChange begin authentication process',
            {
              module: moduleName,
              function: functionName,
            },
          );

          const activityTimestamp = await getActivityTimestamp();
          const activityTimestampDate = new Date(activityTimestamp ?? 0);

          logger.debug(
            'onIdentityProviderTokenChange checking activity timestamp during authentication process',
            {
              module: moduleName,
              function: functionName,
              context: {
                activityTimestamp,
                activityTimestampDate,
              },
            },
          );

          if (lastActivityTimestampExpired(activityTimestamp)) {
            logger.debug(
              'onIdentityProviderTokenChange forced logout due to activity timestamp expiration',
              {
                module: moduleName,
                function: functionName,
                context: {
                  activityTimestamp,
                  activityTimestampDate,
                  currentTime: Date.now(),
                  inactiveTime: Date.now() - (activityTimestamp ?? 0),
                },
              },
            );
            clearTimeout(authenticationDebounceTimerId);
            clearInterval(reauthenticateIntervalId);
            await logout();
            observer(null);
            return;
          }

          const tenantId = await getTenant();
          const token = await authenticate(thirdPartyToken, tenantId);

          logger.debug(
            'onIdentityProviderTokenChange internal authentication process success',
            {
              module: moduleName,
              function: functionName,
            },
          );
          saveToken(token);
          observer(token);
        } catch (error: unknown) {
          logout();
          logger.debug(
            'onIdentityProviderTokenChange internal authentication process failed',
            {
              module: moduleName,
              function: functionName,
            },
          );
          logout().finally(() => {
            observer(null, error as Error);
          });
        }
      };

      authenticationDebounceTimerId = setTimeout(() => {
        authenticationProcess();
      }, AUTHENTICATION_DEBOUNCE_TIME);

      reauthenticateIntervalId = setInterval(() => {
        logger.debug(
          'onIdentityProviderTokenChange invoking internal authentication to prevent internal token expiration',
          {
            module: moduleName,
            function: functionName,
            context: { REAUTHENTICATE_INTERVAL },
          },
        );
        authenticationProcess();
      }, REAUTHENTICATE_INTERVAL);
    };

    const unsubscribe = doOnIdentityProviderTokenChange(callback);

    const unsubscribeEvent = eventBus().subscribe('token:updated', (event) => {
      makeTokenTenantUpdateEventFromEvent(event).then(
        ({ payload: { token } }) => {
          saveToken(token);
          observer(token);
        },
      );
    });

    return Promise.resolve(() => {
      unsubscribe();
      unsubscribeEvent();
    });
  };
