import { Flex, Typography } from '@nstrlabs/ixel';
import type { Column } from '@nstrlabs/ixel';
import { capitalize } from '@nstrlabs/utils';
import { format } from 'date-fns';
import type { ContainerUseCase } from '../../../context';
import styles from './index.module.css';

type ActivityDataProps = ContainerUseCase<
  'activity:getUserActivity',
  'result'
>['data'][number];

const RenderUserName = ({ user }: ActivityDataProps) => {
  const { name, surname, email } = user;
  return (
    <Flex flexDirection="column" gap="xxs">
      <Typography bold className={styles.name}>
        {`${name} ${surname}`}
      </Typography>
      <Typography className={styles.email} size="small">
        {email}
      </Typography>
    </Flex>
  );
};

const RenderDate = ({ date }: ActivityDataProps) => {
  return (
    <Flex flexDirection="row" gap="xxs">
      <Typography>{format(new Date(date), 'MMM dd yyyy · hh:mma')}</Typography>
    </Flex>
  );
};

export const COLUMNS: Column<ActivityDataProps>[] = [
  {
    cellRenderer: RenderDate,
    className: styles.roleCell,
    id: 'date',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.DATE',
    pinnable: false,
  },
  {
    cellRenderer: RenderUserName,
    id: 'user.name',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.USER',
    pinnable: false,
  },
  {
    cellRenderer: ({ event: { operation } }: ActivityDataProps) => (
      <Typography>{capitalize(operation)}</Typography>
    ),
    id: 'event.operation',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.OPERATION',
    pinnable: false,
    maxWidth: 140,
  },
  {
    cellRenderer: ({ event: { entity } }: ActivityDataProps) => (
      <Typography>{capitalize(entity)}</Typography>
    ),
    id: 'event.entity',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.ENTITY',
    pinnable: false,
    maxWidth: 140,
  },
  {
    cellRenderer: ({ event: { name } }: ActivityDataProps) => (
      <Typography>{capitalize(name)}</Typography>
    ),
    id: 'event.name',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.NAME',
    pinnable: false,
  },
  {
    cellRenderer: ({ ip_address }: ActivityDataProps) => (
      <Typography>{ip_address}</Typography>
    ),
    headerClassName: styles.lastHeaderColumn,
    id: 'ip_address',
    moveable: false,
    name: 'SETTINGS.TENANT.ACTIVITY.FIELDS.IP_ADDRESS',
    pinnable: false,
    maxWidth: 140,
  },
];
