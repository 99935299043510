import { Flex } from '@nstrlabs/ixel';
import { emptyFn, eventEmitter } from '@nstrlabs/utils';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import NoData from '../../../components/molecules/NoData';
import Pagination from '../../../components/organisms/FullTable/Pagination';
import SkeletonTable from '../../../components/organisms/FullTable/SkeletonTable';
import Table from '../../../components/organisms/FullTable/Table';
import { useNewFilters } from '../../../components/organisms/NewFilters/FiltersProvider';
import { findFilter } from '../../../components/organisms/NewFilters/utils';
import { useActivity } from '../../../hooks/activity/useActivity';
import { COLUMNS } from './ActivityColumns';
import styles from './index.module.css';

const ActivityTable = () => {
  const { t } = useTranslation();
  const emitter = useMemo(() => eventEmitter<{ type: 'deselect' }>(), []);
  const { filters } = useNewFilters();
  const { from, to } = findFilter('rangeData', filters) as Record<
    string,
    string
  >;

  const activityFiltersFormatted = {
    from: Math.floor(new Date(from).getTime()),
    to: Math.floor(new Date(to).getTime()),
  };

  const [pageSelected, setPageSelected] = useState(1);

  const { activity, isLoading } = useActivity({
    ...activityFiltersFormatted,
    page: pageSelected,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setPageSelected(1);
  }, [activityFiltersFormatted.from, activityFiltersFormatted.to]);

  if (isLoading) return <SkeletonTable columns={6} />;

  if (activity && !isLoading) {
    const { data, page, limit, count } = activity;
    return (
      <Flex flexDirection="column" gap="xl">
        {data.length > 0 ? (
          <>
            <Table
              activeRow=""
              className={styles.table}
              columns={COLUMNS}
              data={data}
              disableClickSelection
              emitter={emitter}
              groupSelection=""
              hideHeader
              noData={
                <NoData
                  size="normal"
                  gap="xxl"
                  title={t('PIPELINE.MENU.NO_DATA.TITLE')}
                  subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
                  flexDirection="column"
                  iconName="search"
                />
              }
              onRowActive={emptyFn}
              title=""
              searchValue=""
            />
            <Pagination
              page={page}
              total={Math.ceil(count / limit)}
              onPageChange={(page) => setPageSelected(page)}
            />
          </>
        ) : (
          <NoData
            className={styles.emptySearch}
            size="normal"
            gap="xxl"
            title={t('PIPELINE.MENU.NO_DATA.TITLE')}
            subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
            flexDirection="column"
            iconName="search"
          />
        )}
      </Flex>
    );
  }

  return null;
};

export default ActivityTable;
