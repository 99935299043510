export const YOUR_PREFERENCES_GENERAL = {
  value: 'your_preferences_general',
  label: 'SETTINGS.MENU_OPTIONS.GENERAL',
  resource: '',
};

export const YOUR_PREFERENCES_EMAIL_PASSWORD = {
  value: 'email_password',
  label: 'SETTINGS.MENU_OPTIONS.EMAIL_PASSWORD',
  resource: '',
};

export const YOUR_PREFERENCES_SECURITY = {
  value: 'your_preferences_security',
  label: 'SETTINGS.MENU_OPTIONS.SECURITY',
  resource: '',
};

export const YOUR_PREFERENCES_NOTIFICATIONS = {
  value: 'notifications',
  label: 'SETTINGS.MENU_OPTIONS.NOTIFICATIONS',
  resource: '',
};

export const ORGANIZATION_SETTINGS_GENERAL = {
  value: 'organization_settings_general',
  label: 'SETTINGS.MENU_OPTIONS.GENERAL',
  resource: '',
};
export const ORGANIZATION_SETTINGS_DOWNLOADS = {
  value: 'marketplace_downloads',
  label: 'SETTINGS.MENU_OPTIONS.MARKETPLACE_DOWNLOADS',
  resource: '',
};

export const ORGANIZATION_SETTINGS_SECURITY = {
  value: 'organization_settings_security',
  label: 'SETTINGS.MENU_OPTIONS.SECURITY',
  resource: '',
};
export const ORGANIZATION_SETTINGS_API = {
  value: 'organization_settings_api',
  label: 'SETTINGS.MENU_OPTIONS.API',
  resource: '',
};

export const ORGANIZATION_SETTINGS_PAYMENT_METHOD = {
  value: 'payment_method',
  label: 'SETTINGS.MENU_OPTIONS.PAYMENT_METHOD',
  resource: '',
};

export const ORGANIZATION_SETTINGS_SECRET_MANAGEMENT = {
  value: 'secrets_management',
  label: 'SETTINGS.MENU_OPTIONS.SECRETS_MANAGEMENT',
  resource: 'secret',
};

export const TENANT_ACTIVITY = {
  value: 'activity_log',
  label: 'SETTINGS.MENU_OPTIONS.ACTIVITY',
  resource: 'activity',
};

export const CONTROL_ACCESS_USERS = {
  value: 'control_access_users',
  label: 'SETTINGS.MENU_OPTIONS.USERS',
  resource: '',
};

export const CONTROL_ACCESS_API = {
  value: 'control_access_api',
  label: 'SETTINGS.MENU_OPTIONS.API',
  resource: '',
};

export const CONTROL_ACCESS_ARCHIVED = {
  value: 'archived',
  label: 'SETTINGS.MENU_OPTIONS.ARCHIVED',
  resource: '',
};
