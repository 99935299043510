import { Flex, Icon, Typography } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import type { FilterToSelect } from '../FiltersProvider';
import styles from './index.module.css';

export type HTMLFilterSuggestedProps = React.ComponentPropsWithRef<'div'> & {
  filter: FilterToSelect;
  onClick?: () => void;
  dataTestId?: string;
};

const FilterSuggestedItem = (
  {
    className,
    onClick,
    dataTestId = 'filter-suggested-chip',
    filter,
  }: HTMLFilterSuggestedProps,
  ref: Ref<HTMLElement>,
) => {
  const { label, iconName } = filter || {};
  const { t } = useTranslation();

  return (
    <Flex
      data-testid={dataTestId}
      data-cy={dataTestId}
      alignContent="center"
      justifyContent="center"
      alignItems="center"
      gap="xxs"
      ref={ref}
      className={clsx(className, styles.container)}
      onClick={(e: { preventDefault: () => void }) => {
        e.preventDefault();
        invoke(onClick);
      }}
    >
      <Icon name={iconName ?? 'plus'} className={styles.icon} />
      <Typography className={styles.nameText}>{t(label)}</Typography>
    </Flex>
  );
};

export default forwardRef(FilterSuggestedItem);
