import { Either, type Value, domainError } from '@nstrlabs/utils';
import { uuidValue } from './UuidValue';

export const invalidIdPrefix = domainError<{
  expected: string;
  received: string;
  value: string;
}>()('shared:id:prefix', 'WEB-xxx', 'The id is invalid');

export type InvalidIdPrefix = ReturnType<typeof invalidIdPrefix>;

export const invalidIdUuid = domainError<{
  value: string;
}>()('shared:id:uuid', 'WEB-xxx', 'The id is invalid');

export type InvalidIdUuid = ReturnType<typeof invalidIdUuid>;

export const idValue = <Type extends Value<string, any>>(
  prefix: string,
  id: string,
): Either<InvalidIdUuid | InvalidIdPrefix, Type> => {
  const [context, uuid] = id.split('_');

  return uuidValue(uuid)
    .mapLeft(() => invalidIdUuid({ value: id }))
    .flatMap(() => {
      if (context !== prefix) {
        return Either.left(
          invalidIdPrefix({ expected: prefix, received: context, value: id }),
        );
      }

      return Either.of(id as Type);
    });
};
