import { Navigate, useParams } from 'react-router-dom';

type AreValidParamsProps = {
  validate: (params: unknown) => boolean;
  children: React.ReactNode;
};
const AreValidParams = ({ validate, children }: AreValidParamsProps) => {
  const areValid = validate(useParams());
  return areValid ? children : <Navigate to="/not-found" replace />;
};

export default AreValidParams;
