import {
  Button,
  Flex,
  PasswordField,
  Scroll,
  TextField,
  Typography,
} from '@nstrlabs/ixel';
import { useLogger } from '@nstrlabs/sdk';
import { emptyFn } from '@nstrlabs/utils';
import { type ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/atoms/Separator';
import SettingsHeader from '../../../components/atoms/SettingsHeader';
import NewPassword, {
  STRONG_PASSWORD_VALIDATION,
  isNewPasswordSecure,
} from '../../../components/molecules/NewPassword';
import { useContainer } from '../../../hooks/useContainer';
import { useInputText } from '../../../hooks/useInputText';
import { useToast } from '../../../hooks/useToast';
import { useUser } from '../../../hooks/useUser';
import styles from './index.module.css';

const isFormCorrect = (
  oldPassword: string,
  newPassword: string,
  confirmNewPassword: string,
  passwordIsSecureErrors: Array<unknown>,
) =>
  oldPassword !== '' &&
  isNewPasswordSecure(newPassword, confirmNewPassword, passwordIsSecureErrors);

const MailAndPassword = () => {
  const container = useContainer();
  const { t } = useTranslation('translation', {
    keyPrefix: 'SETTINGS.MAIL_PASSWORD',
  });
  const [updatePassword, setUpdatePassword] = useState(false);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword, passwordIsSecureErrors] = useInputText(
    '',
    STRONG_PASSWORD_VALIDATION,
    true,
  );
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [showIfNewPasswordIsConfirmed, setShowIfNewPasswordIsConfirmed] =
    useState(false);
  const { Email, UID } = useUser();
  const toastService = useToast();
  const logger = useLogger();

  const ERRORS_MAP = passwordIsSecureErrors.reduce(
    (map, error) => (error.err in map ? { ...map, [error.err]: false } : map),
    {
      MIN_LENGTH: true,
      ERROR_NUMBER: true,
      ERROR_CAP: true,
      ERROR_LOWER: true,
      ERROR_SPECIAL: true,
    },
  );

  const resetToInitState = () => {
    setShowIfNewPasswordIsConfirmed(false);
    setOldPassword('');
    setNewPassword({
      preventDefault: emptyFn,
      target: { value: '' },
    } as ChangeEvent<HTMLInputElement>);
    setConfirmNewPassword('');
    setUpdatePassword(false);
  };

  return (
    <Scroll className={styles.scrollContainer} paddingX="xl">
      <Flex
        className={styles.container}
        flexDirection="column"
        alignItems="center"
      >
        <Flex className={styles.content} flexDirection="column">
          <SettingsHeader
            squareClassName={styles.square}
            title={t('TITLE')}
            description={t('DESCRIPTION')}
            icon="settings"
          />
          <Separator />
          <Flex flexDirection="column" gap="md">
            <Flex flexDirection="column" gap="xs">
              <Typography bold>{t('EMAIL')}</Typography>
              <Typography className={styles.description}>
                {t('EMAIL_DESCRIPTION')}
              </Typography>
            </Flex>
            <TextField
              id="email"
              className={styles.mail}
              label={t('EMAIL_INPUT_LABEL')}
              value={Email}
              disabled
            />
          </Flex>
          <Separator />
          <Flex flexDirection="column" gap="md">
            {updatePassword ? (
              <>
                <Flex flexDirection="column" gap="xs">
                  <Typography bold>{t('PASSWORD')}</Typography>
                  <Typography className={styles.description}>
                    {t('PASSWORD_DESCRIPTION')}
                  </Typography>
                </Flex>
                <PasswordField
                  id="user_old_password"
                  label={t('CURRENT_PASSWORD_INPUT_LABEL')}
                  value={oldPassword}
                  onChange={(e) => setOldPassword(e.target.value)}
                />
                <NewPassword
                  newPassword={newPassword}
                  onNewPassword={setNewPassword}
                  confirmNewPassword={confirmNewPassword}
                  onConfirmNewPassword={(e) =>
                    setConfirmNewPassword(e.target.value)
                  }
                  applyShowConfirmError={() =>
                    setShowIfNewPasswordIsConfirmed(true)
                  }
                  showConfirmError={showIfNewPasswordIsConfirmed}
                  errors={ERRORS_MAP}
                />
                <Flex gap="md">
                  <Button
                    disabled={
                      !isFormCorrect(
                        oldPassword,
                        newPassword,
                        confirmNewPassword,
                        passwordIsSecureErrors,
                      )
                    }
                    onClick={() =>
                      void container
                        .call('auth:updatePassword', {
                          userId: UID,
                          oldPassword,
                          newPassword,
                          confirmNewPassword,
                        })
                        .then(() => {
                          toastService.publish({
                            content: t('UPDATE_SUCCESS'),
                          });
                          resetToInitState();
                        })
                        .catch((err) => {
                          logger.error('Error when updating user password', {
                            function: 'auth:updatePassword',
                            module: 'settings:user&password',
                            context: err as Record<string, unknown>,
                          });
                          toastService.publish({
                            level: 'alert',
                            content: t('UPDATE_ERROR'),
                          });
                        })
                    }
                  >
                    {t('SAVE')}
                  </Button>
                  <Button
                    mode="primary"
                    variant="link"
                    onClick={resetToInitState}
                  >
                    {t('CANCEL')}
                  </Button>
                  <Button className={styles.forgetBtn} variant="link" disabled>
                    {t('FORGOT')}
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <Flex flexDirection="column" gap="xs">
                  <Typography bold>{t('PASSWORD')}</Typography>
                  <Typography>•••••••••••</Typography>
                </Flex>
                <Button
                  className={styles.updateBtn}
                  onClick={() => setUpdatePassword(true)}
                >
                  {t('CHANGE_PASSWORD')}
                </Button>
              </>
            )}
          </Flex>
          <Separator />
        </Flex>
      </Flex>
    </Scroll>
  );
};

export default MailAndPassword;
