import { Toast } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.css';
import { type ToastCommand, parseToastCommand } from './parseToastCommand';

export type ToastStackedProps = {
  className?: string;
  selector?: string;
  source: {
    subscribe: (fn: (toast: unknown) => void) => () => void;
  };
};

/**
 * Pass a customized id in case you want
 * your toast not repeat on the same action
 */
const ToastStacked = ({
  className: containerClassName,
  selector,
  source,
}: ToastStackedProps) => {
  const [toastStack, setToastStack] = useState<ToastCommand[]>([]);
  const root = useMemo(
    () => (selector ? document.querySelector(selector) : null),
    [selector],
  );
  const mergeDuplicated = (
    commands: ToastCommand[],
    newCommand: ToastCommand,
  ) =>
    commands.reduce(
      (acc, current) =>
        current.id === newCommand.id ? [...acc] : [...acc, current],
      [],
    );

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  useEffect(
    () =>
      source.subscribe((toast) => {
        const { onClose, channel, action, ...validCommand } = parseToastCommand(
          toast as ToastCommand,
        );
        if (channel === 'stacking') {
          const removeFromStack = () => {
            setToastStack((commands) =>
              commands.filter((command) => command.id !== validCommand.id),
            );
            invoke(onClose);
          };

          setToastStack((commands) => {
            const timeoutId = setTimeout(
              removeFromStack,
              validCommand.time || 5000,
            );

            const onCloseWrapper = () => {
              clearTimeout(timeoutId);
              removeFromStack();
            };

            return [
              ...mergeDuplicated(commands, validCommand),
              { ...validCommand, onClose: onCloseWrapper },
            ];
          });
        }
      }),
    [source],
  );

  const rootClassName = clsx(
    styles.appToastSectionStacking,
    styles.toastRoot,
    containerClassName,
  );

  const render = (
    <section role="alert" className={rootClassName}>
      {toastStack.map(
        ({ id, level, action, onClose, className, content, customRender }) => (
          <Toast
            key={id}
            data-testid={id}
            level={level}
            className={className}
            onClose={onClose}
            action={action}
          >
            {content || customRender}
          </Toast>
        ),
      )}
    </section>
  );

  if (root !== null) {
    return createPortal(render, root);
  }

  return render;
};

export default ToastStacked;
