import { z } from 'zod';

const envScheme = z
  .object({
    NODE_ENV: z.string().default('development'),
    VITE_API_GATEWAY: z.string().default('http://localhost/v1'),
    VITE_S3_ASSETS_URL: z.string().default(''),
  })
  .transform(({ VITE_S3_ASSETS_URL, ...other }) => ({
    ...other,
    S3_ASSETS_URL: VITE_S3_ASSETS_URL,
  }));

type Env = z.infer<typeof envScheme>;

const env = envScheme.parse(process.env);

export const getEnvSync = <Key extends keyof Env>(key: Key): Env[Key] =>
  env[key];

export const getEnv = <Key extends keyof Env>(key: Key) =>
  Promise.resolve(getEnvSync(key));
