import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ActivityQueryParamsPrimitives } from '../../context/activity/domain/ActivityQueryParams';
import { useContainer } from '../useContainer';

export const useActivity = (filters: ActivityQueryParamsPrimitives) => {
  const container = useContainer();
  const queryClient = useQueryClient();

  const { data, error, isError, isPending, isLoading } = useQuery({
    queryKey: ['activity', filters],
    queryFn: () => {
      queryClient.removeQueries({ queryKey: ['activity'], type: 'inactive' });
      return container.call('activity:getUserActivity', filters);
    },
  });

  return {
    activity: data,
    error,
    isError,
    isPending,
    isLoading,
  };
};
