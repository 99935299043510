import { Avatar, Skeleton } from '@nstrlabs/ixel';
import { type HTMLAttributes, useState } from 'react';
import type { UserType } from '../../UserSettings';
import DefaultMark from './DefaultMark';
import styles from './index.module.css';

const ORGANIZATION_INFO = {
  firstName: 'T',
  lastName: '',
  color: 'darkViolet',
};

export type OrganizationIconProps = HTMLAttributes<HTMLDivElement> & {
  tenantName: string;
  tenantLogo?: string;
  isDefault?: boolean;
};

const OrganizationIcon = ({
  tenantName,
  tenantLogo,
  isDefault = false,
}: OrganizationIconProps) => {
  const [errorOnLoadImage, setErrorOnLoadImage] = useState(false);

  return (
    <div className={styles.container}>
      {errorOnLoadImage || tenantLogo === undefined ? (
        <Avatar
          user={
            {
              ...ORGANIZATION_INFO,
              firstName: tenantName.charAt(0),
            } as UserType
          }
        />
      ) : (
        <div className={styles.imgContainer}>
          {errorOnLoadImage ? (
            <Skeleton width={32} height={32} />
          ) : (
            <img
              src={tenantLogo}
              alt={tenantName}
              width={32}
              height={32}
              onError={() => setErrorOnLoadImage(true)}
            />
          )}
        </div>
      )}
      {isDefault ? <DefaultMark className={styles.mark} /> : null}
    </div>
  );
};
export default OrganizationIcon;
