import { Either } from '@nstrlabs/utils';
import { type Token, makeToken } from './Token';

export const makeTokenTenantUpdateEventFromToken = (token: Token) => ({
  payload: { token },
});

export const makeTokenTenantUpdateEventFromEvent = (event: unknown) => {
  if (
    typeof event === 'object' &&
    event !== null &&
    'payload' in event &&
    typeof event.payload === 'object' &&
    event.payload !== null &&
    'token' in event.payload &&
    typeof event.payload.token === 'string'
  )
    return Either.toPromise(
      makeToken(event.payload.token).map(makeTokenTenantUpdateEventFromToken),
    );

  return Promise.reject(new Error('unknown event'));
};
