import FilterGenericNumber from '../FilterGenericNumber';
import type { FilterWithOperation } from '../FiltersProvider';

type Operator =
  | {
      value: '<';
      label: '<';
    }
  | {
      value: '>';
      label: '>';
    }
  | {
      value: '=';
      label: '=';
    }
  | {
      value: '<=';
      label: '<=';
    }
  | {
      value: '>=';
      label: '>=';
    };

type Operators = Operator[];

const options: Operators = [
  { value: '<', label: '<' },
  { value: '>', label: '>' },
  { value: '=', label: '=' },
  { value: '<=', label: '<=' },
  { value: '>=', label: '>=' },
];

export type FilterNumberProps = React.HTMLAttributes<HTMLDivElement> & {
  filter: FilterWithOperation<'number', number>;
  buttonName: string;
  onDelete: () => void;
  handleSaveFilters: (
    filterToSave: FilterWithOperation<'number', number>,
  ) => void;
};

const FilterNumber = ({
  filter,
  buttonName,
  onDelete,
  handleSaveFilters,
}: FilterNumberProps) => {
  const saveFilters = (filterToSave: FilterWithOperation<'number', number>) => {
    handleSaveFilters({
      ...filterToSave,
      type: 'number',
      value: Number(filterToSave.value),
    });
  };
  return (
    <FilterGenericNumber
      filter={filter}
      buttonName={buttonName}
      onDelete={onDelete}
      handleSaveFilters={saveFilters}
      options={options}
    />
  );
};

export default FilterNumber;
