import { invoke } from '@nstrlabs/utils';
import { useCallback, useRef } from 'react';

type UseClickProps<
  TClickArgs extends Record<string, unknown>,
  TDoubleClickArgs extends Record<string, unknown>,
> = {
  onClick?: (args?: TClickArgs, event?: React.MouseEvent) => void;
  onDoubleClick?: (args?: TDoubleClickArgs, event?: React.MouseEvent) => void;
  delay?: number;
};

const useDoubleClick = <
  TClickArgs extends Record<string, unknown>,
  TDoubleClickArgs extends Record<string, unknown>,
>({
  onClick,
  onDoubleClick,
  delay = 280,
}: UseClickProps<TClickArgs, TDoubleClickArgs>) => {
  const clickTimeout = useRef<NodeJS.Timeout | null>(null);

  return useCallback(
    (
      event: React.MouseEvent,
      onClickArgs?: TClickArgs,
      onDoubleClickArgs?: TDoubleClickArgs,
    ) => {
      if (clickTimeout.current) {
        clearTimeout(clickTimeout.current);
        clickTimeout.current = null;
      }

      if (event.detail === 1) {
        clickTimeout.current = setTimeout(() => {
          clickTimeout.current = null;
          return onClickArgs
            ? invoke(onClick, onClickArgs, event)
            : invoke(onClick, undefined, event);
        }, delay);
      }

      if (event.detail === 2) {
        return onDoubleClickArgs
          ? invoke(onDoubleClick, onDoubleClickArgs, event)
          : invoke(onDoubleClick, undefined, event);
      }
      return null;
    },
    [onClick, onDoubleClick, delay],
  );
};

export default useDoubleClick;
