import {
  Button,
  type Column,
  Flex,
  Icon,
  Item,
  Typography,
} from '@nstrlabs/ixel';
import { moveArrayPosition } from '@nstrlabs/utils';

import type { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { List } from 'react-movable';

import styles from './index.module.css';

export type TableSettingsProps = ComponentPropsWithoutRef<'div'> & {
  columns: Column<unknown>[];
  columnsDefaultPosition: string[];
  onColumnUpdate: (columns: Column<unknown>[]) => void;
};

const TableSettings = ({
  columns,
  columnsDefaultPosition,
  onColumnUpdate,
}: TableSettingsProps) => {
  const { t } = useTranslation();

  const handleColumnVisibility = (columnId: string) => {
    onColumnUpdate(
      columns.map((column: Column<unknown>): Column<unknown> => {
        if (column.id === columnId) {
          return { ...column, hidden: !column.hidden };
        }

        return column;
      }),
    );
  };

  const handleColumnPin = (columnId: string) => {
    onColumnUpdate(
      columns.map((column) => {
        if (column.id === columnId) {
          return {
            ...column,
            pinned: column.pinned === 'left' ? null : 'left',
          };
        }
        return column;
      }),
    );
  };

  const handleColumnPosition = ({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number;
    newIndex: number;
  }) => {
    const updatedPositionColumns: Column<unknown>[] = moveArrayPosition(
      columns,
      oldIndex,
      newIndex,
    );
    onColumnUpdate(updatedPositionColumns);
  };

  const handleColumnsReset = () => {
    onColumnUpdate(
      columnsDefaultPosition.map((defaultColumnId) => {
        const columnFound = columns.find(
          (column) => column.id === defaultColumnId,
        ) as Column<unknown>;

        return {
          ...columnFound,
          hidden: false,
          pinned: null,
        };
      }),
    );
  };

  const columnsHidableAndPinnable = columns.filter(
    (column) => column.id !== 'actions',
  );

  return (
    <Flex flexDirection="column">
      <Typography
        as="h1"
        bold
        size="default"
        variant="body"
        className={styles.title}
      >
        {t('TABLE_SETTINGS.TITLE')}
      </Typography>
      <Typography as="p" size="default" variant="body" color="secondary">
        {t('TABLE_SETTINGS.DESCRIPTION')}
      </Typography>
      <List
        values={columnsHidableAndPinnable}
        lockVertically
        renderList={({ children, props }) => (
          <ul {...props} className={styles.listItems}>
            {children}
          </ul>
        )}
        renderItem={({ isDragged, value, props: { style, ...rest } }) => (
          <li style={{ ...style, listStyle: 'none', zIndex: 1180 }} {...rest}>
            <Item
              isDragged={isDragged}
              name={value.name ?? value.id}
              firstIcon={<Icon name={value.hidden ? 'hide' : 'show'} />}
              firstIconAction={() => handleColumnVisibility(value.id)}
              secondIcon={<Icon name={value.pinned ? 'pinSelected' : 'pin'} />}
              secondIconAction={() => handleColumnPin(value.id)}
            />
          </li>
        )}
        onChange={handleColumnPosition}
      />

      <Button
        className={styles.reset}
        variant="ghost"
        onClick={handleColumnsReset}
      >
        {t('TABLE_SETTINGS.RESET_BUTTON')}
      </Button>
    </Flex>
  );
};

export default TableSettings;
