import {
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  DEFAULT_SETTINGS,
  FetchUserSettingsError,
  type UserSettings,
} from '../../context/settings/domain/UserSettings';
import { useContainer } from '../useContainer';

export type UserSettingsContextType = {
  settings: UserSettings;
  saveSettings: (settings: UserSettings) => Promise<void>;
};

const UserSettingsContext = createContext<UserSettingsContextType | null>(null);

export const useSettings = (): UserSettingsContextType => {
  const container = useContainer();
  const [userSettings, setUserSettings] =
    useState<UserSettings>(DEFAULT_SETTINGS);

  useEffect(() => {
    container
      .call('settings:getUserSettings')
      .then(setUserSettings)
      .catch(() => {
        throw new FetchUserSettingsError();
      });
  }, [container]);

  const saveSettings = useCallback(
    async (settings: UserSettings) => {
      setUserSettings(
        await container.call('settings:saveUserSettings', { settings }),
      );
    },
    [container],
  );

  return {
    settings: userSettings,
    saveSettings,
  };
};

export const UserSettingsProvider = (props: { children?: ReactNode }) => {
  const { settings, saveSettings } = useSettings();

  const value = useMemo(
    () => ({ settings, saveSettings }),
    [settings, saveSettings],
  );

  return <UserSettingsContext.Provider {...props} value={value} />;
};

export class UseUserSettingsError extends Error {
  constructor() {
    super('useUserSettings should be used within a provider');
  }
}

export const useUserSettings = (): UserSettingsContextType => {
  const container = useContext(UserSettingsContext);

  if (container === null) {
    throw new UseUserSettingsError();
  }

  return container;
};

export const useUserSettingsSafe = (): UserSettingsContextType => {
  const container = useContext(UserSettingsContext);

  if (container === null) {
    return {
      settings: DEFAULT_SETTINGS,
      saveSettings: () => Promise.resolve(),
    } as UserSettingsContextType;
  }

  return container;
};
