import { Accordion, Button, Flex, Icon, Typography } from '@nstrlabs/ixel';
import { capitalize } from 'lodash';
import { useTranslation } from 'react-i18next';
import { handleOpenItem } from '.';
import type { UseSecretUsages } from '../../../../hooks/organizationSettings/secrets/useSecretUsages';
import SquareMetadataLogo from '../../../molecules/SquareMetadataLogo';
import styles from './index.module.css';

const handleOpenDatasink = (id: string) => {
  handleOpenItem(`/datasinks/${id}`);
};
export const DatasinkListComponent = ({
  datasinks,
}: { datasinks: UseSecretUsages['usages']['datasinks'] }) => {
  const { t } = useTranslation();
  return (
    <Accordion
      as="section"
      title={capitalize(t('GLOBAL.DATASINKS'))}
      items={datasinks.length}
    >
      <Flex flexDirection="column">
        {datasinks.map((datasink) => {
          const { id, name } = datasink;
          return (
            <Flex
              key={id}
              gap="md"
              flexDirection="row"
              alignContent="center"
              justifyContent="spaceBetween"
              className={styles.itemRow}
            >
              <Flex gap="md" alignItems="center">
                <SquareMetadataLogo
                  name={datasink.actionName}
                  size={12}
                  variant="fit"
                />
                <Typography color="secondary">{name}</Typography>
                <Button
                  icon={<Icon name="open" />}
                  size="small"
                  mode="primary"
                  variant="link"
                  onClick={() => handleOpenDatasink(id)}
                />
              </Flex>
            </Flex>
          );
        })}
      </Flex>
    </Accordion>
  );
};
