import { Either, type Value, domainError } from '@nstrlabs/utils';

export type Token = Value<string, 'auth_token'>;

const invalidToken = domainError<{
  token: string;
}>()('invalid:token', 'WEB-11007', 'Token is invalid.');

export type InvalidToken = ReturnType<typeof invalidToken>;

export const makeToken = (value: string): Either<InvalidToken, Token> => {
  if (value.trim() === '') {
    return Either.left(invalidToken({ token: value }));
  }

  return Either.of(value as Token);
};
