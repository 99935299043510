import type { EventBus } from '@nstrlabs/sdk';

export const windowEventBus = (): EventBus => {
  const broadcast = window;
  const channel = crypto.randomUUID();

  return {
    subscribe(topic: string, listener: (event: unknown) => void) {
      const eventListener = ({ data }: MessageEvent<unknown>) => {
        if (
          typeof data !== 'object' ||
          data === null ||
          ('channel' in data ? data.channel !== channel : true) ||
          ('topic' in data ? data.topic !== topic : true)
        )
          return;

        listener('event' in data ? data.event : undefined);
      };

      broadcast.addEventListener('message', eventListener);

      return () => broadcast.removeEventListener('message', eventListener);
    },
    emit(topic: string, event: unknown) {
      broadcast.postMessage({ channel, topic, event });
    },
  };
};
