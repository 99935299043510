import { rangeLast7Days } from '@nstrlabs/utils';
import type {
  Filter,
  FilterToSelect,
} from '../../../components/organisms/NewFilters/FiltersProvider';

const filtersTranslationRoot = 'SETTINGS.TENANT.ACTIVITY.FILTERS';

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

const lastSevenDays = rangeLast7Days({ timeZone: TIME_ZONE });
const defaultFromTo = () =>
  lastSevenDays.from !== null && lastSevenDays.to !== null
    ? {
        from: lastSevenDays.from.toISOString(),
        to: lastSevenDays.to.toISOString(),
      }
    : { from: '', to: '' };

const DEFAULT_DATE = {
  from: defaultFromTo().from,
  to: defaultFromTo().to,
  preset: 'LAST_SEVEN_DAYS',
};

type OperationOption = Record<'value' | 'label', string>;
type EntityOption = Record<'value' | 'label', string>;

export const filters: Filter[] = [
  {
    label: `${filtersTranslationRoot}.RANGE_DATE`,
    field: 'rangeData',
    type: 'date',
    iconName: 'calendar',
    value: DEFAULT_DATE,
    isOpen: false,
    isFixed: true,
    id: 'rangeData',
  },
];

const operationOptions: OperationOption[] = [
  {
    value: 'create',
    label: `${filtersTranslationRoot}.OPERATION.CREATE`,
  },
  {
    value: 'update',
    label: `${filtersTranslationRoot}.OPERATION.UPDATE`,
  },
  {
    value: 'delete',
    label: `${filtersTranslationRoot}.OPERATION.DELETE`,
  },
  {
    value: 'deprecate',
    label: `${filtersTranslationRoot}.OPERATION.DEPRECATE`,
  },
  {
    value: 'star',
    label: `${filtersTranslationRoot}.OPERATION.STAR`,
  },
  {
    value: 'stop',
    label: `${filtersTranslationRoot}.OPERATION.STOP`,
  },
];

const entityOptions: EntityOption[] = [
  {
    value: 'certificate',
    label: `${filtersTranslationRoot}.ENTITY.CERTIFICATE`,
  },
  {
    value: 'cluster',
    label: `${filtersTranslationRoot}.ENTITY.CLUSTER`,
  },
  {
    value: 'data sink',
    label: `${filtersTranslationRoot}.ENTITY.DATASINK`,
  },
  {
    value: 'debug pipeline',
    label: `${filtersTranslationRoot}.ENTITY.DEBUG_PIPELINE`,
  },
  {
    value: 'label',
    label: `${filtersTranslationRoot}.ENTITY.LABEL`,
  },
  {
    value: 'listener',
    label: `${filtersTranslationRoot}.ENTITY.LISTENER`,
  },
  {
    value: 'lookup',
    label: `${filtersTranslationRoot}.ENTITY.LOOKUP`,
  },
  {
    value: 'lookup version',
    label: `${filtersTranslationRoot}.ENTITY.LOOKUP_VERSION`,
  },
  {
    value: 'pipeline',
    label: `${filtersTranslationRoot}.ENTITY.PIPELINE`,
  },
  {
    value: 'secret',
    label: `${filtersTranslationRoot}.ENTITY.SECRET`,
  },
  {
    value: 'tenant',
    label: `${filtersTranslationRoot}.ENTITY.TENANT`,
  },
  { value: 'user', label: `${filtersTranslationRoot}.ENTITY.USER` },
  { value: 'zone', label: `${filtersTranslationRoot}.ENTITY.ZONE` },
];

export const filtersToSelect: FilterToSelect[] = [
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.OPERATION',
    field: 'operation',
    type: 'category',
    iconName: 'action',
    isOpen: true,
    isFixed: false,
    data: operationOptions,
  },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.ENTITY',
    field: 'entity',
    type: 'category',
    iconName: 'sankey',
    isOpen: true,
    isFixed: false,
    data: entityOptions,
  },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.NAME',
    field: 'name',
    type: 'string',
    iconName: 'name',
    isOpen: true,
    isFixed: false,
  },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.IP_ADDRESS',
    field: 'ipaddress',
    type: 'string',
    iconName: 'ip',
    isOpen: true,
    isFixed: false,
  },
  {
    label: 'SETTINGS.TENANT.ACTIVITY.FIELDS.USER',
    field: 'user',
    type: 'category',
    iconName: 'user',
    isOpen: true,
    isFixed: false,
    data: [],
    // isSuggested: true,
  },
];
