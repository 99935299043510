import { useMemo } from 'react';
import { getLatestActions } from '../components/atoms/MetadataLogo';
import { useMetadata } from './actions/useMetadata';

const FALLBACK_COLOR = '#5FA8D3';

// recieves a HEX color and returns an object with the main, medium and light colors
export const getMetadataColorVars = (color?: string) => {
  // pure white color is not allowed, so we fallback to a default color in that case
  const mainColor =
    color === '#FFFFFF' ? FALLBACK_COLOR : (color ?? FALLBACK_COLOR);

  return {
    '--color-metadata-main': mainColor,
    '--color-metadata-medium': `${mainColor}66`, // 40% opacity
    '--color-metadata-light': `${mainColor}24`, // 14% opacity
  } as React.CSSProperties;
};

export const useMetadataColor = ({ name }: { name?: string }) => {
  const { data: metadata } = useMetadata();

  const latestActions = useMemo(() => getLatestActions(metadata), [metadata]);

  const action = latestActions.find((action) => action.name === name);
  const color = action?.ui.icons?.style.backgroundColor;

  return {
    color,
    colorCssVars: getMetadataColorVars(color),
  };
};
