import { Suspense, lazy } from 'react';
import { Outlet, Route } from 'react-router-dom';
import { z } from 'zod';
import WithPermissions from '../../../hooks/controlAccess/WithPermissions';
import AreValidParams from '../AreValidParams';
import { dataSinkCreatePipelineRoutes } from '../DatasinksNestedRoutes';
import { listenerCreatePipelineRoutes } from '../ListenersNestedRoutes';

const PipelineCardDetail = lazy(
  () => import('../../../components/organisms/PipelineCardDetail'),
);

const ActionDetail = lazy(() => import('../../../pages/ActionDetail'));
const DatasinkEdit = lazy(() => import('../../../pages/DatasinkEdit'));
const ListenerEdit = lazy(() => import('../../../pages/ListenerEdit'));
const PipelineDetail = lazy(() => import('../../../pages/PipelineDetail'));

const validatePipelineDetailParams = (
  params: Record<string, string>,
): boolean => {
  const pipelineDetailsParams = z.object({
    pipelineId: z.union([z.literal('create'), z.string().startsWith('PL_')]),
    pipelineVersion: z.optional(z.string()),
  });

  return pipelineDetailsParams.safeParse(params).success;
};

export const pipelineFocusRoute = (
  <Route
    path="focus/:pipelineId/:versionId"
    element={
      <WithPermissions action="read" resource="pipeline">
        <Suspense>
          <PipelineCardDetail />
        </Suspense>
      </WithPermissions>
    }
  />
);

export const pipelineDetailsRoute = (
  <Route path="pipelines">
    <Route
      path=":pipelineId/:pipelineVersion?"
      element={
        <AreValidParams validate={validatePipelineDetailParams}>
          <Suspense>
            <PipelineDetail />
          </Suspense>
          <Outlet />
        </AreValidParams>
      }
    >
      <Route
        path="action/:actionId/:actionVersion"
        element={
          <WithPermissions resource="action">
            <Suspense>
              <ActionDetail />
            </Suspense>
          </WithPermissions>
        }
      />
      <Route
        path="listener/:listenerId/edit"
        element={
          <WithPermissions resource="listener">
            <Suspense>
              <ListenerEdit />
            </Suspense>
          </WithPermissions>
        }
      />
      <Route
        path="datasink/:datasinkId/edit"
        element={
          <WithPermissions resource="datasink">
            <Suspense>
              <DatasinkEdit />
            </Suspense>
          </WithPermissions>
        }
      />
      {listenerCreatePipelineRoutes}
      {dataSinkCreatePipelineRoutes}
    </Route>
  </Route>
);
