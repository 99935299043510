import { Flex, Scroll } from '@nstrlabs/ixel';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/atoms/Separator';
import SettingsHeader from '../../../components/atoms/SettingsHeader';
import Filters from '../../../components/organisms/NewFilters';
import UseFiltersProvider from '../../../components/organisms/NewFilters/FiltersProvider';
import ActivityTable from './ActivityTable';
import { filters, filtersToSelect } from './filters';
import styles from './index.module.css';

const Activity = () => {
  const { t } = useTranslation();

  return (
    <Scroll
      className={styles.scrollContainer}
      paddingX="xl"
      style={
        {
          '--scroll-padding-right': 'var(--xs)',
          '--scrollbar-gutter': 'stable',
        } as React.CSSProperties
      }
    >
      <Flex flexDirection="column" className={styles.container}>
        <SettingsHeader
          squareClassName={styles.square}
          title={t('SETTINGS.TENANT.ACTIVITY.TITLE')}
          description={t('SETTINGS.TENANT.ACTIVITY.DESCRIPTION')}
          icon="list"
        />
        <Separator />
        <UseFiltersProvider
          value={{
            filtersToSelect,
            filters,
          }}
        >
          <Flex gap="xs">
            <Filters addMoreFilters={false} />
          </Flex>
          <ActivityTable />
        </UseFiltersProvider>
      </Flex>
    </Scroll>
  );
};

export default Activity;
