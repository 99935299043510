import {
  Breadcrumb,
  Button,
  FixedFooter,
  Flex,
  Icon,
  StackView,
} from '@nstrlabs/ixel';
import type { DomainError } from '@nstrlabs/utils';
import {
  type FormEvent,
  type FormEventHandler,
  type MouseEvent,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Separator from '../../../components/atoms/Separator';
import StepLayout from '../../../components/organisms/StepLayout';
import { useCreateSecret } from '../../../hooks/organizationSettings/secrets/useCreateSecret';
import { useToast } from '../../../hooks/useToast';
import SecretCreateConfiguration from './SecretCreateConfiguration';
import SecretCreateMetadata from './SecretCreateMetadata';
import SecretCreateValue from './SecretCreateValue';
import styles from './index.module.css';

type SecretCreateProps = {
  handleCloseStackedview: (state: boolean) => void;
};
const SecretCreate = ({ handleCloseStackedview }: SecretCreateProps) => {
  const { t } = useTranslation('translation');
  const { mutate, isPending } = useCreateSecret();
  const source = useToast();

  const [viewStatus, setViewStatus] = useState<'open' | 'closed'>('open');
  const [secretTags, setSecretTags] = useState<string[]>([]);
  const [disabledSave, setDisabledSave] = useState<boolean>(true);
  const [secretNameError, setSecretNameError] = useState<string | undefined>(
    undefined,
  );
  const [secretDescriptionError, setSecretDescriptionError] = useState<
    string | undefined
  >(undefined);
  const [secretExpirationDateError, setSecretExpirationDateError] = useState<
    string | undefined
  >(undefined);
  const [secretValueError, setSecretValueError] = useState<string | undefined>(
    undefined,
  );
  const requiredFields = useRef<Record<string, string | boolean>>({
    name: '',
    description: '',
    expirationDate: false,
    value: '',
  });
  const handleRequiredFieldsNotEmpty = (
    field: string,
    fieldValue: string | boolean,
  ) => {
    requiredFields.current[field] = fieldValue;
    const { name, expirationDate, value } = requiredFields.current;
    const allFieldsValid = name && expirationDate && value;
    setDisabledSave(!allFieldsValid);
  };

  const onClosed = () => {
    handleCloseStackedview(false);
  };

  const breadcrumbSteps = [
    { name: 'Secrets management' },
    { name: 'New secret' },
  ];

  const closeView = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setViewStatus('closed');
  };

  const handleCreateSecret: FormEventHandler<HTMLFormElement> = (
    event: FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    const formData = new FormData(event.target as HTMLFormElement).entries();

    const secretCreator = Array.from(formData).reduce(
      (prev, [key, value]) => ({
        ...prev,
        [key]: key === 'tags' ? secretTags : value,
      }),
      { name: '', description: '', value: '' },
    );
    mutate(secretCreator, {
      onSuccess: () => {
        source.publish({
          level: 'default',
          content: t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SUCCESS'),
        });
        onClosed();
      },
      onError: (error) => {
        if ((error as unknown as DomainError).code === 'WEB-00031') {
          setSecretNameError(t((error as unknown as DomainError).code));
        }
        if ((error as unknown as DomainError).code === 'WEB-00028') {
          setSecretDescriptionError(t((error as unknown as DomainError).code));
        }
        if ((error as unknown as DomainError).code === 'WEB-00029') {
          setSecretExpirationDateError(
            t((error as unknown as DomainError).code),
          );
        }
        if ((error as unknown as DomainError).code === 'WEB-00030') {
          setSecretValueError(t((error as unknown as DomainError).code));
        }
        if ((error as unknown as DomainError).code === 'WEB-00032') {
          setSecretNameError(t((error as unknown as DomainError).code));
        }
        source.publish({
          level: 'alert',
          content: t((error as unknown as DomainError).code),
        });
      },
    });
  };

  const handleChangeFieldsErrors = (
    field: string,
    value: string | undefined,
  ) => {
    if (field === 'description') setSecretDescriptionError(value);
    if (field === 'name') setSecretNameError(value);
    if (field === 'expirationDate') setSecretExpirationDateError(value);
    if (field === 'value') setSecretValueError(value);
  };
  const handleChangeTags = (tags: string[]) => {
    setSecretTags(tags);
  };

  return (
    <StackView
      status={viewStatus}
      classNameContent={styles.stackviewCreate}
      onClosed={onClosed}
    >
      <form
        className={styles.stackviewForm}
        noValidate
        onSubmit={handleCreateSecret}
      >
        <Flex flexDirection="column" className={styles.stackviewContainer}>
          <Flex
            alignItems="center"
            justifyContent="spaceBetween"
            className={styles.header}
          >
            <Breadcrumb steps={breadcrumbSteps} />
            <Button
              variant="link"
              mode="primary"
              icon={<Icon name="close" />}
              onClick={closeView}
            />
          </Flex>
          <Separator />
          <Flex flexDirection="column" className={styles.content}>
            <StepLayout
              content={[
                {
                  icon: 'tag',
                  title: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_METADATA_TITLE',
                  ),
                  description: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_METADATA_DESCRIPTION',
                  ),
                  squareClassName: styles.square,
                  children: (
                    <SecretCreateMetadata
                      secretNameError={secretNameError}
                      secretDescriptionError={secretDescriptionError}
                      secretTags={secretTags}
                      onChangeTags={handleChangeTags}
                      onChangeFieldsErrors={handleChangeFieldsErrors}
                      requiredFieldsNotEmpty={handleRequiredFieldsNotEmpty}
                    />
                  ),
                },
              ]}
            />
            <Separator />
            <StepLayout
              content={[
                {
                  icon: 'tag',
                  title: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_CONFIGURATION_TITLE',
                  ),
                  description: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_CONFIGURATION_DESCRIPTION',
                  ),
                  squareClassName: styles.square,
                  children: (
                    <SecretCreateConfiguration
                      secretExpirationDateError={secretExpirationDateError}
                      onChangeFieldsErrors={handleChangeFieldsErrors}
                      requiredFieldsNotEmpty={handleRequiredFieldsNotEmpty}
                    />
                  ),
                },
              ]}
            />
            <Separator />
            <StepLayout
              content={[
                {
                  icon: 'tag',
                  title: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_VALUE_TITLE',
                  ),
                  description: t(
                    'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SECTION_VALUE_DESCRIPTION',
                  ),
                  squareClassName: styles.square,
                  children: (
                    <SecretCreateValue
                      secretValueError={secretValueError}
                      onChangeFieldsErrors={handleChangeFieldsErrors}
                      requiredFieldsNotEmpty={handleRequiredFieldsNotEmpty}
                    />
                  ),
                },
              ]}
            />
          </Flex>
          <FixedFooter
            abortAction={
              <>
                {/* <button type="submit">Submit</button>s */}
                <Button mode="primary" variant="outline" onClick={closeView}>
                  {t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.CANCEL')}
                </Button>
              </>
            }
            continueAction={
              <Button
                mode="primary"
                variant="solid"
                type="submit"
                disabled={disabledSave}
                loading={isPending}
              >
                {t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.SAVE')}
              </Button>
            }
            variant="default"
          />
        </Flex>
      </form>
    </StackView>
  );
};

export default SecretCreate;
