import { Flex, Typography } from '@nstrlabs/ixel';
import type { ReactNode } from 'react';
import { ReactComponent as Logo } from '../../../assets/Logo-text.svg';
import { useUserSettingsSafe } from '../../../hooks/settings/useUserSettings';
import styles from './index.module.css';

const Auth = ({ children }: { children: ReactNode }) => {
  const { settings } = useUserSettingsSafe();

  return (
    <div className={styles.fullScreen}>
      <Flex
        flexDirection="column"
        justifyContent="spaceBetween"
        alignItems="flexStart"
        className={styles.leftLogin}
      >
        <div className={styles.logo}>
          <Logo />
        </div>
        {children}
        <Typography className={styles.copyText}>
          ©Onum {new Date().getFullYear()}
        </Typography>
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="center"
        className={styles.rightLogin}
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className={styles.info}
        >
          <img
            className={styles.infoMedia}
            src={`/lines_${settings.theme}.svg`}
            alt="Onum login"
          />
        </Flex>
      </Flex>
    </div>
  );
};

export default Auth;
