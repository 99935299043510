import {
  Button,
  Flex,
  Icon,
  Modal,
  Scroll,
  SelectField,
  Tag,
  Toast,
  TooltipAction,
  Typography,
} from '@nstrlabs/ixel';
import { IfCanAccess, useCanAccess } from '@nstrlabs/sdk';
import { emptyFn, eventEmitter } from '@nstrlabs/utils';
import {
  Suspense,
  createContext,
  lazy,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../../components/atoms/DropdownMenu';
import Separator from '../../../../components/atoms/Separator';
import SettingsHeader from '../../../../components/atoms/SettingsHeader';
import NoData from '../../../../components/molecules/NoData';
import SkeletonTable from '../../../../components/organisms/FullTable/SkeletonTable';
import Table from '../../../../components/organisms/FullTable/Table';
import HomesSearch from '../../../../components/organisms/HomesSearch';
import type { ToastCommand } from '../../../../components/providers/ToastRoot/parseToastCommand';
import ToastStacked from '../../../../components/providers/ToastRoot/toastStacked';
import { type User, useUsers } from '../../../../hooks/controlAccess/useUsers';
import { useConfirmation } from '../../../../hooks/useConfirmation';
import { UseToastProvider, useToast } from '../../../../hooks/useToast';
import { useUser } from '../../../../hooks/useUser';
import styles from './index.module.css';

const InviteUsers = lazy(
  () => import('../../../../components/organisms/InviteUsers'),
);

const source = eventEmitter<ToastCommand>();

const OperationsContext = createContext<{
  deleteUsers: (ids: string[]) => void;
  roles: { id: string; name: string }[];
  tenantId: string;
  updateRoles: (update: { userIds: string[]; roleId: string }) => void;
  reinviteUsers: (ids: string[]) => void;
} | null>(null);

const RenderUserName = ({ email, name, surname, tenants }: User) => {
  const operations = useContext(OperationsContext);
  const isActive =
    tenants.find(({ id }) => id === operations?.tenantId)?.status ===
    'ACCEPTED';

  return (
    <Flex flexDirection="column" gap="xxs">
      {!isActive && name === 'unknown' && surname === 'unknown' ? null : (
        <Typography bold>{`${name} ${surname}`}</Typography>
      )}
      <Typography className={styles.mail} size="small">
        {email}
      </Typography>
    </Flex>
  );
};

const RenderRoleName = ({ name }: User['roles'][number]) => (
  <Typography className={styles.roleOptions}>{name}</Typography>
);

const RenderRoleNameToast = ({ name }: User['roles'][number]) => (
  <Typography className={styles.roleOptions} bold>
    {name}
  </Typography>
);

const RenderRoles = ({ id: userId, roles }: User) => {
  const operations = useContext(OperationsContext);
  const { canAccess } = useCanAccess();
  const { UID: currentUserId, AccessMap } = useUser();
  const canEdit = canAccess({ action: 'update', resource: 'user' });
  const hasOwner = roles.some(({ name }) => name === 'owner');
  const currentRole = roles[0]?.id;

  if (
    currentUserId === userId ||
    (AccessMap.Roles.some((role) => role !== 'owner') && hasOwner) ||
    !canEdit
  ) {
    return (
      <div className={styles.disabledRole}>
        <RenderRoleName {...roles[0]} />
      </div>
    );
  }

  return (
    <SelectField<User['roles'][number], 'id'>
      id={`role_selector_${userId}`}
      optionKey="id"
      value={currentRole}
      options={operations?.roles ?? []}
      variant="plain"
      selectSize="fit-content"
      fullWidth={false}
      onChange={(roleId) =>
        operations?.updateRoles({ userIds: [userId], roleId })
      }
      renderOption={RenderRoleName}
      renderValue={RenderRoleName}
      disabled={!canEdit}
    />
  );
};

const RenderStatus = ({ tenants }: User) => {
  const operations = useContext(OperationsContext);
  const { t } = useTranslation();
  const isActive =
    tenants.find(({ id }) => id === operations?.tenantId)?.status ===
    'ACCEPTED';

  return (
    <Tag
      className={styles.status}
      variant={isActive ? 'published' : 'generic'}
      text={t(
        isActive
          ? 'SETTINGS.TENANT.USERS.ACCEPTED'
          : 'SETTINGS.TENANT.USERS.PENDING',
      )}
    />
  );
};

const RenderOptions = ({ email, id, roles, tenants }: User) => {
  const operations = useContext(OperationsContext);
  const toastService = useToast();
  const { t } = useTranslation();
  const { UID: currentUserId, AccessMap } = useUser();
  const { canAccess } = useCanAccess();
  const confirmation = useConfirmation();
  const canDelete = canAccess({ action: 'delete', resource: 'user' });
  const hasOwner = roles.some(({ name }) => name === 'owner');
  const isActive =
    tenants.find((tenant) => tenant.id === operations?.tenantId)?.status ===
    'ACCEPTED';

  return (
    <DropdownMenu
      placement="bottom-end"
      onClick={(e) => e.stopPropagation()}
      trigger={
        <Button
          as="span"
          variant="link"
          mode="primary"
          title="Row actions"
          icon={<Icon name="more" />}
          id="action-button"
        />
      }
    >
      {({ closeDropdown }) => (
        <Flex
          justifyContent="center"
          flexDirection="column"
          className={styles.actionsList}
          onClick={(e) => e.stopPropagation()}
        >
          <TooltipAction
            className={styles.option}
            icon={<Icon name="copy" />}
            name={t('SETTINGS.TENANT.USERS.COPY_EMAIL', { count: 1 })}
            onClick={() => {
              navigator.clipboard.writeText(email);
              toastService.publish({
                content: t('SETTINGS.TENANT.USERS.COPIED_EMAIL', {
                  count: 1,
                }),
              });
              closeDropdown();
            }}
          />
          {isActive ? null : (
            <IfCanAccess action="update" resource="user">
              <TooltipAction
                className={styles.option}
                onClick={() => {
                  operations?.reinviteUsers([id]);
                  closeDropdown();
                }}
                icon={<Icon name="mail" />}
                name={t('SETTINGS.TENANT.USERS.RESEND_INVITE')}
              />
            </IfCanAccess>
          )}
          {!canDelete ||
          currentUserId === id ||
          (AccessMap.Roles.some((role) => role !== 'owner') &&
            hasOwner) ? null : (
            <TooltipAction
              className={styles.option}
              onClick={async () => {
                closeDropdown();
                if (
                  !(await confirmation({
                    title: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_TITLE', {
                      count: 1,
                    }),
                    subtitle: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_SUBTITLE'),
                    cancel: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_CANCEL'),
                    confirm: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_CONFIRM'),
                    className: styles.confirmationModal,
                  }))
                )
                  return;
                operations?.deleteUsers([id]);
              }}
              icon={<Icon name="remove" />}
              name={t('SETTINGS.TENANT.USERS.DELETE_USER')}
              destructive
            />
          )}
        </Flex>
      )}
    </DropdownMenu>
  );
};

const COLUMNS = [
  {
    checkable: true,
    className: styles.selectCell,
    id: 'id',
    maxWidth: 40,
    moveable: false,
    pinnable: false,
    sortable: false,
  },
  {
    cellRenderer: RenderUserName,
    id: 'name',
    moveable: false,
    name: 'SETTINGS.TENANT.USERS.USER',
    pinnable: false,
  },
  {
    cellRenderer: RenderRoles,
    className: styles.roleCell,
    id: 'surname', // Hack para que el filter pueda utilizar la propiedad surname para filtrar, el componente renderiza la prop role
    moveable: false,
    name: 'SETTINGS.TENANT.USERS.ROLE',
    pinnable: false,
  },
  {
    cellRenderer: RenderStatus,
    id: 'status',
    moveable: false,
    name: 'SETTINGS.TENANT.USERS.STATUS',
    pinnable: false,
  },
  {
    cellRenderer: RenderOptions,
    headerClassName: styles.actionHeader,
    hidden: false,
    id: 'email',
    maxWidth: 66,
    moveable: false,
    pinnable: false,
    sortable: false,
    pinned: 'right' as const,
  },
];

const getUsersId = ({ id }: User) => id;

const Users = () => {
  const { t } = useTranslation();
  const toastService = useToast();
  const {
    users,
    deleteUsers,
    updateRoles,
    inviteUsers,
    reinviteUsers,
    tenantId,
    roles,
    isLoading,
  } = useUsers();
  const { UID: currentUserId, AccessMap } = useUser();
  const [selected, setSelected] = useState<Set<string>>(new Set());
  const [search, setSearch] = useState('');
  const emitter = useMemo(() => eventEmitter<{ type: 'deselect' }>(), []);
  const operations = useMemo(
    () => ({
      deleteUsers: (ids: string[]) => {
        deleteUsers(ids);
        setSelected(() => new Set());
      },
      roles,
      tenantId,
      updateRoles,
      reinviteUsers,
    }),
    [deleteUsers, tenantId, roles, updateRoles, reinviteUsers],
  );
  const [openInviteUsers, setOpenInviteUsers] = useState(false);
  const confirmation = useConfirmation();

  const { canAccess } = useCanAccess();

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  useEffect(() => {
    const onKeyPress = (e: KeyboardEvent) => {
      if (!(e.key === 'Escape' && selected.size > 0)) return;

      e.preventDefault();
      e.stopImmediatePropagation();
      emitter.publish({ type: 'deselect' });
      setSelected(new Set());
    };

    window.addEventListener('keyup', onKeyPress, true);

    return () => {
      window.removeEventListener('keyup', onKeyPress, true);
    };
  }, [selected]);

  const canEdit = canAccess({ action: 'update', resource: 'user' });
  const canDelete = canAccess({ action: 'delete', resource: 'user' });

  const hasCurrentUser = selected.has(currentUserId);
  const hasCurrentUserOwnerRole = AccessMap.Roles.some(
    (role) => role === 'owner',
  );
  const someSelectedUserHasOwnerRole = users.some(
    ({ id, roles: r }) =>
      selected.has(id) && r.some(({ name }) => name === 'owner'),
  );

  const someSelectedUserHasAccepted = users.some(
    ({ id, tenants }) =>
      selected.has(id) &&
      tenants.find((tenant) => tenant.id === operations?.tenantId)?.status ===
        'ACCEPTED',
  );

  const toastHasEditOption =
    canEdit &&
    !hasCurrentUser &&
    ((someSelectedUserHasOwnerRole && hasCurrentUserOwnerRole) ||
      !someSelectedUserHasOwnerRole);

  const toastHasDeleteOption =
    canDelete &&
    !hasCurrentUser &&
    ((someSelectedUserHasOwnerRole && hasCurrentUserOwnerRole) ||
      !someSelectedUserHasOwnerRole);

  const toastHasResendOption =
    !someSelectedUserHasAccepted &&
    canAccess({ action: 'update', resource: 'user' });

  const hasResults = search
    ? users.some(
        ({ email, name, surname }) =>
          email.toLowerCase().includes(search.toLowerCase()) ||
          name.toLowerCase().includes(search.toLowerCase()) ||
          surname.toLowerCase().includes(search.toLowerCase()),
      )
    : true;

  const view = isLoading ? 'loading' : hasResults ? 'table' : 'empty';

  return (
    <OperationsContext.Provider value={operations}>
      <Scroll
        className={styles.scrollContainer}
        paddingX="xl"
        style={
          {
            '--scroll-padding-right': 'var(--xs)',
            '--scrollbar-gutter': 'stable',
          } as React.CSSProperties
        }
      >
        <Flex flexDirection="column" className={styles.container}>
          <SettingsHeader
            squareClassName={styles.square}
            title={t('SETTINGS.TENANT.USERS.TITLE')}
            description={t('SETTINGS.TENANT.USERS.DESCRIPTION')}
            icon="users"
          />
          <Separator />
          <Flex flexDirection="column">
            <Flex justifyContent="spaceBetween" className={styles.header}>
              <HomesSearch
                value={search}
                alwaysActive
                onSearch={(event) => setSearch(event.target.value)}
              />
              <IfCanAccess action="update" resource="user">
                <Button onClick={() => setOpenInviteUsers(true)}>
                  {t('SETTINGS.TENANT.USERS.ADD_USER')}
                </Button>
              </IfCanAccess>
            </Flex>
            {view === 'table' && (
              <Table
                activeRow=""
                getRowId={getUsersId}
                className={styles.table}
                columns={COLUMNS}
                data={users}
                disableClickSelection
                emitter={emitter}
                groupSelection=""
                hideHeader
                noData={
                  <NoData
                    size="normal"
                    gap="xxl"
                    title={t('PIPELINE.MENU.NO_DATA.TITLE')}
                    subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
                    flexDirection="column"
                    iconName="search"
                  />
                }
                onRowActive={emptyFn}
                onRowSelected={(_, total) =>
                  setSelected(new Set(total.map(({ id }) => id)))
                }
                searchValue={search}
                title=""
              />
            )}
            {view === 'loading' && <SkeletonTable columns={3} />}
            {view === 'empty' && (
              <NoData
                className={styles.emptySearch}
                size="normal"
                gap="xxl"
                title={t('PIPELINE.MENU.NO_DATA.TITLE')}
                subtitle={t('PIPELINE.MENU.NO_DATA.SUBTITLE')}
                flexDirection="column"
                iconName="search"
              />
            )}
          </Flex>
        </Flex>
      </Scroll>
      <div className={styles.toastContainer}>
        <ToastStacked source={source} className={styles.toastStack} />
        {selected.size ? (
          <Toast
            className={styles.toast}
            data-theme="dark"
            onClose={() => {
              emitter.publish({ type: 'deselect' });
              setSelected(new Set());
            }}
          >
            <Flex alignItems="center" gap="sm">
              <Typography bold>
                {t('SETTINGS.TENANT.USERS.SELECTED_USER', {
                  count: selected.size,
                })}
                :
              </Typography>
              <Button
                mode="primary"
                variant="link"
                icon={<Icon name="copy" />}
                onClick={() => {
                  navigator.clipboard.writeText(
                    users
                      .filter(({ id }) => selected.has(id))
                      .map(({ email }) => email)
                      .join(','),
                  );
                  toastService.publish({
                    content: t('SETTINGS.TENANT.USERS.COPIED_EMAIL', {
                      count: selected.size,
                    }),
                  });
                  if (
                    !toastHasEditOption &&
                    !toastHasResendOption &&
                    !toastHasDeleteOption
                  )
                    emitter.publish({ type: 'deselect' });
                }}
              >
                {t('SETTINGS.TENANT.USERS.COPY_EMAIL', {
                  count: selected.size,
                })}
              </Button>
              {toastHasEditOption ? (
                <SelectField<User['roles'][number], 'id'>
                  className={styles.globalRoleSelector}
                  classNameWrapper={styles.globalRoleSelectorWrapper}
                  id="selected_users_role"
                  optionKey="id"
                  variant="plain"
                  options={operations?.roles ?? []}
                  selectSize="fit-content"
                  fullWidth={false}
                  onChange={(roleId) => {
                    updateRoles({
                      userIds: Array.from(selected.values()),
                      roleId,
                    });
                    emitter.publish({ type: 'deselect' });
                  }}
                  placeholder="Change Role"
                  renderOption={RenderRoleName}
                  renderValue={RenderRoleNameToast}
                />
              ) : null}
              {someSelectedUserHasAccepted ? null : (
                <Button
                  mode="primary"
                  variant="link"
                  onClick={() => {
                    reinviteUsers(Array.from(selected.values()));
                    toastService.publish({
                      content: `${selected.size} emails copied to your clipboard`,
                    });
                    emitter.publish({ type: 'deselect' });
                  }}
                >
                  {t('SETTINGS.TENANT.USERS.RESEND_INVITE')}
                </Button>
              )}
              {toastHasDeleteOption ? (
                <Button
                  mode="destructive"
                  variant="link"
                  icon={<Icon name="remove" />}
                  onClick={async () => {
                    if (
                      !(await confirmation({
                        title: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_TITLE', {
                          count: selected.size,
                        }),
                        subtitle: t(
                          'SETTINGS.TENANT.USERS.MODAL_REMOVE_SUBTITLE',
                        ),
                        cancel: t('SETTINGS.TENANT.USERS.MODAL_REMOVE_CANCEL'),
                        confirm: t(
                          'SETTINGS.TENANT.USERS.MODAL_REMOVE_CONFIRM',
                        ),
                        className: styles.confirmationModal,
                      }))
                    )
                      return;
                    deleteUsers(Array.from(selected.values()));
                    emitter.publish({ type: 'deselect' });
                  }}
                />
              ) : null}
              <Separator direction="vertical" />
            </Flex>
          </Toast>
        ) : null}
      </div>
      <Modal open={openInviteUsers} className={styles.modal}>
        <Suspense>
          <InviteUsers
            roles={roles}
            onClose={() => setOpenInviteUsers(false)}
            onSave={(toInvite) => {
              inviteUsers({ users: toInvite });
              setOpenInviteUsers(false);
            }}
          />
        </Suspense>
      </Modal>
    </OperationsContext.Provider>
  );
};

export default function () {
  return (
    <UseToastProvider value={source}>
      <Users />
    </UseToastProvider>
  );
}
