import { useCallback, useMemo } from 'react';
import {
  type Location,
  type NavigateOptions,
  useLocation,
  useNavigate,
} from 'react-router-dom';

type LocationState = {
  from: {
    pathname: string;
  };
};

type UseLocationFromProps = {
  fallbackPath?: string;
  navigateOptions?: NavigateOptions;
};

const getLocation = (
  locationState: LocationState | undefined,
  fallbackPath: string,
) => {
  if (locationState != null) return locationState;
  return { from: { pathname: fallbackPath } };
};

export function useLocationFrom({
  fallbackPath = '/',
  navigateOptions = {},
}: UseLocationFromProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const from = useMemo(
    () => getLocation(locationState, fallbackPath).from,
    [locationState, fallbackPath],
  );

  const goBack = useCallback(
    () => navigate(from, navigateOptions),
    [from, navigate, navigateOptions],
  );
  const goRoot = useCallback(
    () => navigate(fallbackPath, navigateOptions),
    [fallbackPath, navigate, navigateOptions],
  );

  return { from, goBack, goRoot };
}

export function ofLocation(location: Location) {
  return {
    state: { from: location },
  };
}
