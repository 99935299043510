import { domainError } from '@nstrlabs/utils';
import type { TenantId } from '../../tenants/domain/TenantId';
import type { ActivityTimestamp } from './ActivityTimestamp';
import type { Company } from './Company';
import type { Email } from './Email';
import type { Name } from './Name';
import type { Password } from './Password';
import type { StrongPassword } from './StrongPassword';
import type { Surname } from './Surname';
import type { Token } from './Token';
import type { UserId } from './UserId';

export const errorUserAndPasswordNotMatch = domainError()(
  'error:login',
  'WEB-11008',
  'The email address and password do not match',
);
export type ErrorUserAndPasswordNotMatch = ReturnType<
  typeof errorUserAndPasswordNotMatch
>;

export const errorAlreadyExistsWithDifferentCredential = domainError()(
  'error:login',
  'WEB-11009',
  'The email address already exists with different credentials',
);
export type ErrorAlreadyExistsWithDifferentCredential = ReturnType<
  typeof errorAlreadyExistsWithDifferentCredential
>;

export const errorAccountNotAuthorized = domainError()(
  'error:login',
  'WEB-10010',
  'The email address is not authorized',
);
export type ErrorAccountNotAuthorized = ReturnType<
  typeof errorAccountNotAuthorized
>;

export type Providers = 'google' | 'microsoft';

export type AuthRepository = {
  loginWithEmailAndPassword: (
    email: Email,
    password: Password,
  ) => Promise<Token>;

  loginWithIdentityProvider: (provider: Providers) => Promise<void>;

  loginRedirectResult: () => Promise<Token | null>;

  onIdentityProviderTokenChange: (
    observer: (
      token: Token | null,
      error?: Error | ErrorAccountNotAuthorized,
    ) => void,
  ) => () => void;

  logout: () => Promise<void>;

  logoutOnIdle: () => void;

  getActivityTimestamp: () => Promise<ActivityTimestamp | null>;

  saveActivityTimestamp: () => void;

  removeActivityTimestamp: () => void;

  getToken: () => Promise<Token>;

  saveToken: (token: Token) => Promise<Token>;

  removeToken: () => Promise<void>;

  authenticate: (token: Token, tenantId: TenantId | null) => Promise<Token>;

  updatePassword: (
    userId: UserId,
    oldPassword: Password,
    newPassword: StrongPassword,
  ) => Promise<{ email: Email; password: StrongPassword }>;

  verifyUser: (token: Token) => Promise<void>;

  forgetPassword: (email: Email) => Promise<void>;

  resetPassword: (
    password: StrongPassword,
    confirmPassword: StrongPassword,
    resetPasswordToken: Token,
  ) => Promise<void>;

  verifyNewUser: (
    token: Token,
    name: Name,
    surname: Surname,
    company: Company,
    password: StrongPassword,
    confirmPassword: StrongPassword,
  ) => Promise<{ email: Email; password: StrongPassword }>;

  resendInviteUsers: (ids: UserId[]) => Promise<void>;
  changeTenant: (id: TenantId) => Promise<Token>;
  getTenant: () => Promise<TenantId | null>;
};
