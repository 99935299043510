import {
  type LogLevels,
  Logger,
  type LoggerClientMethods,
} from '../domain/Logger';

export class ConsoleLogger extends Logger {
  constructor(logLevelFilter?: LogLevels) {
    const client: LoggerClientMethods = {
      debug: (params) => console.debug(JSON.stringify(params)),
      log: (params) => console.log(JSON.stringify(params)),
      info: (params) => console.info(JSON.stringify(params)),
      warn: (params) => console.warn(JSON.stringify(params)),
      error: (params) => console.error(JSON.stringify(params)),
    };
    super(client, logLevelFilter);
  }
}
