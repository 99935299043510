import { Flex, Icon, type IconName, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { stringToSlug } from '@nstrlabs/utils';
import styles from './index.module.css';

export type FilterSearchItemProps = {
  element: string;
  onClick: () => void;
  iconName?: IconName;
};

const FilterSelectorOption = ({
  onClick,
  element,
  iconName,
}: FilterSearchItemProps) => {
  const { t } = useTranslation();
  return (
    <button
      className={clsx(styles.listElement)}
      type="button"
      onClick={onClick}
      data-cy={`add-filter-button-${stringToSlug(element)}`}
      data-testid={`add-filter-button-${stringToSlug(element)}`}
    >
      <Flex gap="xs" alignContent="center" alignItems="center">
        {iconName ? <Icon name={iconName} /> : null}

        <Typography>{t(element)}</Typography>
      </Flex>
    </button>
  );
};

export default FilterSelectorOption;
