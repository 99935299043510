import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ContainerUseCase } from '../../../context';
import { versionsStatusOrder } from '../../../utils/pipeline/pipelinesInfo';
import { useContainer } from '../../useContainer';

export type UseSecretUsages = {
  usages: ContainerUseCase<'secrets:getUsages', 'result'>;
  isLoading: boolean;
  cancelAction: () => Promise<void>;
};

const sortPipelineVersionsByStatus = (
  versions: ContainerUseCase<
    'secrets:getUsages',
    'result'
  >['pipelines'][number]['versions'],
) =>
  versions.sort((a, b) => {
    return (
      versionsStatusOrder.indexOf(a.status) -
      versionsStatusOrder.indexOf(b.status)
    );
  });

export function useUsages(
  secretId: string,
  sortVersionsByStatus?: boolean,
): UseSecretUsages {
  const container = useContainer();
  const queryClient = useQueryClient();
  const initData = {
    pipelines: [],
    listeners: [],
    datasinks: [],
    totalUsages: 0,
  };

  const { data = initData, isLoading } = useQuery({
    queryKey: ['secret:usages', secretId],
    queryFn: () => container.call('secrets:getUsages', secretId),
  });

  return {
    usages: sortVersionsByStatus
      ? {
          ...data,
          pipelines: data.pipelines.map((p) => ({
            ...p,
            versions: sortPipelineVersionsByStatus(p.versions),
          })),
        }
      : data,
    isLoading,
    cancelAction: () =>
      queryClient.cancelQueries({ queryKey: ['secret:usages'] }),
  };
}
