import { Button, Flex, TextArea, Typography } from '@nstrlabs/ixel';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './index.module.css';

type SecretCreateValueProps = {
  secretValueError: string | undefined;
  onChangeFieldsErrors: (field: string, value: string | undefined) => void;
  requiredFieldsNotEmpty: (field: string, fieldValue: string) => void;
};

const SecretCreateValue = ({
  secretValueError,
  onChangeFieldsErrors,
  requiredFieldsNotEmpty,
}: SecretCreateValueProps) => {
  const { t } = useTranslation('translation');
  const valueRef = useRef<HTMLTextAreaElement>(null);
  const [secretValue, setSecretValue] = useState<string>('');

  const [showValue, setShowValue] = useState<boolean>(false);

  const handleAddNewValue = () => {
    setShowValue(true);
    valueRef.current?.focus();
  };
  return (
    <Flex flexDirection="column" gap="sm">
      <Typography variant="body">
        {t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_VALUE.VALUE_DESCRIPTION')}
      </Typography>
      {showValue ? (
        <TextArea
          autoFocus
          name="value"
          id="value"
          value={typeof secretValue === 'string' ? secretValue : undefined}
          error={
            typeof secretValueError === 'string' ? secretValueError : undefined
          }
          required
          onChange={(event) => {
            requiredFieldsNotEmpty('value', event.target.value);
            if (event.target.value === '') {
              onChangeFieldsErrors('value', t('WEB-00030'));
              setSecretValue(event.target.value);
              return;
            }
            if (secretValue !== event.target.value) {
              onChangeFieldsErrors('value', undefined);
              setSecretValue(event.target.value);
            }
          }}
          data-cy="text-field-secret-value"
        />
      ) : (
        <Button
          className={style.buttonAddNewValue}
          mode="main"
          size="regular"
          variant="ghost"
          onClick={handleAddNewValue}
          data-cy="add-secret-value"
        >
          {t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_VALUE.VALUE_BUTTON')}
        </Button>
      )}
    </Flex>
  );
};

export default SecretCreateValue;
