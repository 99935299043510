import { Button, Tooltip, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { Link, useLinkClickHandler } from 'react-router-dom';

import { stringToSlug } from '@nstrlabs/utils';
import Shortcut from '../../atoms/Shortcut';

import styles from './index.module.css';

type MenuOptionProps = {
  icon: ReactNode;
  to: string;
  title: string;
  shortcut?: ReactNode;
  active?: boolean;
  collapsed?: boolean;
  id?: string;
};

const MenuOption = ({
  active,
  collapsed,
  icon,
  shortcut,
  title,
  to,
  id,
}: MenuOptionProps) => {
  /*
    This handle function is needed to avoid click twice in a menu option
    when a dropdown is open and tries to navigate to a different view
  */
  const handleClick = useLinkClickHandler(to);

  if (collapsed) {
    const menuOptionClassName = clsx(styles.menuOptionCollapsed, {
      [styles.active]: active,
    });

    return (
      <Tooltip
        content={<Typography bold>{title}</Typography>}
        placement="right"
      >
        <Link
          to={to}
          className={menuOptionClassName}
          onClick={handleClick}
          {...(id ? { 'data-cy': stringToSlug(`menu-option-${id}`) } : {})}
        >
          <Button icon={icon} size="regular" variant="link" mode="primary" />
        </Link>
      </Tooltip>
    );
  }

  const menuOptionClassName = clsx('MenuOption-Root', styles.menuOption, {
    [styles.active]: active,
  });

  return (
    <Link
      to={to}
      className={menuOptionClassName}
      onClick={handleClick}
      {...(id ? { 'data-cy': stringToSlug(`menu-option-${id}`) } : {})}
    >
      <Button
        className="MenuOption-Icon"
        icon={icon}
        size="small"
        variant="link"
        mode="primary"
      />
      <Typography
        as="span"
        className={clsx('MenuOption-Title', styles.text)}
        bold={active}
      >
        {title}
      </Typography>
      {shortcut ? <Shortcut>{shortcut}</Shortcut> : null}
      {/* <Button
        variant="link"
        size="small"
        icon={<Icon name="more" />}
      /> */}
    </Link>
  );
};

export default MenuOption;
