import clsx from 'clsx';
import type { ComponentPropsWithoutRef } from 'react';
import { useMetadataColor } from '../../../hooks/useMetadataColor';
import MetadataLogo, { type MetadataLogoProps } from '../../atoms/MetadataLogo';
import styles from './index.module.css';

export type SquareMetadataLogoProps = ComponentPropsWithoutRef<'div'> &
  MetadataLogoProps & {
    color?: 'blue' | 'green' | 'red';
    variant?: 'regular' | 'fit';
  };

const SquareMetadataLogo = ({
  className,
  size = 16,
  variant = 'regular',
  name,
  ...props
}: SquareMetadataLogoProps) => {
  const { colorCssVars } = useMetadataColor({ name });

  return (
    <div
      {...props}
      className={clsx(styles.container, className, {
        [styles.noExtraSize]: variant === 'fit',
      })}
      style={colorCssVars}
    >
      <MetadataLogo name={name} type="white" size={size} />
    </div>
  );
};

export default SquareMetadataLogo;
