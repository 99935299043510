import { Icon } from '@nstrlabs/ixel';
import { useMemo, useState } from 'react';
import { gt } from 'semver';
import type { ContainerUseCase } from '../../../context';
import type { UserSettings } from '../../../context/settings/domain/UserSettings';
import {
  type UseMetadata,
  useMetadata,
} from '../../../hooks/actions/useMetadata';
import { useUserSettings } from '../../../hooks/settings/useUserSettings';

export type MetadataLogoDefinition = ContainerUseCase<
  'actions:getMetadata',
  'result'
>[number]['ui']['icons'];

export const getLatestActions = (metadata: UseMetadata['data']) => {
  const actionsMap = new Map<
    UseMetadata['data'][number]['name'],
    UseMetadata['data'][number]
  >();

  metadata.forEach((action) => {
    const { name, version } = action;

    if (gt(version, actionsMap.get(name)?.version ?? '0.0.0')) {
      actionsMap.set(name, action);
    }
  }, []);

  return [...actionsMap.values()];
};

const getLogoUrl = (
  name: string,
  logoType: string,
  metadata: UseMetadata['data'],
) => {
  const latestActions = getLatestActions(metadata);
  const action = latestActions.find((action) => action.name === name);

  if (!action) {
    return undefined;
  }

  return action?.ui?.icons?.elements?.find(
    (element) => element.name === logoType,
  )?.url;
};

const getLogoType = (theme: UserSettings['theme'], type?: string) => {
  if (type) return type;

  if (theme === 'light') return 'natural';
  if (theme === 'dark') return 'white';

  return 'white';
};

export type MetadataLogoProps = {
  className?: string;
  size?: 8 | 12 | 16 | 24;
  name: string;
  type?: 'white' | 'negative' | 'natural';
};

const MetadataLogo = ({
  className,
  name,
  type,
  size = 16,
}: MetadataLogoProps) => {
  const { settings } = useUserSettings();
  const { data: metadata } = useMetadata();
  const [error, setError] = useState(false);

  if (!name) {
    return null;
  }

  const logoType = getLogoType(settings.theme, type);
  const logoUrl = useMemo(
    () => getLogoUrl(name, logoType, metadata),
    [name, logoType, metadata],
  );

  if (!logoUrl || error) {
    return <Icon name="fit" svgColor="main" />;
  }

  return (
    <img
      className={className}
      src={logoUrl}
      width={size}
      height={size}
      onError={() => setError(true)}
      alt=""
    />
  );
};

export default MetadataLogo;
