import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import resources from './locales/loadResources';

const i18nConfig = (async function initializeI18n() {
  const getI18n = await i18n
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources,
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      returnNull: false,
    });
  return getI18n;
})();

export default i18nConfig;
