import { Flex, Icon, Typography } from '@nstrlabs/ixel';
import { useTranslation } from 'react-i18next';
import Auth from '../../components/molecules/Auth';
import { useUserSettingsSafe } from '../../hooks/settings/useUserSettings';
import styles from './index.module.css';

const Maintenance = () => {
  const { t } = useTranslation();
  const { settings } = useUserSettingsSafe();

  document.documentElement.setAttribute('data-theme', settings.theme);

  return (
    <Auth>
      <Flex className={styles.content} flexDirection="column" gap="xl">
        <Icon
          className={styles.icon}
          name="maintenance"
          width={32}
          height={32}
        />
        <Flex flexDirection="column">
          <Typography className={styles.title} variant="header" bold>
            {t('MAINTENANCE.TITLE')}
          </Typography>
          <Typography className={styles.description}>
            {t('MAINTENANCE.DESCRIPTION')}
          </Typography>
        </Flex>
      </Flex>
    </Auth>
  );
};

export default Maintenance;
