import { Button, Flex, Icon } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { range } from 'lodash';
import styles from './index.module.css';

type PaginationProps = {
  page: number;
  total: number;
  singlePageDisabled?: boolean;
  onPageChange: (nextPage: number) => void;
};

const Pagination = ({
  page,
  total,
  singlePageDisabled = true,
  onPageChange,
}: PaginationProps) => {
  const pivot = total - 2 <= page ? total - 2 : page < 3 ? 3 : page;

  const pagesRange = range(pivot - 2, pivot + 3).filter(
    (x) => x >= 1 && total >= x,
  );
  const hasFirst = pagesRange.includes(1);
  const hasLast = pagesRange.includes(total);
  const showFirstDots = !(pagesRange.includes(2) || pagesRange.includes(1));
  const showLastDots = !(
    pagesRange.includes(total - 1) || pagesRange.includes(total)
  );

  return (
    <Flex
      className={clsx(styles.pagination, {
        [styles.paginationHidden]: singlePageDisabled && total === 1,
      })}
      gap="xxs"
      as="ul"
      flexDirection="row"
      justifyContent="center"
    >
      <li>
        <Button
          size="small"
          mode="primary"
          className={styles.arrow}
          disabled={page === 1}
          icon={<Icon name="chevronLeft" />}
          onClick={() => onPageChange(page - 1)}
        />
      </li>
      {hasFirst ? null : (
        <li>
          <Button
            className={styles.page}
            size="small"
            mode="primary"
            onClick={() => onPageChange(1)}
          >
            1
          </Button>
        </li>
      )}
      {showFirstDots ? (
        <li>
          <Button
            className={clsx(styles.page, styles.dots)}
            size="small"
            mode="primary"
          >
            ...
          </Button>
        </li>
      ) : null}
      {pagesRange.map((p) => (
        <li key={p}>
          <Button
            className={clsx(p === page ? [styles.selectedPage] : [styles.page])}
            size="small"
            mode={p === page ? 'main' : 'primary'}
            onClick={() => onPageChange(p)}
          >
            {p}
          </Button>
        </li>
      ))}
      {showLastDots ? (
        <li>
          <Button
            className={clsx(styles.page, styles.dots)}
            size="small"
            mode="primary"
          >
            ...
          </Button>
        </li>
      ) : null}
      {hasLast ? null : (
        <li>
          <Button
            className={styles.page}
            size="small"
            mode="primary"
            onClick={() => onPageChange(total)}
          >
            {total}
          </Button>
        </li>
      )}
      <li>
        <Button
          size="small"
          mode="primary"
          className={styles.arrow}
          disabled={page === total}
          icon={<Icon name="chevronRight" />}
          onClick={() => onPageChange(page + 1)}
        />
      </li>
    </Flex>
  );
};

export default Pagination;
