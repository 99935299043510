import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { TenantLogoNotFoundError } from '../../context/tenants/domain/Tenant';
import { fetchConfiguration } from '../../utils/fetchConfiguration';
import { useContainer } from '../useContainer';

const useGetTenantLogo = (tenantName: string) => {
  const container = useContainer();
  const queryClient = useQueryClient();
  const { data, isLoading } = useQuery({
    queryKey: ['tenantLogo'],
    queryFn: () => container.call('tenants:getTenantLogo', { tenantName }),
    enabled: !!tenantName,
    // TODO Remove refetchInterval&retry when backend sends a 200 status on empty
    refetchInterval: ({ state: { fetchFailureReason } }) =>
      (fetchFailureReason as unknown as TenantLogoNotFoundError)?.context
        ?.cause === 404
        ? false
        : fetchConfiguration.REFETCH_INTERVAL,
    retry: (_failureCount, failureError) =>
      (failureError as unknown as TenantLogoNotFoundError).context?.cause !==
      404,
  });

  return {
    tenantLogo: data,
    isLoadingTenantlogo: isLoading,
    cancelTenants: () =>
      queryClient.cancelQueries({ queryKey: ['tenantLogo'] }),
  };
};

export default useGetTenantLogo;
