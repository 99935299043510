import type { Pipeline } from '../../context/pipelines/domain/Pipeline';

export type PipelineInfo = {
  name: string;
  info: {
    id: string;
    version: string;
    versionId: number;
    status: 'RUNNING' | 'DRAFT' | 'STOPPED';
  }[];
};

export const versionsStatusOrder = ['RUNNING', 'DRAFT', 'PUBLISHED', 'STOPPED'];

export const getPipelinesInfo = (pipelines: Pipeline[]): PipelineInfo[] =>
  pipelines.flatMap((pipeline) => ({
    name: pipeline.name,
    info: pipeline.versions
      .map((runningVersion) => ({
        id: pipeline.id,
        version: runningVersion.name,
        versionId: runningVersion.id,
        status: runningVersion.status,
      }))
      .sort((a, b) => {
        return (
          versionsStatusOrder.indexOf(a.status) -
          versionsStatusOrder.indexOf(b.status)
        );
      }),
  }));
