import { Either, domainError } from '@nstrlabs/utils';

type StringOptions = {
  canBeEmpty?: boolean;
  min?: number;
  max?: number;
  regEx?: RegExp;
};

export const invalidLength = domainError<{
  value: string;
  min?: number;
  max?: number;
}>()('string:length', '', 'Invalid string length');

export const invalidPattern = domainError<{ value: string }>()(
  'string:pattern',
  '',
  'Invalid string pattern',
);

export type InvalidLength = ReturnType<typeof invalidLength>;
export type InvalidPattern = ReturnType<typeof invalidPattern>;

export const stringValue = <T extends string = string>(
  value: string,
  { canBeEmpty, regEx, min, max }: StringOptions = {},
): Either<InvalidLength | InvalidPattern, T> => {
  if (canBeEmpty && value.length === 0) {
    return Either.of(value as T);
  }

  if (
    (min !== undefined && min > value.length) ||
    (max !== undefined && value.length > max)
  ) {
    return Either.left(
      invalidLength({
        value,
        min,
        max,
      }),
    );
  }

  if (regEx !== undefined && !regEx.test(value)) {
    return Either.left(invalidPattern({ value }));
  }

  return Either.of(value as T);
};
