import { Accordion, Button, Icon, Typography } from '@nstrlabs/ixel';
import { stringToSlug } from '@nstrlabs/utils';
import clsx from 'clsx';
import hotkeys, { type HotkeysEvent } from 'hotkeys-js';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useFeatureFlags } from '../../../hooks/featureFlags/useFeatureFlags';
import { useUserSettings } from '../../../hooks/settings/useUserSettings';
import MenuOption from '../../molecules/MenuOption';
import { type MenuOptions, useMenuOptions } from './UseMenuOptions';
import styles from './index.module.css';

const startsOpened = () => {
  const vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0,
  );
  return vw < 1280;
};

type MenuProps = {
  menuOptions?: MenuOptions;
};

const Menu = ({ menuOptions }: MenuProps) => {
  const navigate = useNavigate();
  const features = useFeatureFlags();
  const location = useLocation();
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(() => startsOpened());
  const { settings } = useUserSettings();
  const options = useMenuOptions();
  const { isActive: isFeatureActive } = useFeatureFlags();
  const isShortcutsEnabled = settings.keyboardShortcuts === 'on';

  useEffect(() => {
    const navTo = (path: string) => {
      navigate(path);
      return false;
    };

    const handleNav = (_: KeyboardEvent, hotkey: HotkeysEvent) => {
      switch (hotkey.key) {
        case 'command+1':
          return navTo('/home');
        case 'command+2':
          return navTo('/listeners');
        case 'command+3':
          return navTo('/pipelines');
        case 'command+4':
          return navTo('/datasinks');
        case 'command+5':
          if (!features.isActive('alerts')) return true;
          return navTo('/alerts');
        default:
          // TODO: Remove console

          console.info('Unknown command');
          return true;
      }
    };

    if (isShortcutsEnabled) {
      hotkeys('command+1,command+2,command+3,command+4,command+5', handleNav);
    }

    return () => {
      if (isShortcutsEnabled) {
        hotkeys.unbind(
          'command+1,command+2,command+3,command+4,command+5',
          handleNav,
        );
      }
    };
  }, [features, navigate, isShortcutsEnabled]);

  const handleCollapse = () => {
    // TODO: this is a workaround to solve the visualization issue in the table component.
    // Is still there a poor transition between collapse and expanded sidebar.
    setTimeout(() => window.dispatchEvent(new Event('resize')), 350);
    setCollapse((state) => !state);
  };

  const menuClassName = clsx(styles.menu, {
    [styles.collapse]: collapse,
  });
  const collapseMenuClassName = clsx(styles.collapseMenu, styles.collapseText);

  const isActive = (path: string) => location.pathname.includes(path);
  const hasMenuMoreSections =
    isFeatureActive('enrichment') ||
    isFeatureActive('debugging') ||
    isFeatureActive('kronograph') ||
    isFeatureActive('update-actions');

  // Removed the accordion waiting for more menu categories
  const Container = !hasMenuMoreSections || collapse ? 'div' : Accordion;
  const containerProps = collapse ? {} : { hideCount: true };

  const transformGroupOptions = (groupOptions: MenuOptions) =>
    groupOptions.map(({ translateKey, activeOn, icon, to, featureFlag }) =>
      featureFlag === undefined || features.isActive(featureFlag) ? (
        <li key={translateKey}>
          <MenuOption
            active={activeOn ? isActive(activeOn) : false}
            icon={icon}
            title={t(translateKey)}
            to={to ?? ''}
            id={stringToSlug(activeOn ?? '')}
            key={translateKey}
            collapsed={collapse}
          />
        </li>
      ) : null,
    );

  return (
    <div className={menuClassName} data-cy="app-menu-root">
      <nav
        className={styles.navBar}
        data-cy={collapse ? 'app-menu-nav-collapse' : 'app-menu-nav'}
      >
        {(menuOptions ?? options).map(
          ({ options: groupOptions = [], translateKey, featureFlag }) =>
            (featureFlag === undefined || features.isActive(featureFlag)) &&
            groupOptions.length > 0 ? (
              <Container
                key={t(translateKey)}
                className={styles.menuOptions}
                {...(collapse ? {} : { title: t(translateKey) })}
                {...containerProps}
              >
                <ul key={translateKey}>
                  {transformGroupOptions(groupOptions)}
                </ul>
              </Container>
            ) : null,
        )}
      </nav>
      <div className={collapseMenuClassName}>
        <Button
          className={styles.collapseButton}
          onClick={handleCollapse}
          variant="link"
          mode="primary"
          icon={
            <Icon
              name={collapse ? 'collapseSidebar' : 'expandSidebar'}
              svgColor="secondary"
            />
          }
          data-cy="app-menu-collapse"
        >
          {!collapse && <Typography as="span">{t('MENU.COLLAPSE')}</Typography>}
        </Button>
      </div>
    </div>
  );
};

export default Menu;
