import type React from 'react';
import {
  type FeatureFlagKey,
  useFeatureFlags,
} from '../../../hooks/featureFlags/useFeatureFlags';

type FeatureFlagsProps = {
  flag: FeatureFlagKey;
  fallback?: React.ReactNode;
  children: React.ReactNode;
};

const FeatureFlags = ({ fallback, flag, children }: FeatureFlagsProps) => {
  const { isActive } = useFeatureFlags();

  if (!isActive(flag)) {
    return fallback;
  }

  return children;
};

export default FeatureFlags;
