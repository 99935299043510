import { Button, Flex, Icon, TooltipAction } from '@nstrlabs/ixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeleteSecret } from '../../../hooks/organizationSettings/secrets/useDeleteSecret';
import DropdownMenu from '../../atoms/DropdownMenu';
import RemoveConfirmationDialog from './ConfirmSecretAction';
import styles from './index.module.css';

const ActionMenu = ({
  secretName,
  secretId,
}: {
  secretName: string;
  secretId: string;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'SETTINGS.ORGANIZATION_SETTINGS.SECRET_DETAIL.ACTION_MENU',
  });
  const deleteSecretMutation = useDeleteSecret();
  const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
  return (
    <>
      <DropdownMenu
        placement="bottom-end"
        onClick={(e) => e.stopPropagation()}
        trigger={
          <Button
            as="span"
            variant="link"
            mode="primary"
            title={t('ACTIONS_TITLE')}
            icon={<Icon name="more" />}
            id="action-button"
            data-cy={`secret-action-button-${secretName}`}
          />
        }
      >
        {({ closeDropdown }) => (
          <Flex
            justifyContent="center"
            flexDirection="column"
            className={styles.actionsList}
            onClick={(e) => e.stopPropagation()}
            gap="xxs"
          >
            <TooltipAction
              className={styles.option}
              onClick={() => {
                closeDropdown();
                setOpenRemoveDialog(true);
              }}
              icon={<Icon name="remove" />}
              name={t('REMOVE_SECRET')}
              destructive
              data-cy="remove-secret-option-button"
            />
          </Flex>
        )}
      </DropdownMenu>
      {openRemoveDialog ? (
        <RemoveConfirmationDialog
          open={openRemoveDialog}
          secretName={secretName}
          secretId={secretId}
          onClose={() => {
            setOpenRemoveDialog(false);
          }}
          onRemove={() => {
            deleteSecretMutation.mutate({ id: secretId });
          }}
        />
      ) : null}
    </>
  );
};

export default ActionMenu;
