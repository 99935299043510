import { Flex, Metadata, TextArea, TextField } from '@nstrlabs/ixel';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type SecretCreateMetadataProps = {
  secretTags: string[];
  secretNameError: string | undefined;
  secretDescriptionError: string | undefined;
  onChangeTags: (tags: string[]) => void;
  requiredFieldsNotEmpty: (field: string, fieldValue: string) => void;
  onChangeFieldsErrors: (field: string, value: string | undefined) => void;
};
const SecretCreateMetadata = ({
  secretTags,
  onChangeFieldsErrors,
  onChangeTags,
  secretNameError,
  secretDescriptionError,
  requiredFieldsNotEmpty,
}: SecretCreateMetadataProps) => {
  const { t } = useTranslation('translation');
  const [secretName, setSecretName] = useState<string>('');
  const [secretDescription, setSecretDescription] = useState<string>('');

  return (
    <Flex flexDirection="column" gap="md">
      <TextField
        label={t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.NAME')}
        name="name"
        id="name"
        maxLength={50}
        value={typeof secretName === 'string' ? secretName : undefined}
        error={
          typeof secretNameError === 'string' ? secretNameError : undefined
        }
        required
        onChange={(e) => {
          requiredFieldsNotEmpty('name', e.target.value);
          if (e.target.value === '') {
            onChangeFieldsErrors('name', t('WEB-00032'));
            setSecretName(e.target.value);
            return;
          }
          if (secretName !== e.target.value) {
            onChangeFieldsErrors('name', undefined);
            setSecretName(e.target.value);
          }
        }}
      />
      <TextArea
        label={t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.DESCRIPTION')}
        name="description"
        id="description"
        onChange={(e) => {
          if (secretDescription !== e.target.value) {
            onChangeFieldsErrors('description', undefined);
            setSecretDescription(e.target.value);
          }
        }}
        value={
          typeof secretDescription === 'string' ? secretDescription : undefined
        }
        error={
          typeof secretDescriptionError === 'string'
            ? secretDescriptionError
            : undefined
        }
      />
      <Metadata
        metadataName="tags"
        label={t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.TAGS')}
        onChange={onChangeTags}
        tags={secretTags}
      />
    </Flex>
  );
};

export default SecretCreateMetadata;
