import {
  type Dispatch,
  type ReactNode,
  type SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';

export type ConsoleSettingsContextType = {
  consoleSettingsIsOpen: boolean;
  setConsoleSettingsIsOpen: Dispatch<SetStateAction<boolean>>;
};

const ConsoleSettingsContext = createContext<ConsoleSettingsContextType | null>(
  null,
);

export const ConsoleSettings = (): ConsoleSettingsContextType => {
  const url = new URL(window.location.href);
  const queryParams = new URLSearchParams(url.search);

  const sectionSelected = queryParams.get('sectionSelected');
  const menuOption = queryParams.get('menuOption');

  const [consoleSettingsIsOpen, setConsoleSettingsIsOpen] = useState<boolean>(
    !!(sectionSelected && menuOption),
  );

  return {
    consoleSettingsIsOpen,
    setConsoleSettingsIsOpen,
  };
};

export const ConsoleSettingsProvider = (props: { children?: ReactNode }) => {
  const { consoleSettingsIsOpen, setConsoleSettingsIsOpen } = ConsoleSettings();

  const value = useMemo(
    () => ({ consoleSettingsIsOpen, setConsoleSettingsIsOpen }),
    [consoleSettingsIsOpen, setConsoleSettingsIsOpen],
  );

  return <ConsoleSettingsContext.Provider {...props} value={value} />;
};

export class UseConsoleSettingsError extends Error {
  constructor() {
    super('useConsoleSettings should be used within a provider');
  }
}

export const useConsoleSettings = (): ConsoleSettingsContextType => {
  const container = useContext(ConsoleSettingsContext);

  if (container === null) {
    throw new UseConsoleSettingsError();
  }

  return container;
};
