import { Flex, type IconProps, SquareIcon, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { Fragment } from 'react';
import Separator from '../../atoms/Separator';
import styles from './index.module.css';

type StepSectionProps = {
  children: React.ReactNode;
  className?: string;
  description: string;
  icon: IconProps['name'];
  leftClassName?: string;
  rightClassName?: string;
  title: string;
  squareClassName?: string;
};
type StepLayoutProps = { content: StepSectionProps[] };

const StepSection = ({
  icon,
  title,
  description,
  className,
  leftClassName,
  rightClassName,
  children,
  squareClassName,
}: StepSectionProps) => (
  <Flex className={clsx(styles.section, className)}>
    <Flex flexDirection="column" className={clsx(styles.left, leftClassName)}>
      <SquareIcon className={squareClassName} icon={icon} />
      <Flex flexDirection="column" className={styles.text}>
        <Typography bold size="large" as="h1">
          {title}
        </Typography>
        <Typography as="p" className={styles.description}>
          {description}
        </Typography>
      </Flex>
    </Flex>
    <div className={clsx(styles.right, rightClassName)}>{children}</div>
  </Flex>
);

const StepLayout = ({ content }: StepLayoutProps) => (
  <>
    {content.map((props, index) => (
      <Fragment key={props.title}>
        <StepSection {...props} />
        {index !== content.length - 1 ? (
          <Separator className={styles.separator} />
        ) : null}
      </Fragment>
    ))}
  </>
);

export default StepLayout;
