import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ContainerUseCase } from '../../context';
import { useContainer } from '../useContainer';

export type MetadataUseCase = ContainerUseCase<'actions:getMetadata', 'result'>;
export type UseMetadata = {
  data: MetadataUseCase;
  isLoading: boolean;
  cancel: () => Promise<void>;
};

const preloaded = new Set<string>();

export function useMetadata(): UseMetadata {
  const container = useContainer();
  const queryClient = useQueryClient();
  const { data = [], isLoading } = useQuery({
    queryKey: ['actions', 'metadata'],
    queryFn: async () => {
      const metadata = await container.call('actions:getMetadata');

      new Set(
        metadata.flatMap(({ type, ui }) => {
          if (type === 'action') return [];
          return ui.icons?.elements.reduce((acc: string[], { url }) => {
            if (!preloaded.has(url)) acc.push(url);
            return acc;
          }, []);
        }),
      ).forEach((url) => {
        if (!url) return;
        const link = document.createElement('link');
        link.rel = 'prefetch';
        link.as = 'image';
        link.type = 'image/svg+xml';
        link.href = url;

        preloaded.add(url);

        document.head.appendChild(link);
      });

      return metadata;
    },
    ...{
      staleTime: 1000 * 60,
    },
  });

  return {
    data,
    isLoading,
    cancel: () =>
      queryClient.cancelQueries({ queryKey: ['actions', 'metadata'] }),
  };
}
