import { type ReactNode, useEffect } from 'react';
import { useUserSettings } from '../../../hooks/settings/useUserSettings';

const UserSettingsObserver = ({ children }: { children?: ReactNode }) => {
  const { settings } = useUserSettings();

  useEffect(() => {
    const theme = settings.theme ?? 'dark';
    document.documentElement.setAttribute('data-theme', theme);
  }, [settings.theme]);

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-density',
      settings.tableDensity,
    );
  }, [settings.tableDensity]);

  return children as JSX.Element;
};

export default UserSettingsObserver;
