import { Shortcut as Shortcode } from '@nstrlabs/ixel';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useUserSettings } from '../../../hooks/settings/useUserSettings';
import { useMountTransition } from '../../../hooks/useMountTransition';

import styles from './index.module.css';

const Shortcut = ({ children }: { children: ReactNode }) => {
  const { settings } = useUserSettings();
  const isShortcutsEnabled = settings.keyboardShortcuts === 'on';
  const isTransitioning = useMountTransition(isShortcutsEnabled, 300);
  const containerClass = clsx(styles.container, {
    [styles.in]: isTransitioning && isShortcutsEnabled,
    [styles.out]: isTransitioning && !isShortcutsEnabled,
  });

  if (!isTransitioning && !isShortcutsEnabled) {
    return null;
  }

  return (
    <div className={containerClass}>
      <Shortcode>{children}</Shortcode>
    </div>
  );
};

export default Shortcut;
