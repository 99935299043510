import {
  Dropdown,
  Flex,
  Icon,
  type IconName,
  NoData,
  OverlayLayout,
  Typography,
} from '@nstrlabs/ixel';
import { emptyFn, invoke, normalizeIncludes } from '@nstrlabs/utils';
import { type ReactElement, type ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import FilterItem from '../FilterItem';
import type { FilterSelectorType } from '../FiltersProvider';
import styles from './index.module.css';

export type FilterSelectorProps<
  T extends Record<K | string, string | IconName | undefined>,
  K extends string = 'id',
> = {
  label: string;
  options?: T[];
  onClick: (option: T) => void;
  trigger?: ReactElement;
  value?: string;
  iconName?: IconName;
  renderOption: (props: {
    option: T;
    onClick: () => void;
  }) => ReactNode;
  isOpen?: boolean;
  isFixed?: boolean;
  onDelete?: () => void;
  keyLabel?: string;
  keyId?: K;
  filter?: FilterSelectorType<'selector', string>;
};

const FilterSelector = <
  T extends Record<K | string, string | IconName | undefined>,
  K extends string = 'id',
>({
  label,
  options = [] as unknown as T[],
  trigger,
  onDelete,
  onClick,
  renderOption: RenderOption,
  isOpen = true,
  isFixed,
  iconName,
  keyLabel = 'label',
  filter,
}: FilterSelectorProps<T, K>) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const [valueOption, setValueOption] = useState<string>(filter?.value ?? '');
  const [isClosing, setIsClosing] = useState<boolean>(false);

  // TODO this is not beauty but is the only way to control you did not fill with value and remove filter
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isClosing && !valueOption) {
      invoke(onDelete);
    }
  }, [valueOption, isClosing]);

  const filtredOptions = options.filter((option) =>
    normalizeIncludes(option[keyLabel] ?? '', search),
  );

  const handleClose = () => {
    setIsClosing(() => true);
  };
  const handleClickOption = (option: T, close: () => void) => {
    setValueOption(() => option.label as string);
    onClick(option);
    close();
  };

  return (
    <Dropdown.Menu
      defaultOpen={isOpen}
      onClose={handleClose}
      className={styles.dropdown}
      trigger={
        trigger ?? (
          <FilterItem
            onDelete={onDelete ?? emptyFn}
            isFixed={isFixed}
            name={
              <Flex gap="xxs">
                {iconName ? <Icon name={iconName} /> : null}
                <Typography>{t(label)}</Typography>
                <Typography>{t('FILTERS.CATEGORY.IS')}</Typography>
                <Typography bold>{filter?.value}</Typography>
              </Flex>
            }
          />
        )
      }
    >
      {({ closeDropdown }) => (
        <div className={styles.dropdownContainer}>
          <OverlayLayout>
            {options.length >= 10 ? (
              <OverlayLayout.Search
                placeholder="Search field"
                className={styles.search}
                onSearch={(e) => setSearch(e.target.value)}
              />
            ) : null}
            <OverlayLayout.Body border={false}>
              {filtredOptions.length ? (
                <Flex flexDirection="column" gap="md">
                  <Flex
                    gap="xs"
                    flexDirection="column"
                    alignItems="center"
                    data-cy="add-filter-item-list"
                  >
                    {filtredOptions.map((option) => (
                      <RenderOption
                        option={option}
                        onClick={() => handleClickOption(option, closeDropdown)}
                      />
                    ))}
                  </Flex>
                </Flex>
              ) : (
                <Flex
                  flexDirection="column"
                  gap="md"
                  alignContent="center"
                  alignItems="center"
                  className={styles.noData}
                >
                  <NoData
                    title={t('FILTERS.FIELDS.NO_FIELDS')}
                    iconName="filter"
                  />
                </Flex>
              )}
            </OverlayLayout.Body>
          </OverlayLayout>
        </div>
      )}
    </Dropdown.Menu>
  );
};

export default FilterSelector;
