import { Button, FixedFooter, Flex, Icon, Metadata } from '@nstrlabs/ixel';
import { invoke, throttle } from '@nstrlabs/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../atoms/DropdownMenu';
import styles from './index.module.css';

export type TagsIndicatorProps = {
  onCancel?: () => void;
  onSave?: (tags: string[] | undefined) => void;
  tags: string[];
  buttonSize?: 'small' | 'regular';
  showLabel?: boolean;
  canEdit?: boolean;
  disabled?: boolean;
};

const TagsIndicator = ({
  onCancel,
  onSave,
  tags,
  buttonSize,
  showLabel = true,
  canEdit,
  disabled,
}: TagsIndicatorProps) => {
  const { t } = useTranslation();
  const [tagsIndicator, setTagsIndicator] = useState<string[]>(tags);
  const hasTags = Array.isArray(tags) && tags.length !== 0;
  const isLabelAddTags = hasTags ? tags.length : t('PIPELINE.HEADER.ADD_TAG');

  const onClickCancel = throttle(() => {
    invoke(onCancel);
  }, 400);
  const onClickSave = throttle(() => {
    invoke(onSave, tagsIndicator);
  }, 400);

  return (
    <DropdownMenu
      onClick={(e) => e.stopPropagation()}
      trigger={
        <Button
          as="span"
          size={buttonSize}
          variant="ghost"
          icon={<Icon name="tag" svgColor="secondary" />}
          className={styles.buttonTagsIndicator}
        >
          {showLabel ? isLabelAddTags : null}
        </Button>
      }
    >
      {({ closeDropdown }) => (
        <Flex
          className={styles.container}
          flexDirection="column"
          onClick={(e) => e.stopPropagation()}
        >
          <Flex flexDirection="column" className={styles.metadata}>
            <Metadata
              label={t('TAGS.TITLE')}
              onChange={(tagsMetadata) => {
                setTagsIndicator(tagsMetadata);
              }}
              tags={tagsIndicator}
              disabled={disabled}
              canEdit={canEdit}
              variant={canEdit ? 'metadata' : 'generic'}
            />
          </Flex>
          {canEdit ? (
            <FixedFooter
              className={styles.footer}
              abortAction={
                <Button
                  mode="primary"
                  variant="outline"
                  onClick={() => {
                    setTagsIndicator(tags);
                    closeDropdown();
                    onClickCancel();
                  }}
                >
                  {t('TAGS.CANCEL')}
                </Button>
              }
              continueAction={
                <Button
                  mode="primary"
                  variant="solid"
                  onClick={() => {
                    closeDropdown();
                    onClickSave();
                  }}
                >
                  {t('TAGS.SAVE')}
                </Button>
              }
              variant="default"
            />
          ) : null}
        </Flex>
      )}
    </DropdownMenu>
  );
};

export default TagsIndicator;
