import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useContainer } from '../useContainer';

const useTenants = () => {
  const container = useContainer();
  const queryClient = useQueryClient();
  const { data = [], isLoading } = useQuery({
    queryKey: ['tenants'],
    queryFn: () => container.call('tenants:getAll'),
  });

  return {
    tenantsData: data,
    isLoading,
    cancelTenants: () => queryClient.cancelQueries({ queryKey: ['tenants'] }),
  };
};

export default useTenants;
