const loadIfNotExists = (exists, toLoad) =>
  exists ? Promise.resolve() : toLoad();

export default async function bootstrap() {
  await Promise.all([
    loadIfNotExists(
      'at' in Array.prototype,
      () => import('core-js/actual/array/at'),
    ),
    loadIfNotExists(
      'at' in String.prototype,
      () => import('core-js/actual/string/at'),
    ),
    loadIfNotExists(
      'structuredClone' in window,
      () => import('core-js/actual/structured-clone'),
    ),
  ]);
}
