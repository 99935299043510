import { useQuery } from '@tanstack/react-query';
import { useContainer } from '../useContainer';
import { useUser } from '../useUser';

export function useCurrentUser() {
  const container = useContainer();
  const { UID } = useUser();

  const { data: user, isLoading } = useQuery({
    queryKey: ['users', `current_user:(${UID})`],
    queryFn: () => container.call('users:findById', { id: UID }),
    staleTime: 1000 * 60,
  });

  return {
    user,
    isLoading,
  };
}
