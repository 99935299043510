import { Button, Flex, Icon, StackView } from '@nstrlabs/ixel';
import { useCallback, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { match } from 'ts-pattern';
import ModalHeader from '../../components/layouts/ModalHeader';
import ModalSidebar from '../../components/layouts/ModalSidebar';

import MenuSettings from '../../components/organisms/MenuSettings';
import WithPermissions from '../../hooks/controlAccess/WithPermissions';
import { useConsoleSettings } from '../../hooks/settings/useConsoleSettings';
import { useLocationFrom } from '../../hooks/useLocationFrom';
import SecretsManagement from '../OrganizationSettings/SecretsManagement';
import MailAndPassword from '../Preferences/MailAndPassword';
import Activity from '../Tenant/Activity';
import Users from '../Tenant/ControlAccess/Users';
import styles from './index.module.css';
import { useSettingsOptions } from './useSettingsOptions';

const MemoizedMailAndPasswordComponent = () => <MailAndPassword />;
const MemoizedUsersComponent = () => <Users />;
const MemoizedSecretsManagementComponent = () => <SecretsManagement />;
const MemoizedActivityComponent = () => {
  return (
    <WithPermissions resource="activity">
      <Activity />
    </WithPermissions>
  );
};

const Settings = () => {
  const { setConsoleSettingsIsOpen } = useConsoleSettings();
  const [searchParams, setSearchParams] = useSearchParams();
  const sectionSelected = searchParams.get('sectionSelected');
  const menuOption = searchParams.get('menuOption');

  const location = useLocation();
  const navigate = useNavigate();
  const options = useSettingsOptions();

  const { goBack } = useLocationFrom({
    fallbackPath: location?.state?.from || '/home',
  });

  const [activeMenuOption, setActiveMenuOption] = useState(() => ({
    section: sectionSelected,
    selectedMenuOption: menuOption || 'email_password',
  }));

  const [showFocusPanel, setShowFocusPanel] = useState<'open' | 'closed'>(
    'open',
  );

  const closeFocusPanel = () => {
    setShowFocusPanel('closed');
    setConsoleSettingsIsOpen(false);
    searchParams.delete('sectionSelected');
    searchParams.delete('menuOption');
    setSearchParams(searchParams);
  };
  const handleClosedSidebar = () => {
    setConsoleSettingsIsOpen(false);
    goBack();
  };
  const handleChangeMenuSettings = (
    section: string,
    selectedMenuOption: string,
  ) => {
    navigate(`?sectionSelected=${section}&menuOption=${selectedMenuOption}`, {
      state: { from: location?.state?.from },
    });
    setActiveMenuOption(() => ({
      section,
      selectedMenuOption,
    }));
  };
  const MemoizedValueIdComponent = useCallback(
    () => <div>{activeMenuOption.selectedMenuOption}</div>,
    [activeMenuOption.selectedMenuOption],
  );

  return (
    <StackView
      status={showFocusPanel}
      className={styles.modal}
      onClose={closeFocusPanel}
      onClosed={handleClosedSidebar}
    >
      <ModalHeader
        className={styles.content}
        contentClassName={styles.contentSettings}
        content={
          <ModalSidebar
            bar={
              <MenuSettings
                menuSettings={options}
                activeMenuOption={activeMenuOption}
                onChange={handleChangeMenuSettings}
              />
            }
            content={
              <Flex className={styles.contentModal}>
                <Flex
                  alignItems="center"
                  justifyContent="flexEnd"
                  className={styles.closeButton}
                >
                  <Button
                    variant="link"
                    mode="primary"
                    icon={<Icon name="close" />}
                    onClick={closeFocusPanel}
                  />
                </Flex>
                {match(activeMenuOption.selectedMenuOption)
                  .with('email_password', MemoizedMailAndPasswordComponent)
                  .with('control_access_users', MemoizedUsersComponent)
                  .with(
                    'secrets_management',
                    MemoizedSecretsManagementComponent,
                  )
                  .with('activity_log', MemoizedActivityComponent)
                  .otherwise(MemoizedValueIdComponent)}
              </Flex>
            }
          />
        }
      />
    </StackView>
  );
};

export default Settings;
