import { BaseError } from '@nstrlabs/utils';
import {
  type LogLevels,
  Logger,
  type LoggerClientMethods,
  type LoggerClientParams,
} from '../domain/Logger';

type Levels = keyof Logger;

type GetUrl = {
  url: string;
  domain: string;
  token: string;
  host: string;
  tag: string;
};

function getUrl({ url, domain, token, host, tag }: GetUrl) {
  return `${url}/event/${domain}/token!${token}/${host}/${tag}`;
}

const buildBody = (severity: Levels, params: LoggerClientParams): string =>
  [
    window.location.hostname,
    severity,
    params.project,
    params.tenant,
    params.tenantName,
    params.userId,
    params.userEmail,
    params.sessionId,
    params.url,
    params.browser,
    params.appVersion,
    params.logContext.module,
    params.logContext.function,
    params.logContext.errorCode ?? '',
    params.message,
    JSON.stringify(params.logContext.context ?? {}),
  ]
    .join('|')
    .replace(/(\r\n|\n|\r)/gm, '');

const url = getUrl({
  url: process.env.VITE_LOGGER_DEVO_URL || 'https://http-eu.devo.io',
  domain: process.env.VITE_LOGGER_DEVO_DOMAIN || 'domain',
  token: process.env.VITE_LOGGER_DEVO_TOKEN || 'token',
  host: process.env.VITE_LOGGER_DEVO_HOST || 'local0',
  tag: process.env.VITE_LOGGER_DEVO_TAG || 'my.app.http',
});

class DevoLoggerError extends BaseError {
  constructor(genericError: Error) {
    super({
      message: `Error sending log to Devo => Error name: ${genericError.name} | Error message: ${genericError.message}`,
      code: 'WEB-10111',
    });
  }
}

const handleLoggerError = (e: Error) => new DevoLoggerError(e);
const method = 'POST';
const sendLog = (body: RequestInit['body']) => {
  fetch(url, { body, method }).catch(handleLoggerError);
};

export class DevoLogger extends Logger {
  constructor(logLevel?: LogLevels) {
    const client: LoggerClientMethods = {
      debug: (params) => {
        sendLog(buildBody('debug', params));
      },
      log: (params) => {
        sendLog(buildBody('log', params));
      },
      info: (params) => {
        sendLog(buildBody('info', params));
      },
      warn: (params) => {
        sendLog(buildBody('warn', params));
      },
      error: (params) => {
        sendLog(buildBody('error', params));
      },
    };
    super(client, logLevel);
  }
}
