import {
  Flex,
  type FlexProps,
  Icon,
  type IconProps,
  Typography,
} from '@nstrlabs/ixel';

import clsx from 'clsx';
import { t } from 'i18next';
import styles from './index.module.css';

export type NoDataProps = {
  iconName?: IconProps['name'];
  size?: 'small' | 'normal' | 'large';
  flexDirection?: FlexProps['flexDirection'];
  gap?: FlexProps['gap'];
  title?: string;
  subtitle?: string;
  className?: string;
};
const NoData = ({
  iconName = 'data',
  size = 'normal',
  flexDirection = 'column',
  gap = 'sm',
  title = t('NO_DATA'),
  subtitle,
  className,
}: NoDataProps) => {
  const iconWrapperClassName = clsx(styles.iconWrapper, 'NoData-Wrapper', {
    [styles.iconWrapperNormal]: size === 'normal',
  });
  const iconContainerClassName = clsx(styles.iconContainer, {
    [styles.iconContainerSmall]: size === 'small',
  });
  return (
    <Flex
      flexDirection={flexDirection}
      alignItems="center"
      justifyContent="center"
      className={clsx(className, styles.noData, 'NoData-Root')}
      gap={gap}
    >
      <div className={iconWrapperClassName}>
        <div className={iconContainerClassName}>
          <Icon name={iconName} />
        </div>
      </div>
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap="xxs"
      >
        <Typography className="NoData-Title" bold>
          {title}
        </Typography>
        {subtitle != null ? (
          <Typography className="NoData-Subtitle" color="tertiary">
            {subtitle}
          </Typography>
        ) : null}
      </Flex>
    </Flex>
  );
};
export default NoData;
