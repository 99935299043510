import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useContainer } from '../useContainer';

export const useUpdateMainTenant = () => {
  const container = useContainer();
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (tenantId: string) =>
      container.call('users:updateMainTenant', { tenantId }),
    onSuccess(user) {
      queryClient.setQueryData(['users', `current_user:(${user.id})`], user);
    },
  });

  return { update: mutate, isPending };
};
