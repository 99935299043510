import {
  Button,
  FixedFooter,
  Flex,
  Icon,
  SelectField,
  TextField,
  Typography,
} from '@nstrlabs/ixel';
import { type ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../atoms/DropdownMenu';
import FilterItem from '../FilterItem';
import type { FilterWithOperation } from '../FiltersProvider';
import styles from './index.module.css';

type Operator = {
  value: string;
  label: string;
};

export type FilterGenericNumberProps<Op extends Operator> =
  React.HTMLAttributes<HTMLDivElement> & {
    filter: FilterWithOperation<'number' | 'float' | 'integer', number>;
    buttonName: string;
    onDelete: () => void;
    handleSaveFilters: (
      filterToSave: FilterWithOperation<'number' | 'float' | 'integer', number>,
    ) => void;
    options: Op[];
  };

const FilterGenericNumber = <Op extends Operator>({
  filter,
  buttonName,
  onDelete,
  handleSaveFilters,
  options,
}: FilterGenericNumberProps<Op>) => {
  const { label, operation, value, isOpen = true, isFixed, iconName } = filter;
  const [conditional, setConditional] = useState<Op['value']>(operation);
  const [numberValue, setNumberValue] = useState<number>(value);
  const { t } = useTranslation();

  const onApply = () => {
    if (conditional && numberValue) {
      handleSaveFilters({
        ...filter,
        operation: conditional,
        value: numberValue,
      });
    }
    if (!conditional && !numberValue) {
      onDelete();
    }
  };

  const handleKeypress = (
    event: React.KeyboardEvent<HTMLButtonElement>,
  ): void => {
    if (event.key === 'Enter') {
      onApply();
    }
  };

  const handleClose = () => {
    if (!numberValue || !conditional) {
      onDelete();
    }
  };

  return (
    <DropdownMenu
      defaultOpen={isOpen}
      trigger={
        <FilterItem
          onDelete={onDelete}
          isFixed={isFixed}
          name={
            <Flex gap="xxs">
              {iconName ? <Icon name={iconName} /> : null}
              <Typography>{t(label)}</Typography>
              <Typography>
                {
                  options.find(
                    (op) => op.value === operation?.toLocaleLowerCase(),
                  )?.label
                }
              </Typography>
              <Typography bold>{value}</Typography>
            </Flex>
          }
        />
      }
      onClose={handleClose}
    >
      {({ closeDropdown }) => (
        <Flex className={styles.dropdownContainer} flexDirection="column">
          <Flex flexDirection="column" className={styles.dropdownContent}>
            <SelectField
              id="condition"
              label="Condition"
              format="regular"
              options={options}
              renderOption={(option) => <p>{option.label}</p>}
              renderValue={(option) => <p>{option.label}</p>}
              value={conditional}
              onChange={(value) => setConditional(value)}
              parentContained
            />
            <TextField
              id="value"
              label="Value"
              type="number"
              value={numberValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setNumberValue(parseInt(event.target.value))
              }
            />
          </Flex>
          <FixedFooter
            abortAction={null}
            continueAction={
              <Button
                disabled={!numberValue || !conditional}
                variant="solid"
                mode="primary"
                onClick={() => {
                  onApply();
                  closeDropdown();
                }}
                onKeyDown={handleKeypress}
              >
                {buttonName}
              </Button>
            }
          />
        </Flex>
      )}
    </DropdownMenu>
  );
};

export default FilterGenericNumber;
