import {
  Button,
  FixedFooter,
  Flex,
  SelectField,
  TextField,
  Typography,
} from '@nstrlabs/ixel';
import {
  type ChangeEvent,
  type ComponentPropsWithoutRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import DropdownMenu from '../../../atoms/DropdownMenu';
import FilterItem from '../FilterItem';
import type { FilterWithOperation } from '../FiltersProvider';
import styles from './index.module.css';

export enum OperatorsEnum {
  CONTAINS = 'Contains',
  EQUALS = 'Equals',
  MATCHES = 'Matches',
}
type OperatorKeys = keyof typeof OperatorsEnum;

export type FilterStringProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  buttonName: string;
  onDelete: () => void;
  handleSaveFilters: (
    filterToSave: FilterWithOperation<'string', string>,
  ) => void;
  filter: FilterWithOperation<'string', string>;
};

const FilterString = ({
  className,
  buttonName,
  onDelete,
  handleSaveFilters,
  filter,
}: FilterStringProps) => {
  const { t } = useTranslation();
  const { label, operation, value, isOpen = true, isFixed } = filter;
  const [conditional, setConditional] = useState<OperatorKeys>(
    operation as OperatorKeys,
  );
  const [stringValue, setStringValue] = useState<string>(value);

  const onApply = () => {
    if (conditional && stringValue) {
      handleSaveFilters({
        ...filter,
        operation: conditional,
        value: stringValue,
      });
    }
    if (!conditional && !stringValue) {
      onDelete();
    }
  };

  const handleKeypress = (
    event: React.KeyboardEvent<HTMLButtonElement>,
  ): void => {
    if (event.key === 'Enter') {
      onApply();
    }
  };

  const handleClose = () => {
    if (!stringValue || !conditional) {
      onDelete();
    }
  };
  return (
    <DropdownMenu
      defaultOpen={isOpen}
      onClose={handleClose}
      trigger={
        <FilterItem
          className={className}
          onDelete={onDelete}
          isFixed={isFixed}
          name={
            <Flex gap="xxs">
              <Typography className={styles.text}>{t(label)}</Typography>
              <Typography>{operation?.toLocaleLowerCase() ?? ''}</Typography>
              <Typography bold>{t(value)}</Typography>
            </Flex>
          }
        />
      }
    >
      {({ closeDropdown }) => (
        <Flex
          className={styles.dropdownContainer}
          flexDirection="column"
          data-cy="filter-string-dropdown"
        >
          <Flex flexDirection="column" className={styles.dropdownContent}>
            <SelectField
              id="condition"
              label="Condition"
              format="regular"
              options={[
                {
                  value: OperatorsEnum.CONTAINS,
                  label: t('FILTERS.STRING_OPTIONS.CONTAINS'),
                },
                {
                  value: OperatorsEnum.EQUALS,
                  label: t('FILTERS.STRING_OPTIONS.EQUALS'),
                },
                {
                  value: OperatorsEnum.MATCHES,
                  label: t('FILTERS.STRING_OPTIONS.MATCHES'),
                },
              ]}
              value={conditional}
              parentContained
              onChange={(value: string) =>
                setConditional(value as unknown as OperatorKeys)
              }
              data-cy="filter-string-condition-selector"
            />
            <TextField
              id="value"
              label="Value"
              type="text"
              value={stringValue}
              onChange={(
                event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
              ) => setStringValue(event.target.value) as unknown as string}
            />
          </Flex>
          <FixedFooter
            abortAction={null}
            continueAction={
              <Button
                disabled={!stringValue || !conditional}
                variant="solid"
                mode="primary"
                data-cy="filter-string-button-save"
                onClick={() => {
                  onApply();
                  closeDropdown();
                }}
                onKeyDown={handleKeypress}
              >
                {buttonName}
              </Button>
            }
          />
        </Flex>
      )}
    </DropdownMenu>
  );
};

export default FilterString;
