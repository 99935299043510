import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { z } from 'zod';
import WithPermissions from '../../../hooks/controlAccess/WithPermissions';
import AreValidParams from '../AreValidParams';

const DatasinkCreate = lazy(
  () => import('../../../components/layouts/DatasinkCreate'),
);
const DatasinkCreateType = lazy(
  () => import('../../../pages/DatasinkCreateType'),
);
const DatasinkCreateConfiguration = lazy(
  () => import('../../../pages/DatasinkCreateConfiguration'),
);
const DatasinkDetail = lazy(() => import('../../../pages/DataSinkDetail'));
const DatasinkEdit = lazy(() => import('../../../pages/DatasinkEdit'));

const validateDatasinkDetailParams = (params: { datasinkId: string }) => {
  const datasinkDetailsParams = z.object({
    datasinkId: z.string().startsWith('DS_'),
  });

  return datasinkDetailsParams.safeParse(params).success;
};

export const dataSinksNestedRoutes = (
  <>
    <Route
      element={
        <WithPermissions action="create" resource="datasink">
          <Suspense>
            <DatasinkCreate />
          </Suspense>
        </WithPermissions>
      }
    >
      <Route
        path="create/type"
        element={
          <WithPermissions action="create" resource="datasink">
            <Suspense>
              <DatasinkCreateType />
            </Suspense>
          </WithPermissions>
        }
      />
      <Route
        path="create/configuration"
        element={
          <WithPermissions action="create" resource="datasink">
            <Suspense>
              <DatasinkCreateConfiguration />
            </Suspense>
          </WithPermissions>
        }
      />
    </Route>
    <Route
      path=":datasinkId"
      element={
        <AreValidParams validate={validateDatasinkDetailParams}>
          <WithPermissions action="read" resource="datasink">
            <Suspense>
              <DatasinkDetail />
            </Suspense>
          </WithPermissions>
        </AreValidParams>
      }
    />
    <Route
      path=":datasinkId/edit"
      element={
        <WithPermissions action="read" resource="datasink">
          <Suspense>
            <DatasinkEdit />
          </Suspense>
        </WithPermissions>
      }
    />
  </>
);

export const dataSinkCreatePipelineRoutes = (
  <Route
    element={
      <WithPermissions action="create" resource="datasink">
        <Suspense>
          <DatasinkCreate />
        </Suspense>
      </WithPermissions>
    }
  >
    <Route
      path="datasink/create/type"
      element={
        <WithPermissions action="create" resource="datasink">
          <Suspense>
            <DatasinkCreateType />
          </Suspense>
        </WithPermissions>
      }
    />
    <Route
      path="datasink/create/configuration"
      element={
        <WithPermissions action="create" resource="datasink">
          <Suspense>
            <DatasinkCreateConfiguration />
          </Suspense>
        </WithPermissions>
      }
    />
  </Route>
);

export const dataSinkViewRoute = (
  <Route
    path="datasink/:datasinkId"
    element={
      <WithPermissions action="read" resource="datasink">
        <Suspense>
          <DatasinkDetail />
        </Suspense>
      </WithPermissions>
    }
  />
);

export const dataSinkEditRoute = (
  <Route
    path="datasink/:datasinkId/edit"
    element={
      <WithPermissions action="read" resource="datasink">
        <Suspense>
          <DatasinkEdit />
        </Suspense>
      </WithPermissions>
    }
  />
);
