import {
  Container,
  type ContainerType,
  type Module,
  callStack,
} from '@nstrlabs/sdk';
import {
  BaseError,
  type DomainError,
  domainErrorSchema,
} from '@nstrlabs/utils';
import logger from './shared/infrastructure/logger';

const useCases = {
  'actions:parser:addDelimiter': () =>
    import('./actions/compose/ParserAction/addDelimiter'),
  'actions:parser:reParse': () =>
    import('./actions/compose/ParserAction/reParse'),
  'actions:parser:addToken': () =>
    import('./actions/compose/ParserAction/addToken'),
  'actions:parser:discoverLog': () =>
    import('./actions/compose/ParserAction/discoverLog'),
  'actions:parser:parseLog': () =>
    import('./actions/compose/ParserAction/parseLog'),
  'actions:parser:removeDelimiter': () =>
    import('./actions/compose/ParserAction/removeDelimiter'),
  'actions:parser:updateFields': () =>
    import('./actions/compose/ParserAction/updateFields'),
  'actions:parser:updateNames': () =>
    import('./actions/compose/ParserAction/updateNames'),
  'actions:parser:updateTypes': () =>
    import('./actions/compose/ParserAction/updateTypes'),
  'actions:getByIdAndVersion': () => import('./actions/compose/getAction'),
  'actions:getMetadata': () => import('./actions/compose/getMetadata'),
  'actions:testing:test': () =>
    import('./actions/compose/ActionTesting/testAction'),
  'alerts:countAlerts': () => import('./alerts/compose/countAlerts'),
  'alerts:getAlert': () => import('./alerts/compose/getAlerts'),
  'alerts:getAllAlerts': () => import('./alerts/compose/getAllAlerts'),
  'auth:forgetPassword': () => import('./auth/compose/forgetPassword'),
  'auth:getAuthToken': () => import('./auth/compose/getAuthToken'),
  'auth:login': () => import('./auth/compose/login'),
  'auth:loginRedirectResult': () =>
    import('./auth/compose/loginRedirectResult'),
  'auth:resendInviteUsers': () => import('./auth/compose/resendInviteUsers'),
  'auth:resetPassword': () => import('./auth/compose/resetPassword'),
  'auth:updatePassword': () => import('./auth/compose/updatePassword'),
  'auth:loginWithIdentityProvider': () =>
    import('./auth/compose/loginWithIdentityProvider'),
  'auth:logout': () => import('./auth/compose/logout'),
  'auth:logoutOnIdle': () => import('./auth/compose/logoutOnIdle'),
  'auth:onIdentityProviderTokenChange': () =>
    import('./auth/compose/onIdentityProviderTokenChange'),
  'auth:verifyNewUser': () => import('./auth/compose/verifyNewUser'),
  'auth:verifyUser': () => import('./auth/compose/verifyUser'),
  'clusters:all': () => import('./clusters/compose/all'),
  'datasinks:findById': () => import('./dataSinks/compose/findById'),
  'datasinks:getAll': () => import('./dataSinks/compose/getAll'),
  'datasinks:create': () => import('./dataSinks/compose/createDatasink'),
  'datasinks:delete': () => import('./dataSinks/compose/deleteDatasink'),
  'datasinks:update': () => import('./dataSinks/compose/update'),
  'datasinks:getPipelines': () => import('./dataSinks/compose/getPipelines'),
  'datasinks:updateNameTags': () =>
    import('./dataSinks/compose/updateNameTags'),
  'datasinks:updateTags': () => import('./dataSinks/compose/updateTags'),
  'listeners:findById': () => import('./listeners/compose/findById'),
  'listeners:getAll': () => import('./listeners/compose/getAll'),
  'listeners:create': () => import('./listeners/compose/createListener'),
  'listeners:delete': () => import('./listeners/compose/deleteListener'),
  'listeners:update': () => import('./listeners/compose/update'),
  'listeners:updateNameTags': () =>
    import('./listeners/compose/updateNameTags'),
  'listeners:updateTags': () => import('./listeners/compose/updateTags'),
  'listeners:getPipelines': () => import('./listeners/compose/getPipelines'),
  'labels:getPipelines': () =>
    import('./listeners/compose/getPipelinesByLabels'),
  'lookups:createLookup': () => import('./lookups/compose/createLookup'),
  'lookups:deleteLookup': () => import('./lookups/compose/deleteLookup'),
  'lookups:deleteLookupVersion': () =>
    import('./lookups/compose/deleteLookupVersion'),
  'lookups:findLookupVersion': () =>
    import('./lookups/compose/findLookupVersion'),
  'lookups:getLookupsSummary': () =>
    import('./lookups/compose/getLookupsSummary'),
  'lookups:updateLookup': () => import('./lookups/compose/updateLookup'),
  'lookups:getLookupById': () => import('./lookups/compose/getLookupById'),
  'lookups:uploadNewLookupVersion': () =>
    import('./lookups/compose/uploadNewLookupVersion'),
  'lookups:getPipelines': () => import('./lookups/compose/getPipelines'),
  'metrics:getMetrics': () => import('./metrics/compose/getMetrics'),
  'metrics:getMetricSeries': () => import('./metrics/compose/getMetricSeries'),
  'metrics:getMetricSankey': () => import('./metrics/compose/getMetricSankey'),
  'pipelines:allDatasinks': () => import('./pipelines/compose/allDatasinks'),
  'pipelines:delete': () => import('./pipelines/compose/deletePipeline'),
  'pipelines:deleteByIds': () => import('./pipelines/compose/deletePipelines'),
  'pipelines:stopPipelines': () => import('./pipelines/compose/stopPipelines'),
  'pipelines:startPipelines': () =>
    import('./pipelines/compose/startPipelines'),
  'pipelines:debug:publish': () => import('./pipelines/compose/debug/publish'),
  'pipelines:debug:pull': () => import('./pipelines/compose/debug/pull'),
  'pipelines:debug:unpublish': () =>
    import('./pipelines/compose/debug/unpublish'),
  'pipelines:debug:update': () => import('./pipelines/compose/debug/update'),
  'pipelines:getAll': () => import('./pipelines/compose/getAll'),
  'pipelines:getById': () => import('./pipelines/compose/getById'),
  'pipelines:getByIdAndVersion': () =>
    import('./pipelines/compose/getByIdAndVersion'),
  'pipelines:createInternalPipeline': () =>
    import('./pipelines/compose/createInternalPipeline'),
  'pipelines:getInternalPipelineLogsSamples': () =>
    import('./pipelines/compose/getInternalPipelineLogsSamples'),
  'pipelines:deleteInternalPipeline': () =>
    import('./pipelines/compose/deleteInternalPipeline'),
  'pipelines:getLogsSamples': () =>
    import('./pipelines/compose/getLogsSamples'),
  'pipelines:listeners': () => import('./pipelines/compose/getAllListeners'),
  'pipelines:modifyPipelineStatus': () =>
    import('./pipelines/compose/modifyPipelineStatus'),
  'pipelines:update': () => import('./pipelines/compose/update'),
  'pipelines:updateVersionNameAndDescription': () =>
    import('./pipelines/compose/updateVersionNameAndDescription'),
  'settings:getUserSettings': () =>
    import('./settings/compose/getUserSettings'),
  'settings:deleteUsers': () => import('./settings/compose/deleteUsers'),
  'settings:getRoles': () => import('./settings/compose/getRoles'),
  'settings:getUsers': () => import('./settings/compose/getUsers'),
  'settings:inviteUsers': () => import('./settings/compose/inviteUsers'),
  'settings:saveUserSettings': () =>
    import('./settings/compose/saveUserSettings'),
  'settings:updateRoles': () => import('./settings/compose/updateRoles'),
  'pipelines:createPipeline': () =>
    import('./pipelines/compose/createPipeline'),
  'pipelines:updateNameAndTags': () =>
    import('./pipelines/compose/updateNameAndTags'),
  'users:findById': () => import('./users/compose/findById'),
  'users:getAll': () => import('./users/compose/getAll'),
  'users:updateMainTenant': () => import('./users/compose/updateMainTenant'),
  'organizationSettings:secret:getSecrets': () =>
    import('./settings/compose/getSecrets'),
  'organizationSettings:secret:createSecret': () =>
    import('./settings/compose/createSecret'),
  'featureFlags:get': () => import('./settings/compose/getFeatureFlags'),
  'tenants:getAll': () => import('./tenants/compose/getAll'),
  'tenants:getTenantLogo': () => import('./tenants/compose/getTenantLogo'),
  'auth:changeTenant': () => import('./auth/compose/changeTenant'),
  'chronograph:getChronograph': () =>
    import('./chronograph/compose/getChronograph'),
  'assistant:getAllAssistants': () =>
    import('./ai-assistant/compose/getAllAssistants'),
  'assistant:getPipelineChat': () =>
    import('./ai-assistant/compose/getChat/getPipelineChat'),
  'assistant:getActionChat': () =>
    import('./ai-assistant/compose/getChat/getActionChat'),
  'assistant:getListenerChat': () =>
    import('./ai-assistant/compose/getChat/getListenerChat'),
  'assistant:getPipelineMessages': () =>
    import('./ai-assistant/compose/getMessages/getPipelineMessages'),
  'assistant:getActionMessages': () =>
    import('./ai-assistant/compose/getMessages/getActionMessages'),
  'assistant:getListenerMessages': () =>
    import('./ai-assistant/compose/getMessages/getListenerMessages'),
  'assistant:createPipelineChat': () =>
    import('./ai-assistant/compose/createChat/createPipelineChat'),
  'assistant:createActionChat': () =>
    import('./ai-assistant/compose/createChat/createActionChat'),
  'assistant:createListenerChat': () =>
    import('./ai-assistant/compose/createChat/createListenerChat'),
  'assistant:sendPipelineMessage': () =>
    import('./ai-assistant/compose/sendMessages/sendPipelineMessages'),
  'assistant:sendActionMessage': () =>
    import('./ai-assistant/compose/sendMessages/sendActionMessages'),
  'assistant:sendListenerMessage': () =>
    import('./ai-assistant/compose/sendMessages/sendListenerMessages'),
  'zones:get': () => import('./zones/compose/get'),
  'secrets:getZoneCertificate': () =>
    import('./secrets/compose/getZoneCertificate'),
  'secrets:getUsages': () => import('./settings/compose/getSecretUsages'),
  'secrets:deleteSecret': () => import('./secrets/compose/deleteSecret'),
  'activity:getUserActivity': () => import('./activity/compose/getActivity'),
};

const isDomainError = (error: unknown): error is DomainError =>
  domainErrorSchema.safeParse(error).success;

export const container = Container.of(useCases);
container.middleWare(({ useCase, error }) => {
  if (isDomainError(error) && error.persistError) {
    logger.error('error', {
      module: 'context',
      function: useCase,
      errorCode: error.code,
      context: {
        trace: console.trace(),
        stack: callStack(),
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  if (error instanceof BaseError) {
    logger.error('error', {
      module: error.name,
      function: useCase,
      errorCode: error.code,
      context: {
        trace: console.trace(),
        stack: error.stack,
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  if ((error as { message: string }).message === 'Failed to fetch') {
    logger.error('error', {
      module: 'context',
      function: useCase,
      errorCode: 'WEB-66666',
      context: {
        trace: console.trace(),
        stack: callStack(),
        url: window.location.href.replace(window.location.origin, ''),
        error,
      },
    });
    return;
  }
  logger.error('error', {
    module: 'context',
    function: useCase,
    errorCode: 'WEB-00008',
    context: {
      trace: console.trace(),
      stack: callStack(),
      url: window.location.href.replace(window.location.origin, ''),
      error,
      name: (error as { name: string }).name,
      message: (error as { message: string }).message,
    },
  });
});

export type UseCases = typeof useCases;

export type UseCasesKey = keyof UseCases;

export type AppContainer = ContainerType<UseCases>;

type Command<UseCase extends Module> = Parameters<
  Awaited<ReturnType<UseCase>>['default']
>[0];

type Result<UseCase extends Module> = Awaited<
  ReturnType<Awaited<ReturnType<UseCase>>['default']>
>;

type ContainerUseCases = {
  [Key in keyof UseCases]: {
    command: Command<UseCases[Key]>;
    result: Result<UseCases[Key]>;
  };
};

export type ContainerUseCase<
  Key extends keyof ContainerUseCases,
  View extends 'command' | 'result',
> = ContainerUseCases[Key][View];
