import type { UseCase } from '@nstrlabs/utils';
import logger from '../../shared/infrastructure/logger';
import type { AuthRepository } from '../domain/AuthRepository';
// Variables used for logger
const moduleName = 'context/auth/application/logout';
const functionName = 'logout';
export const logout =
  (
    removeActivityTimestamp: AuthRepository['removeActivityTimestamp'],
    doLogout: AuthRepository['logout'],
    removeToken: AuthRepository['removeToken'],
  ): UseCase<void, void> =>
  async () => {
    logger.debug('logout invoked', {
      module: moduleName,
      function: functionName,
    });
    removeActivityTimestamp();
    await removeToken();
    await doLogout();
  };
