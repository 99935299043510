import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import { z } from 'zod';
import FeatureFlags from '../../../components/atoms/FeatureFlag';
import WithPermissions from '../../../hooks/controlAccess/WithPermissions';
import AreValidParams from '../AreValidParams';

const OldListenerCreateType = lazy(
  () => import('../../../pages/ListenerCreate/Old/ListenerCreateType'),
);

const OldListenerCreateConfiguration = lazy(
  () => import('../../../pages/ListenerCreate/Old/ListenerCreateConfiguration'),
);

const OldListenerCreateLabels = lazy(
  () => import('../../../pages/ListenerCreate/Old/ListenerCreateLabels'),
);

const ListenerCreate = lazy(
  () => import('../../../pages/ListenerCreate/ListenerCreateFlow'),
);
const ListenerCreateType = lazy(
  () => import('../../../pages/ListenerCreate/ListenerCreateType'),
);
const ListenerCreateConfiguration = lazy(
  () => import('../../../pages/ListenerCreate/ListenerCreateConfiguration'),
);
const ListenerCreateLabels = lazy(
  () => import('../../../pages/ListenerCreate/ListenerCreateLabels'),
);
const ListenerCreateNetwork = lazy(
  () => import('../../../pages/ListenerCreate/ListenerCreateNetwork'),
);
const ListenerDetail = lazy(() => import('../../../pages/ListenerDetail'));
const ListenerEdit = lazy(() => import('../../../pages/ListenerEdit'));

const validateListenerDetailParams = (params: { listenerId: string }) => {
  const listenerDetailsParams = z.object({
    listenerId: z.string().startsWith('LS_'),
  });

  return listenerDetailsParams.safeParse(params).success;
};

export const listenersNestedRoutes = (
  <>
    <Route
      element={
        <WithPermissions action="create" resource="listener">
          <Suspense>
            <ListenerCreate />
          </Suspense>
        </WithPermissions>
      }
      path="create"
    >
      <Route
        element={
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateType />}
            >
              <ListenerCreateType />
            </FeatureFlags>
          </Suspense>
        }
        path="type"
      />
      <Route
        element={
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateConfiguration />}
            >
              <ListenerCreateConfiguration />
            </FeatureFlags>
          </Suspense>
        }
        path="configuration"
      />
      <Route
        element={
          <Suspense>
            <ListenerCreateNetwork />
          </Suspense>
        }
        path="network"
      />
      <Route
        element={
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateLabels />}
            >
              <ListenerCreateLabels />
            </FeatureFlags>
          </Suspense>
        }
        path="labels"
      />
    </Route>
    <Route
      path=":listenerId"
      element={
        <AreValidParams validate={validateListenerDetailParams}>
          <WithPermissions action="read" resource="listener">
            <Suspense>
              <ListenerDetail />
            </Suspense>
          </WithPermissions>
        </AreValidParams>
      }
    />
    <Route
      path=":listenerId/edit"
      element={
        <WithPermissions action="read" resource="listener">
          <Suspense>
            <ListenerEdit />
          </Suspense>
        </WithPermissions>
      }
    />
  </>
);

export const listenerCreatePipelineRoutes = (
  <Route
    element={
      <WithPermissions action="create" resource="listener">
        <Suspense>
          <ListenerCreate />
        </Suspense>
      </WithPermissions>
    }
  >
    <Route
      path="listener/create/type"
      element={
        <WithPermissions action="create" resource="listener">
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateType />}
            >
              <ListenerCreateType />
            </FeatureFlags>
          </Suspense>
        </WithPermissions>
      }
    />

    <Route
      path="listener/create/configuration"
      element={
        <WithPermissions action="create" resource="listener">
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateConfiguration />}
            >
              <ListenerCreateConfiguration />
            </FeatureFlags>
          </Suspense>
        </WithPermissions>
      }
    />
    <Route
      element={
        <Suspense>
          <ListenerCreateNetwork />
        </Suspense>
      }
      path="listener/create/network"
    />
    <Route
      path="listener/create/labels"
      element={
        <WithPermissions action="create" resource="listener">
          <Suspense>
            <FeatureFlags
              flag="cloud-listeners"
              fallback={<OldListenerCreateLabels />}
            >
              <ListenerCreateLabels />
            </FeatureFlags>
          </Suspense>
        </WithPermissions>
      }
    />
  </Route>
);

export const listenerViewRoute = (
  <Route
    path="listener/:listenerId"
    element={
      <WithPermissions action="read" resource="listener">
        <Suspense>
          <ListenerDetail />
        </Suspense>
      </WithPermissions>
    }
  />
);

export const listenerEditRoute = (
  <Route
    path="listener/:listenerId/edit"
    element={
      <WithPermissions action="read" resource="listener">
        <Suspense>
          <ListenerEdit />
        </Suspense>
      </WithPermissions>
    }
  />
);
