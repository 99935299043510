import { Flex } from '@nstrlabs/ixel';
import clsx from 'clsx';
import type { ReactNode } from 'react';
import styles from './index.module.css';

type SidebarProps = {
  bar: ReactNode;
  content: ReactNode;
};

const ModalSidebar = ({ bar, content }: SidebarProps) => (
  <Flex className={clsx(styles.modalContent, 'ModalSidebar-Root')}>
    <aside className={clsx(styles.sidebar, 'ModalSidebar-SideBar')}>
      {bar}
    </aside>
    <Flex className={styles.container}>
      <div className={clsx(styles.content, 'ModalSidebar-Content')}>
        {content}
      </div>
    </Flex>
  </Flex>
);

export default ModalSidebar;
