import {
  type ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  type FeatureFlags,
  FetchFeatureFlagsError,
} from '../../context/settings/domain/FeatureFlags';
import logger from '../../context/shared/infrastructure/logger';
import { useContainer } from '../useContainer';
import { useUser } from '../useUser';

export const AVAILABLE_FEATURE_FLAGS = [
  'ai-assistant',
  'alerts',
  'cloud-listeners',
  'conditionals-forms',
  'debugging-phase-1',
  'debugging',
  'enrichment',
  'kronograph',
  'messageBuilder-delimiter',
  'update-actions',
] as const;

export type FeatureFlagKey =
  | (typeof AVAILABLE_FEATURE_FLAGS)[number]
  | (string & {});

export type FeatureFlagsContextType = FeatureFlags;

const featureFlagsContext = createContext<FeatureFlagsContextType | null>(null);

export const useFeatureFlagsContainer = (): FeatureFlagsContextType => {
  const container = useContainer();
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>({});
  const user = useUser();

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  useEffect(() => {
    container
      .call('featureFlags:get')
      .then((ff) => {
        logger.info('FeatureFlag', {
          module: 'featureFlags:get',
          function: 'useFlagsContainer',
          context: { ff },
        });
        setFeatureFlags(ff);
      })
      .catch(() => {
        throw new FetchFeatureFlagsError();
      });
  }, [container, user]);

  return featureFlags;
};

export const FeatureFlagsProvider = (props: { children?: ReactNode }) => {
  const featureFlags = useFeatureFlagsContainer();

  return <featureFlagsContext.Provider {...props} value={featureFlags} />;
};

export class UseFeatureFlagsError extends Error {
  constructor() {
    super('useFeatureFlags should be used within a provider');
  }
}

export const useFeatureFlags = () => {
  const container = useContext(featureFlagsContext);

  if (container === null) {
    throw new UseFeatureFlagsError();
  }

  const isActive = (key: FeatureFlagKey) => container[key] ?? false;

  return { isActive };
};
