import { Button, Icon, InputField, useOutsideClick } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { type ComponentPropsWithoutRef, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

export type HomesSearchProps = ComponentPropsWithoutRef<'input'> & {
  id?: string;
  value: string;
  alwaysActive?: boolean;
  onDelete?: () => void;
  onSearch: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const HomesSearch = ({
  id = 'table-search',
  value,
  alwaysActive = false,
  onDelete,
  onSearch,
  ...props
}: HomesSearchProps) => {
  const [active, setActive] = useState<boolean>(alwaysActive);
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);
  const ref = useRef(null);

  const handleClickOutside = () =>
    value.length ? null : setActive(alwaysActive);
  useOutsideClick(ref.current, active, handleClickOutside);

  const handleSearch = () => {
    setActive(true);
    inputRef?.current?.focus();
  };

  return (
    <div
      className={clsx(styles.search, {
        [styles.inactive]: !active,
        [styles.active]: active,
      })}
      ref={ref}
    >
      <Button
        aria-label="Open search"
        className={styles.searchButton}
        icon={<Icon name="search" />}
        variant="link"
        mode="primary"
        onClick={(e) => {
          e.stopPropagation();
          handleSearch();
        }}
      />
      <InputField
        {...props}
        id={id}
        ref={inputRef}
        role="searchbox"
        type="text"
        onChange={onSearch}
        value={value}
        className={clsx(styles.searchInput)}
        placeholder={t('TABLE_SEARCH_PLACEHOLDER')}
      />
      {onDelete ? (
        <Button
          aria-label="Close search"
          className={styles.closeButton}
          icon={<Icon name="close" height={12} width={12} />}
          variant="link"
          mode="primary"
          onClick={(e) => {
            e.stopPropagation();
            setActive(alwaysActive);
            invoke(onDelete);
          }}
        />
      ) : null}
    </div>
  );
};

export default HomesSearch;
