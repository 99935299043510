import { Flex, SquareIcon, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import type { ComponentProps } from 'react';

type SettingsHeaderProps = {
  className?: string;
  squareClassName?: string;
  description: string;
  title: string;
  icon: ComponentProps<typeof SquareIcon>['icon'];
};

const SettingsHeader = ({
  className,
  squareClassName,
  description,
  title,
  icon,
}: SettingsHeaderProps) => (
  <Flex className={className} flexDirection="column" gap="xl">
    <SquareIcon className={clsx(squareClassName)} icon={icon} />
    <Flex flexDirection="column" gap="xs">
      <Typography variant="header" size="small" bold>
        {title}
      </Typography>
      <Typography color="secondary">{description}</Typography>
    </Flex>
  </Flex>
);

export default SettingsHeader;
