export type LogLevels = 'error' | 'warn' | 'info' | 'debug' | 'log';

type LogWeights = {
  [Level in LogLevels]: number;
};

const LOG_LEVELS: LogWeights = {
  error: 3,
  warn: 2,
  info: 1,
  debug: 0,
  log: -1,
};

export type LogContext = {
  module: string;
  function: string;
  errorCode?: string;
  context?: Record<string, unknown>;
};

type LoggerMethods = {
  [Level in LogLevels]: (message: string, logContext: LogContext) => void;
};

export type LoggerClientParams = {
  project: string;
  tenant: string;
  tenantName: string;
  userId: string;
  userEmail: string;
  url: string;
  sessionId: string;
  browser: string;
  appVersion: string;
  logContext: LogContext;
  message: string;
};

export type LoggerClientMethods = {
  [Level in LogLevels]: (params: LoggerClientParams) => void;
};

export class Logger implements LoggerMethods {
  readonly client: LoggerClientMethods;

  readonly logLevelFilter: LogLevels;

  // Logger project
  _project = 'console';

  // Logger tenant
  _tenant = '';

  // Logger userId
  _userId = '';

  // Logger email
  _userEmail = '';

  // Logger section (web URL)
  _url = '';

  // Logger sessionId
  _sessionId = '';

  // Logger browser
  _browser = navigator.userAgent;

  // Logger appversion
  _appVersion = '';

  // Logger tenant name
  _tenantName = '';

  constructor(client: LoggerClientMethods, logLevelFilter: LogLevels = 'log') {
    this.client = client;
    this.logLevelFilter = logLevelFilter;
  }

  get project(): string {
    return this._project;
  }

  set project(value: string) {
    this._project = value;
  }

  get tenant(): string {
    return this._tenant;
  }

  set tenant(value: string) {
    this._tenant = value;
  }

  get userId(): string {
    return this._userId;
  }

  set userId(value: string) {
    this._userId = value;
  }

  get userEmail(): string {
    return this._userEmail;
  }

  set userEmail(value: string) {
    this._userEmail = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get sessionId(): string {
    return this._sessionId;
  }

  set sessionId(value: string) {
    this._sessionId = value;
  }

  get browser(): string {
    return this._browser;
  }

  set browser(value: string) {
    this._browser = value;
  }

  get appVersion(): string {
    return this._appVersion;
  }

  set appVersion(value: string) {
    this._appVersion = value;
  }

  get tenantName(): string {
    return this._tenantName;
  }

  set tenantName(value: string) {
    this._tenantName = value;
  }

  private isLogLevelEnabled(level: LogLevels) {
    return LOG_LEVELS[level] >= LOG_LEVELS[this.logLevelFilter];
  }

  private send(level: LogLevels, message: string, logContext: LogContext) {
    if (this.isLogLevelEnabled(level))
      this.client[level]({
        project: this.project,
        tenant: this.tenant,
        tenantName: this.tenantName,
        userId: this.userId,
        userEmail: this.userEmail,
        url: this.url,
        sessionId: this.sessionId,
        browser: this.browser,
        appVersion: this.appVersion,
        message,
        logContext,
      });
  }

  debug(message: string, logContext: LogContext) {
    this.send('debug', message, logContext);
  }

  log(message: string, logContext: LogContext) {
    this.send('log', message, logContext);
  }

  info(message: string, logContext: LogContext) {
    this.send('info', message, logContext);
  }

  warn(message: string, logContext: LogContext) {
    this.send('warn', message, logContext);
  }

  error(message: string, logContext: LogContext) {
    this.send('error', message, logContext);
  }
}
