import { Button, Flex, Typography } from '@nstrlabs/ixel';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.css';

const NotFound = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <section className={styles.container}>
      <Flex
        flexDirection="column"
        alignItems="flexStart"
        className={styles.notFound}
      >
        <div className={styles.content}>
          <Flex flexDirection="column" className={styles.explanation}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              className={styles.errorTxt}
            >
              <Typography
                as="h2"
                className={styles.state}
                variant="header"
                size="large"
                bold
              >
                404
              </Typography>
              <Flex
                flexDirection="column"
                justifyContent="center"
                className={styles.info}
              >
                <Typography as="h3" className={styles.title} variant="header">
                  {t('NOT_FOUND.TITLE')}
                </Typography>
                <Typography className={styles.message} size="large">
                  {t('NOT_FOUND.DESCRIPTION')}
                </Typography>
                <Button
                  variant="solid"
                  style={{ width: 'fit-content' }}
                  onClick={() => navigate('/home')}
                >
                  {t('NOT_FOUND.BUTTONS.BACK')}
                </Button>
              </Flex>
            </Flex>
          </Flex>
          <img
            src="/data-flow.svg"
            alt="maintenance"
            className={styles.spiral}
          />
        </div>
      </Flex>
    </section>
  );
};

export default NotFound;
