import { Button, Flex, Toggle, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

export type SecretsZeroCaseProps = {
  title?: string;
  description?: string;
  buttonText?: string;
  onCreateClick?: () => void;
};
const SecretsZeroCase = ({
  title,
  description,
  buttonText,
  onCreateClick,
}: SecretsZeroCaseProps) => {
  const { t } = useTranslation();

  return (
    <Flex className={styles.zeroCaseContainer}>
      <Flex className={styles.left}>
        <Flex className={clsx(styles.innerWrapper, 'h-box-shadow-soft')}>
          <Flex flexDirection="column" gap="sm">
            <Flex
              alignItems="center"
              className={clsx(styles.secretBox)}
              gap="xs"
            >
              <Toggle checked />
              <Flex flexDirection="column" gap="xxs">
                <div className={clsx(styles.filterBox)}>
                  <span />
                </div>
                <div className={clsx(styles.filterBoxThin)}>
                  <span />
                </div>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              className={clsx(styles.secretBox)}
              gap="xs"
            >
              <Toggle checked />
              <Flex flexDirection="column" gap="xxs">
                <div className={clsx(styles.filterBox)}>
                  <span />
                </div>
                <div className={clsx(styles.filterBoxThin)}>
                  <span />
                </div>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              className={clsx(styles.secretBox)}
              gap="xs"
            >
              <Toggle checked={false} />
              <Flex flexDirection="column" gap="xxs">
                <div className={clsx(styles.filterBox)}>
                  <span />
                </div>
                <div className={clsx(styles.filterBoxThin)}>
                  <span />
                </div>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              className={clsx(styles.secretBox)}
              gap="xs"
            >
              <Toggle checked />
              <Flex flexDirection="column" gap="xxs">
                <div className={clsx(styles.filterBox)}>
                  <span />
                </div>
                <div className={clsx(styles.filterBoxThin)}>
                  <span />
                </div>
              </Flex>
            </Flex>
            <Flex
              alignItems="center"
              className={clsx(styles.secretBox)}
              gap="xs"
            >
              <Toggle checked />
              <Flex flexDirection="column" gap="xxs">
                <div className={clsx(styles.filterBox)}>
                  <span />
                </div>
                <div className={clsx(styles.filterBoxThin)}>
                  <span />
                </div>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
      <Flex className={styles.right} alignItems="center" flexDirection="column">
        <Flex
          gap="md"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body" size="large" bold>
            {title ??
              t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.ZEROCASE.TITLE')}
          </Typography>
          <Typography className={styles.description}>
            {description ??
              t(
                'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.ZEROCASE.DESCRIPTION',
              )}
          </Typography>
        </Flex>
        {onCreateClick && (
          <Typography className={styles.description}>
            <Button
              data-cy="secret-zero-case-button-create"
              mode="main"
              variant="solid"
              onClick={onCreateClick}
            >
              {buttonText ??
                t(
                  'SETTINGS.ORGANIZATION_SETTINGS.SECRET_CREATE.ZEROCASE.CREATE',
                )}
            </Button>
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

export default SecretsZeroCase;
