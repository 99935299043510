import clsx from 'clsx';
import styles from './index.module.css';

const DefaultMark = ({ className }: { className?: string }) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={clsx(className, styles.mark)}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      name="mark"
      d="M12.6686 4.91449V14.2893C12.6687 14.4298 12.5989 14.5587 12.4877 14.623C12.3765 14.6873 12.2427 14.6763 12.141 14.5945L8.19407 11.4236C8.07801 11.3302 7.92196 11.3302 7.8059 11.4236L3.85893 14.5937C3.75736 14.6755 3.62369 14.6866 3.51257 14.6224C3.40145 14.5583 3.33152 14.4297 3.33138 14.2893V4.91449C3.33138 3.67188 4.22718 2.66455 5.33221 2.66455H10.6678C11.7728 2.66455 12.6686 3.67188 12.6686 4.91449Z"
    />
    <path
      name="border"
      d="M12.6686 14.2893H12.0019V14.2897L12.6686 14.2893ZM12.4877 14.623L12.8216 15.2L12.8216 15.2L12.4877 14.623ZM12.141 14.5945L12.559 14.0751L12.5586 14.0748L12.141 14.5945ZM8.19407 11.4236L7.77608 11.9429L7.77653 11.9433L8.19407 11.4236ZM7.8059 11.4236L8.22338 11.9433L8.22389 11.9429L7.8059 11.4236ZM3.85893 14.5937L3.44145 14.074L3.44094 14.0744L3.85893 14.5937ZM3.51257 14.6224L3.17931 15.1998L3.17932 15.1998L3.51257 14.6224ZM3.33138 14.2893H2.66471L2.66471 14.29L3.33138 14.2893ZM13.3353 14.2893V4.91449H12.0019V14.2893H13.3353ZM12.8216 15.2C13.1615 15.0034 13.3355 14.6401 13.3353 14.2888L12.0019 14.2897C12.0019 14.2195 12.0363 14.114 12.1538 14.046L12.8216 15.2ZM11.7231 15.1139C12.0347 15.3647 12.4669 15.4053 12.8216 15.2L12.1538 14.046C12.2862 13.9694 12.4507 13.9879 12.559 14.0751L11.7231 15.1139ZM7.77653 11.9433L11.7235 15.1142L12.5586 14.0748L8.6116 10.9039L7.77653 11.9433ZM8.22389 11.9429C8.16604 11.9895 8.08728 12.0202 7.99999 12.0202C7.91269 12.0202 7.83393 11.9895 7.77608 11.9429L8.61205 10.9042C8.25195 10.6144 7.74802 10.6144 7.38792 10.9042L8.22389 11.9429ZM4.2764 15.1135L8.22338 11.9433L7.38843 10.9038L3.44145 14.074L4.2764 15.1135ZM3.17932 15.1998C3.53392 15.4045 3.96555 15.3637 4.27691 15.1131L3.44094 14.0744C3.54917 13.9873 3.71345 13.9686 3.84583 14.045L3.17932 15.1998ZM2.66471 14.29C2.66508 14.641 2.83946 15.0037 3.17931 15.1998L3.84583 14.045C3.96345 14.1129 3.99797 14.2184 3.99804 14.2885L2.66471 14.29ZM2.66471 4.91449V14.2893H3.99804V4.91449H2.66471ZM5.33221 1.99788C3.7876 1.99788 2.66471 3.37939 2.66471 4.91449H3.99804C3.99804 3.96438 4.66676 3.33122 5.33221 3.33122V1.99788ZM10.6678 1.99788H5.33221V3.33122H10.6678V1.99788ZM13.3353 4.91449C13.3353 3.37939 12.2124 1.99788 10.6678 1.99788V3.33122C11.3332 3.33122 12.0019 3.96438 12.0019 4.91449H13.3353Z"
    />
  </svg>
);

export default DefaultMark;
