import {
  Button,
  type Column,
  DataTable,
  type DataTableDensityType,
  Flex,
  Icon,
  Tooltip,
  Typography,
} from '@nstrlabs/ixel';

import type {
  GridReadyEvent,
  SelectionChangedEvent,
} from '@ag-grid-community/core';
import { type EventEmitter, invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import type { TFunction } from 'i18next';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DropdownInfo from '../../../atoms/DropdownInfo';
import TableDensity from '../TableDensity';
import TableHeader from '../TableHeader';
import ColumnHeaderActionMenu from '../TableHeaderActionsMenu';
import TableSettings from '../TableSettings';
import styles from './index.module.css';

type Row<T = unknown> = {
  id: string;
} & {
  [key: string]: T;
};

const groupColumnsBy = (
  columns: Column<unknown>[],
  columnGroupIndex?: string,
) =>
  columns.map((column) => ({
    ...column,
    rowGroup: column.id === columnGroupIndex,
    hidden: column.id === columnGroupIndex,
  }));

export type TableProps<T> = {
  className?: string;
  columns: Column<T>[];
  data: T[];
  emitter?: EventEmitter<{ type: 'deselect' }>;
  hideHeader?: boolean;
  title: string;
  activeRow: string;
  searchValue?: string;
  groupSelection: string;
  noData: React.ReactNode;
  disableClickSelection?: boolean;
  tableDensity?: DataTableDensityType;
  getRowId?: (row: T) => string;
  onSelectionChanged?: (event: SelectionChangedEvent) => void;
  onGridReady?: (event: GridReadyEvent) => void;
  onClickRow?: (row: Row, event?: Event | null) => void;
  onRowSelected?: (selected: T, total: T[]) => void;
  onRowActive: (newData: string) => void;
};

const getColumnsDefaultPositionById = <T,>(columns: Column<T>[]): string[] =>
  columns.map((column) => column.id);

const buildColumns = <T,>(
  columns: Column<T>[],
  translation: TFunction<'translation', undefined>,
): Column<T>[] =>
  columns.map((column) => ({
    ...column,
    className: column.checkable
      ? clsx(column.className, styles.checkableColumn)
      : column.className,
    headerClassName: column.checkable
      ? clsx(column.headerClassName, styles.checkableColumn)
      : column.headerClassName,
    headerRenderer: column.checkable ? () => null : column.headerRenderer,
    cellRenderer: column.checkable ? () => null : column.cellRenderer,

    name: column.name ? translation(column.name) : column.id,
  }));

const Table = <T,>({
  className,
  columns: primitiveColumns,
  data,
  emitter,
  hideHeader,
  title,
  activeRow,
  searchValue,
  groupSelection,
  noData,
  disableClickSelection,
  getRowId,
  tableDensity = 'comfortable',
  onSelectionChanged,
  onGridReady,
  onClickRow,
  onRowSelected,
  onRowActive,
}: TableProps<T>) => {
  const { t } = useTranslation();
  const [columns, setColumns] = useState<Column<T>[]>(
    buildColumns(primitiveColumns, t),
  );

  useEffect(() => {
    setColumns((currentColumns) =>
      groupColumnsBy(currentColumns, groupSelection),
    );
  }, [groupSelection]);

  const columnsDefaultPosition = useMemo(
    () => getColumnsDefaultPositionById(primitiveColumns),
    [primitiveColumns],
  );

  const handleClickRow = (row: Row, event?: Event | null) => {
    if (
      (event &&
        ((event.target as HTMLButtonElement).id === 'action-button' ||
          (event.target &&
            (event.target as HTMLButtonElement).closest('#action-button') !==
              null))) ||
      row === undefined
    ) {
      return;
    }

    invoke(onRowActive, row?.id);
    invoke(onClickRow, row, event);
  };

  return (
    <div className={clsx(styles.table, className)}>
      {hideHeader ? null : (
        <TableHeader
          leftContent={
            <Flex alignItems="center" gap="xs">
              <DropdownInfo
                className={styles.dropdown}
                onTargetClick={(target: EventTarget) =>
                  target !== document.body
                }
                placement="bottom-start"
                trigger={
                  <Tooltip
                    className={styles.tooltip}
                    content={
                      <Typography as="p" bold className={styles.tooltipText}>
                        {t('TABLE_SETTINGS.TOOLTIP')}
                      </Typography>
                    }
                    placement="top"
                  >
                    <Button
                      aria-label="Table settings"
                      mode="primary"
                      variant="link"
                      icon={<Icon name="settings" />}
                    />
                  </Tooltip>
                }
              >
                <TableSettings
                  {...{ columns, columnsDefaultPosition }}
                  onColumnUpdate={setColumns}
                />
              </DropdownInfo>
              <Typography bold>{title}</Typography>
              <Typography as="span" color="tertiary">
                ({data.length})
              </Typography>
            </Flex>
          }
          rightContent={[<TableDensity key="density" />]}
        />
      )}
      <DataTable
        columnMenu={ColumnHeaderActionMenu}
        columns={columns}
        data={data}
        emitter={emitter}
        noData={noData}
        onClickRow={handleClickRow}
        onRowSelected={onRowSelected}
        onUpdateColumns={setColumns}
        rowDensity={tableDensity}
        searchValue={searchValue}
        selectedRow={activeRow}
        disableClickSelection={disableClickSelection}
        onSelectionChanged={onSelectionChanged}
        onGridReady={onGridReady}
        getRowId={getRowId}
      />
    </div>
  );
};

export default Table;
