import { invoke } from '@nstrlabs/utils';
import { useCallback, useState } from 'react';
import { useContainer } from '../useContainer';

const useLogout = (
  onSuccess: () => void,
  onError: (error: Error) => void,
): [boolean, (e: React.SyntheticEvent) => void] => {
  const container = useContainer();
  const [loading, setLoading] = useState(false);

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  const handleLogout = useCallback(
    (e: React.SyntheticEvent) => {
      e.preventDefault();
      setLoading(true);
      container
        .call('auth:logout')
        .then(() => invoke(onSuccess))
        .catch((error: Error) => invoke(onError, error))
        .finally(() => setLoading(false));
    },

    [onSuccess, onError],
  );

  return [loading, handleLogout];
};

export default useLogout;
