import {
  Avatar,
  Button,
  Flex,
  Icon,
  SegmentedControl,
  Spinner,
  Tag,
  Typography,
} from '@nstrlabs/ixel';
import type { BackgroundColorType } from '@nstrlabs/ixel/dist/atoms/Avatar';
import { useLogger } from '@nstrlabs/sdk';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import type { UserSettings as Settings } from '../../../context/settings/domain/UserSettings';
import useLogout from '../../../hooks/auth/useLogout';
import { useConsoleSettings } from '../../../hooks/settings/useConsoleSettings';
import { useUserSettings } from '../../../hooks/settings/useUserSettings';
import Media from '../../atoms/Media';
import styles from './index.module.css';
import { useMenuSettingsOptions } from './useMenuSettingsOptions';

export type UserType = {
  lastName: string;
  firstName: string;
  image?: string;
  email: string;
  color?: BackgroundColorType;
};

export type UserSettingsType = {
  theme: 'light' | 'dark';
  keyboardShortcuts: 'on' | 'off';
};

export type UserSettingsProps = {
  user: UserType;
  onClose: () => void;
};

type SettingOptionType = {
  label: string;
  value: string;
};

type UserSettingsControlProps = {
  options: SettingOptionType[];
  selected: string;
  onChange: (name: string) => void;
};

const UserSettingsControl = ({
  options,
  selected,
  onChange,
}: UserSettingsControlProps) => (
  <SegmentedControl
    active={selected}
    onChange={onChange}
    className={styles.settingControl}
  >
    {options.map(({ label, value }) => (
      <SegmentedControl.Item key={value} name={value}>
        {label}
      </SegmentedControl.Item>
    ))}
  </SegmentedControl>
);

const moduleName = 'UserSettings';

const UserSettings = ({ user, onClose }: UserSettingsProps): JSX.Element => {
  const { setConsoleSettingsIsOpen } = useConsoleSettings();
  const { t } = useTranslation();
  const logger = useLogger();
  const navigate = useNavigate();
  const location = useLocation();
  const options = useMenuSettingsOptions();

  const userName = `${user.firstName} ${user.lastName}`;

  const { settings, saveSettings } = useUserSettings();

  const onChangeTheme = useCallback(
    (value: Settings['theme']) => {
      void saveSettings({
        ...settings,
        theme: value,
      });
    },
    [settings, saveSettings],
  );

  const onChangeKeyboardShortcuts = useCallback(
    (value: Settings['keyboardShortcuts']) => {
      void saveSettings({
        ...settings,
        keyboardShortcuts: value,
      });
    },
    [settings, saveSettings],
  );

  const themeOptions: SettingOptionType[] = useMemo(
    () =>
      t('USER_SETTINGS.THEME_OPTIONS', {
        returnObjects: true,
      }),
    [t],
  );

  const keyboardOptions: SettingOptionType[] = useMemo(
    () =>
      t('USER_SETTINGS.KEYBOARD_SHORTCUTS_OPTIONS', {
        returnObjects: true,
      }),
    [t],
  );

  const [logoutLoading, handleLogout] = useLogout(
    () => navigate('/'),
    (error: Error) =>
      logger.error(`${error.name}: ${error.message}`, {
        module: moduleName,
        function: 'onLogoutError',
        context: { error },
      }),
  );
  const handleClickSettings = (sectionValue: string, defaultOption: string) => {
    invoke(onClose);
    navigate(`?sectionSelected=${sectionValue}&menuOption=${defaultOption}`, {
      state: { from: location.pathname, menuOptionValue: sectionValue },
    });
    setConsoleSettingsIsOpen(true);
  };

  return (
    <div className={styles.container}>
      <div className={clsx(styles.section, styles.header)}>
        <Media
          media={<Avatar user={user} className={styles.avatar} />}
          content={
            <div className={styles.user}>
              <Typography as="div" bold>
                {userName}
              </Typography>
              <Typography as="div" className={styles.email}>
                {user.email}
              </Typography>
            </div>
          }
        />
      </div>

      <div className={clsx(styles.section, styles.body)}>
        <Typography as="div" bold size="default" variant="body">
          {t('USER_SETTINGS.SCREEN_SETTINGS')}
        </Typography>
        <div className={styles.setting}>
          <Typography
            as="div"
            size="default"
            variant="body"
            className={styles.settingLabel}
          >
            {t('USER_SETTINGS.THEME')}
          </Typography>
          <UserSettingsControl
            options={themeOptions}
            selected={settings.theme}
            onChange={onChangeTheme}
          />
        </div>
        <div className={styles.setting}>
          <Typography
            as="div"
            size="default"
            variant="body"
            className={styles.settingLabel}
          >
            {t('USER_SETTINGS.KEYBOARD_SHORTCUTS')}
          </Typography>
          <UserSettingsControl
            options={keyboardOptions}
            selected={settings.keyboardShortcuts}
            onChange={onChangeKeyboardShortcuts}
          />
        </div>
      </div>

      <div className={clsx(styles.section, styles.content)}>
        <Flex flexDirection="column" className={styles.nav} gap="xs">
          {options.map(({ value, label, defaultOption }) => (
            <Flex
              key={`row-${defaultOption}`}
              flexDirection="row"
              justifyContent="spaceBetween"
            >
              <Button
                mode="primary"
                size="small"
                variant="link"
                color="secondary"
                onClick={() => {
                  handleClickSettings(value, defaultOption);
                }}
                className={styles.optionBtn}
              >
                {t(label)}
              </Button>
              {defaultOption === 'activity_log' ? (
                <Tag text={t('TAGS.NEW')} variant="published" />
              ) : null}
            </Flex>
          ))}
        </Flex>
      </div>

      <div className={clsx(styles.section, styles.footer)}>
        <Button
          onClick={handleLogout}
          mode="primary"
          size="small"
          variant="link"
          color="secondary"
          icon={logoutLoading ? <Spinner /> : <Icon name="logout" />}
        >
          {t(
            logoutLoading
              ? 'USER_SETTINGS.LOGGING_OUT'
              : 'USER_SETTINGS.LOGOUT',
          )}
        </Button>
      </div>
    </div>
  );
};

export default UserSettings;
