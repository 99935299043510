import { Flex } from '@nstrlabs/ixel';
import styles from './index.module.css';

type TableHeaderProps = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode[];
};

const TableHeader = ({ leftContent, rightContent }: TableHeaderProps) => (
  <Flex justifyContent="spaceBetween" className={styles.tableHeader}>
    <Flex>{leftContent}</Flex>
    <Flex alignItems="center" className={styles.sideGap}>
      {rightContent}
    </Flex>
  </Flex>
);

export default TableHeader;
