import {
  Button,
  Flex,
  Icon,
  Modal,
  SquareIcon,
  Typography,
} from '@nstrlabs/ixel';
import { withResolvers } from '@nstrlabs/utils';
import {
  type ComponentProps,
  type ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { match } from 'ts-pattern';

type Confirm = (command: {
  title: string;
  subtitle: string;
  cancel: string;
  confirm: string;
  mode?: ComponentProps<typeof Button>['mode'];
  icon?: ComponentProps<typeof SquareIcon>['icon'];
  className?: string;
}) => Promise<boolean>;

type ConfirmProps = {
  id: string;
  resolve: () => void;
  reject: () => void;
  title: string;
  subtitle: string;
  cancel: string;
  confirm: string;
  className?: string;
  mode: ComponentProps<typeof Button>['mode'];
  icon: ComponentProps<typeof SquareIcon>['icon'];
};

const ConfirmationContext = createContext<Confirm | null>(null);

export const UseConfirmationProvider = ({
  children,
}: {
  children?: ReactNode;
}) => {
  const [confirmations, setConfirmations] = useState<ConfirmProps[]>([]);

  const createConfirmation = useCallback<Confirm>(
    ({
      title,
      subtitle,
      cancel,
      confirm,
      mode = 'destructive',
      icon = 'remove',
      className,
    }) => {
      const { promise, resolve } = withResolvers<boolean>();

      setConfirmations((xs) => {
        const id = crypto.randomUUID();

        return [
          ...xs,
          {
            id,
            title,
            subtitle,
            cancel,
            confirm,
            mode,
            icon,
            className,
            resolve: () => {
              resolve(true);
              setConfirmations((zs) => zs.filter((x) => x.id !== id));
            },
            reject: () => {
              resolve(false);
              setConfirmations((zs) => zs.filter((x) => x.id !== id));
            },
          },
        ];
      });

      return promise;
    },
    [],
  );

  return (
    <ConfirmationContext.Provider value={createConfirmation}>
      {children}
      {createPortal(
        <div
          style={{
            position: 'absolute',
            zIndex: 'var(--zindex-dropdown)',
          }}
        >
          {confirmations.map(
            ({
              id,
              resolve,
              reject,
              title,
              subtitle,
              cancel,
              confirm,
              mode,
              icon,
              className,
            }) => (
              <Modal
                key={id}
                className={className}
                onRequestClose={reject}
                header={
                  <Flex justifyContent="spaceBetween" alignItems="flexStart">
                    <SquareIcon
                      icon={icon}
                      color={match<
                        typeof mode,
                        ComponentProps<typeof SquareIcon>['color']
                      >(mode)
                        .with('destructive', () => 'red')
                        .otherwise(() => 'blue')}
                    />
                    <Button
                      variant="link"
                      mode="primary"
                      icon={<Icon name="close" />}
                      onClick={reject}
                    />
                  </Flex>
                }
                content={
                  <Flex flexDirection="column">
                    <Typography size="large" bold>
                      {title}
                    </Typography>
                    <Typography>{subtitle}</Typography>
                  </Flex>
                }
                footer={
                  <Flex flexDirection="row" gap="sm">
                    <Button variant="outline" mode="primary" onClick={reject}>
                      {cancel}
                    </Button>
                    <Button variant="solid" mode={mode} onClick={resolve}>
                      {confirm}
                    </Button>
                  </Flex>
                }
              />
            ),
          )}
        </div>,
        document.body,
      )}
    </ConfirmationContext.Provider>
  );
};

export class UseConfirmationError extends Error {
  constructor() {
    super('Confirmation should be used within a provider');
  }
}

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext);

  if (context === null) {
    throw new UseConfirmationError();
  }

  return context;
};
