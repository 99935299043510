import { Flex, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import type { ComponentPropsWithoutRef, ReactNode } from 'react';
import styles from './index.module.css';

export type SeparatorProps = ComponentPropsWithoutRef<'div'> & {
  className?: string;
  children?: ReactNode;
  direction?: 'horizontal' | 'vertical';
};

const Separator = ({
  className,
  children,
  direction = 'horizontal',
  ...props
}: SeparatorProps) => (
  <Flex
    {...props}
    as="span"
    alignItems="center"
    className={clsx(styles.separator, className, 'Separator-Root', {
      [styles.withContent]: Boolean(children),
      [styles.vertical]: direction === 'vertical',
      [styles.horizontal]: direction === 'horizontal',
      'Separator-Root-Vertical': direction === 'vertical',
      'Separator-Root-Horizontal': direction === 'horizontal',
    })}
    {...props}
  >
    {children ? (
      <Typography className={styles.text}>{children}</Typography>
    ) : null}
  </Flex>
);

export default Separator;
