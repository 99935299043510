import { Either } from '@nstrlabs/utils';

import jwt_decode from 'jwt-decode';
import { makeTenantId } from '../../tenants/domain/TenantId';
import type { AuthRepository } from '../domain/AuthRepository';
import { type Token, makeToken } from '../domain/Token';

const SESSION_STORAGE_KEY = 'AuthToken';

export class NoAuthTokenFoundError extends Error {
  constructor() {
    super('No auth token found');
  }
}

export const getToken: AuthRepository['getToken'] = async () => {
  const storedToken = sessionStorage.getItem(SESSION_STORAGE_KEY);

  if (typeof storedToken !== 'string') {
    // TODO: Should we reject with an error? The user is not logged in. We should swallow this error and redirect to login.
    return Promise.reject(new NoAuthTokenFoundError());
  }

  return Either.toPromise(makeToken(storedToken));
};

export const getTenant: AuthRepository['getTenant'] = async () => {
  const storedToken = sessionStorage.getItem(SESSION_STORAGE_KEY);
  if (typeof storedToken !== 'string') {
    // TODO: Should we reject with an error? The user is not logged in. We should swallow this error and redirect to login.

    return Promise.resolve(null);
  }
  const { TenantId } = jwt_decode<{ TenantId: string }>(storedToken);

  return Either.toPromise(makeTenantId(TenantId));
};

export const saveToken: AuthRepository['saveToken'] = async (
  token: Token,
): Promise<Token> => {
  sessionStorage.setItem(SESSION_STORAGE_KEY, token);
  return Promise.resolve(token);
};

export const removeToken: AuthRepository['removeToken'] =
  async (): Promise<void> => sessionStorage.removeItem(SESSION_STORAGE_KEY);
