import { type ColumnMenu, Icon, Typography } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

const ColumnHeaderActionMenu: ColumnMenu = ({
  column: { pinned, pinnable, sortable, sort },
  onPinChange,
  onSortChange,
}) => {
  const { t } = useTranslation();
  const sortAsc = () => invoke(onSortChange, 'asc');
  const sortDesc = () => invoke(onSortChange, 'desc');
  const togglePin = () => invoke(onPinChange, pinned ? null : 'left');

  const iconPinClassName = clsx(styles.icon, {
    [styles.active]: pinned === 'left' || pinned === 'right',
  });

  const iconAscClassName = clsx(styles.icon, {
    [styles.active]: sort === 'asc',
  });

  const iconDescClassName = clsx(styles.icon, {
    [styles.active]: sort === 'desc',
  });

  return (
    <ul className={styles.list}>
      {sortable ? (
        <>
          <li className={styles.item}>
            <button type="button" className={styles.button} onClick={sortAsc}>
              <span className={iconAscClassName}>
                <Icon name="arrowTop" />
              </span>
              <Typography className="headerText">
                {t('TABLE_HEADER.MENU.SORT_ASC')}
              </Typography>
            </button>
          </li>

          <li className={styles.item}>
            <button type="button" className={styles.button} onClick={sortDesc}>
              <span className={iconDescClassName}>
                <Icon name="arrowBottom" />
              </span>
              <Typography className="headerText">
                {t('TABLE_HEADER.MENU.SORT_DESC')}
              </Typography>
            </button>
          </li>
        </>
      ) : null}
      {sortable && pinnable ? (
        <li className={styles.item}>
          <hr />
        </li>
      ) : null}
      {pinnable ? (
        <li className={styles.item}>
          <button type="button" className={styles.button} onClick={togglePin}>
            <span className={iconPinClassName}>
              <Icon name="pin" />
            </span>
            <Typography>{t('TABLE_HEADER.MENU.PIN')}</Typography>
          </button>
        </li>
      ) : null}
    </ul>
  );
};

export default ColumnHeaderActionMenu;
