import { Skeleton } from '@nstrlabs/ixel';
import styles from './index.module.css';

export type SkeletonTableProps = React.HTMLAttributes<HTMLDivElement> & {
  rows?: number;
  columns?: number;
  className?: string;
};

const SkeletonTable = ({
  className,
  rows = 10,
  columns = 5,
}: SkeletonTableProps): JSX.Element => {
  const skeletonWidth = (index: number) => (index && index % 2 === 0 ? 40 : 80);

  return (
    <div className={`${className} ${styles.container}`}>
      <table className={styles.table}>
        <thead>
          <tr className={styles.header}>
            {Array(columns)
              .fill(null)
              .map((_, i) => (
                <th aria-label={`header${i}`} key={i}>
                  <Skeleton width={120} />
                </th>
              ))}
          </tr>
        </thead>
        <tbody>
          {Array(rows)
            .fill(null)
            .map((_, i) => (
              <tr key={i} className={styles.body}>
                {Array(columns)
                  .fill(null)
                  .map((__, index) => (
                    <td key={index} aria-label="loading">
                      <Skeleton width={skeletonWidth(index)} />
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default SkeletonTable;
