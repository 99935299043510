import { type Either, domainError } from '@nstrlabs/utils';
import { getEnvSync } from '../infrastructure/Environment';
import { stringValue } from './StringValue';

const UUID_REGEX =
  getEnvSync('NODE_ENV') === 'test'
    ? /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
    : /./m;
const regEx = new RegExp(UUID_REGEX);

export const invalidUuid = domainError<{ value: string }>()(
  'shared:uuid',
  'WEB-xxx',
  'Invalid UUID4',
);

export type InvalidUuid = ReturnType<typeof invalidUuid>;

export const uuidValue = <T extends string = string>(
  value: string,
): Either<InvalidUuid, T> =>
  stringValue<T>(value, { regEx }).mapLeft(() => invalidUuid({ value }));
