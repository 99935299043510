export type InputError = {
  err: string;
  value: string;
};
export type ValidationResult = InputError | null;
export type Validation = (value: string) => ValidationResult;
export type Validations = Validation[];

export function validate(validations: Validations, value: string) {
  return validations.reduce((totalErrors, validation) => {
    const validated = validation(value);

    if (validated !== null) totalErrors.push(validated);

    return totalErrors;
  }, [] as InputError[]);
}

function getErrorMessage(error: InputError) {
  return error.err;
}

export function hasErrors(errors: InputError[]): boolean {
  return errors.length > 0;
}

export function getFirstErrorMessage(errors: InputError[]): string {
  return hasErrors(errors) ? getErrorMessage(errors[0]) : '';
}

export function getAllErrorMessages(errors: InputError[]): string[] {
  return hasErrors(errors) ? errors.map(getErrorMessage) : [''];
}

const EMAIL_REGEX = /^\w+(?:[.\-+]\w+)*@\w+(?:[.-]\w+)*(?:\.\w{2,3})+$/;

type Validator = (value?: number | string, option?: number | string) => boolean;
export const VALIDATOR = {
  IS_VALID_STRING: (value?: string) => value != null && value.trim() !== '',
  IS_VALID_NUMBER: (value?: number) => value != null && !Number.isNaN(value),
  HAS_STRING_VALID_MAX_LENGTH: (maxLength: number) => (value: string) =>
    value.length <= maxLength,
  HAS_STRING_VALID_MIN_LENGTH: (minLength: number) => (value: string) =>
    minLength <= value.length,
  HAS_SOME_NUMBER: (value: string) => /\d/.test(value),
  HAS_SOME_LOWER_CASE: (value: string) => /[a-z]/.test(value),
  HAS_SOME_CAP: (value: string) => /[A-Z]/.test(value),
  HAS_SOME_SPECIAL_CHAR: (value: string) =>
    /[!@#$%^&*()_+{}[\]:;<>,.?~\\/|'"]/.test(value),
  IS_VALID_EMAIL: (value: string) => EMAIL_REGEX.test(value),
  HAS_MIN_LENGTH: (value: number, min: number) => value >= min,
  HAS_MAX_LENGTH: (value: number, max: number) => value <= max,
  HAS_VALID_REGEX_FORMAT: (value: string, regex: string) =>
    new RegExp(regex).test(value), // Flags not supported
};

type ValidationBuilder = (validator: Validator, err: string) => Validation;
export const validationBuilder: ValidationBuilder =
  (validator, err) => (value) =>
    validator(value) ? null : { err, value };
