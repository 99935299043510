import { LicenseManager } from '@ag-grid-enterprise/core';
import { setGlobalContext } from '@nstrlabs/sdk';
import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import bootstrap from './bootstrap';
import { init } from './context/queryBus';
import { windowEventBus } from './context/shared/infrastructure/WindowEventBus';
import logger from './context/shared/infrastructure/logger';
import './i18n';
import './index.css';
import Maintenance from './pages/Maintenance';

LicenseManager.setLicenseKey(process.env.VITE_AG_GRID_LICENSE_KEY ?? '');
declare global {
  interface Window {
    currentVersion: string;
    maintenanceMode: string;
    sentryEnabled: string;
  }
}
window.currentVersion = process.env.VITE_WAF_VERSION ?? '0.0.0';
window.maintenanceMode = process.env.VITE_MAINTENANCE_MODE ?? 'false';
window.sentryEnabled = process.env.VITE_SENTRY_ENABLED ?? 'false';
const isUnderMaintenance = () => window.maintenanceMode === 'true';
const isSentryEnabled = () => window.sentryEnabled === 'true';

Sentry.init({
  dsn: process.env.VITE_SENTRY,
  release: window.currentVersion,
  enabled: isSentryEnabled(),
  environment: process.env.VITE_SENTRY_ENVIRONMENT,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const element = document.createElement('div');
element.style.height = '100vh';
element.style.overflowY = 'hidden';
document.body.appendChild(element);

const eventBus = windowEventBus();

setGlobalContext(eventBus, logger);
init(eventBus);

bootstrap().then(() => {
  ReactDOM.createRoot(element as HTMLElement).render(
    <React.StrictMode>
      {isUnderMaintenance() ? <Maintenance /> : <App eventBus={eventBus} />}
    </React.StrictMode>,
  );
});
