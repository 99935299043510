import { callStack, useLogger } from '@nstrlabs/sdk';
import { useRouteError } from 'react-router-dom';
import GenericError from '../../pages/GenericError';

const DYNAMIC_IMPORT_ERROR_MESSAGE = 'dynamically imported module';

const ErrorView = () => {
  const logger = useLogger();
  const error = useRouteError();
  const { name, message } = error as { message: string; name: string };

  const handleReaload = () => {
    window.location.reload();
  };

  /**
   * Control dynamic import, reload page
   */
  if (
    (error as { message: string }).message
      .toLocaleLowerCase()
      .includes(DYNAMIC_IMPORT_ERROR_MESSAGE.toLocaleLowerCase())
  ) {
    logger.error('error', {
      module: 'Router',
      function: 'ErrorView',
      errorCode: 'WEB-00001',
      context: {
        trace: console.trace(),
        stack: callStack(),
        kind: 'dynamically imported module',
        code: 'WEB-00001',
        name,
        message,
      },
    });
    handleReaload();
    return null;
  }

  logger.error('error', {
    module: 'Router',
    function: 'ErrorView',
    errorCode: 'WEB-00000',
    context: {
      trace: console.trace(),
      stack: callStack(),
      kind: 'Uncontrolled error',
      code: 'WEB-00000',
      name,
      message,
    },
  });

  return (
    <GenericError
      resetErrorBoundary={() => {
        window.location.href = '/';
      }}
    />
  );
};

export default ErrorView;
