import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { SecretCreatorPrimitives } from '../../../context/settings/domain/Secret/SecretCreator';
import { useContainer } from '../../useContainer';

export function useCreateSecret() {
  const queryClient = useQueryClient();
  const container = useContainer();

  const mutation = useMutation({
    mutationFn: async (secretCreator: SecretCreatorPrimitives) =>
      container.call('organizationSettings:secret:createSecret', secretCreator),
    onSuccess: () => {
      // TODO change to optimistic update/create

      void queryClient.resetQueries({ queryKey: ['secrets'] });
      return null;
    },
  });

  return mutation;
}
