import { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';
import WithPermissions from '../../../hooks/controlAccess/WithPermissions';

const CreateEnrichment = lazy(
  () => import('../../../pages/Enrichments/CreateEnrichment'),
);
const EnrichmentEdit = lazy(
  () => import('../../../pages/Enrichments/EnrichmentEdit'),
);
const CreateNewVersion = lazy(
  () => import('../../../pages/Enrichments/CreateNewVersionEnrichment'),
);
const LookupVersion = lazy(
  () => import('../../../pages/Enrichments/EnrichmentVersion'),
);

export const enrichmentsNestedRoutes = (
  <>
    <Route
      path="create"
      element={
        <WithPermissions action="create" resource="datasink">
          <Suspense>
            <CreateEnrichment />
          </Suspense>
        </WithPermissions>
      }
    />
    <Route
      path=":lookupId/edit"
      element={
        <WithPermissions action="read" resource="datasink">
          <Suspense>
            <EnrichmentEdit />
          </Suspense>
        </WithPermissions>
      }
    >
      <Route
        path="createNewVersion"
        element={
          <WithPermissions action="create" resource="datasink">
            <Suspense>
              <CreateNewVersion />
            </Suspense>
          </WithPermissions>
        }
      />
      <Route
        path=":version/versionDetail"
        element={
          <WithPermissions action="read" resource="datasink">
            <Suspense>
              <LookupVersion />
            </Suspense>
          </WithPermissions>
        }
      />
    </Route>
  </>
);
