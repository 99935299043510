import clsx from 'clsx';
import type { HTMLAttributes, ReactNode } from 'react';
import { Children, useCallback, useMemo, useState } from 'react';

import {
  Avatar,
  Button,
  Dropdown,
  Skeleton,
  Tooltip,
  Typography,
} from '@nstrlabs/ixel';
import { useLogger } from '@nstrlabs/sdk';
import { useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import logout from '../../../context/auth/compose/logout';
import UserSettings, { type UserType } from '../UserSettings';

import GridIcon from './gridIcon';
import LogoIcon from './logoIcon';

import type { Tenant } from '../../../context/tenants/domain/Tenant';
import useGetTenantLogo from '../../../hooks/tenants/useGetTenantLogo';
import useTenants from '../../../hooks/tenants/useTenants';
import { useContainer } from '../../../hooks/useContainer';
import { useToast } from '../../../hooks/useToast';
import { useUser } from '../../../hooks/useUser';
import DropdownMenu from '../../atoms/DropdownMenu';
import TenantSelectorOverlay from '../TenantSelectorOverlay';
import OrganizationIcon from './OrganizationIcon';
import styles from './index.module.css';

export type SidebarProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
  user: UserType;
};

const Sidebar = ({ className, children, user }: SidebarProps) => {
  const logger = useLogger();
  const [open, setOpen] = useState(false);
  const { tenantsData, isLoading } = useTenants();

  const container = useContainer();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const toast = useToast();
  const navigate = useNavigate();
  const {
    TenantId,
    AccessMap: {
      Roles: [role],
    },
  } = useUser();

  const currentTenant = useMemo(
    () =>
      tenantsData.find((tenant) => tenant.id === TenantId) ||
      ({ name: '' } as Tenant),
    [tenantsData, TenantId],
  );
  const { tenantLogo, isLoadingTenantlogo } = useGetTenantLogo(
    currentTenant.name,
  );

  // @ts-ignore
  logger.tenantName = currentTenant.name;

  const numberOfChildren = Children.count(children);
  const hasChildren = numberOfChildren > 0;

  const toggleAppList = useCallback(() => {
    setOpen((prev) => !prev);
  }, []);

  document.documentElement.style.setProperty(
    '--sidebar-max-height',
    `calc(${numberOfChildren} * 32px)`,
  );

  const handleChangeTenant = (tenantId: Tenant['id']) => {
    const previousTenantId = TenantId;
    logger.info('sideBar', {
      module: 'auth:changeTenant',
      function: 'handleChangeTenant',
      context: { TenantId },
    });
    // call use case
    container
      .call('auth:changeTenant', tenantId)
      .then(() => {
        navigate('/');
        queryClient.removeQueries();
      })
      .catch(async () => {
        if (previousTenantId !== tenantId) {
          handleChangeTenant(previousTenantId as Tenant['id']);
          toast.publish({
            level: 'alert',
            content: t('AUTH.ERRORS.TENANT_CHANGE'),
          });
          logger.error('sideBar', {
            module: 'auth:changeTenant',
            function: 'handleChangeTenant',
            context: { previousTenantId },
          });
          return;
        }
        await logout();
      });
  };

  return (
    <aside className={clsx(className, styles.sidebar)}>
      <div className={styles.logo}>
        <LogoIcon />
      </div>
      <div className={styles.apps}>
        {isLoading || isLoadingTenantlogo ? (
          <Skeleton width={32} height={32} />
        ) : (
          tenantsData.length > 0 &&
          (tenantsData.length === 1 ? (
            <Tooltip
              content={
                <Typography bold>{`${currentTenant.name} tenant`}</Typography>
              }
              placement="right"
            >
              <OrganizationIcon
                tenantLogo={tenantLogo}
                tenantName={currentTenant.name}
              />
            </Tooltip>
          ) : (
            <Dropdown.Menu
              placement="right-end"
              trigger={
                <button
                  className={styles.buttonTenantSelector}
                  type="button"
                  aria-label="tenant-selector-button"
                  data-cy="tenant-selector-button"
                >
                  <OrganizationIcon
                    tenantLogo={tenantLogo}
                    tenantName={currentTenant.name}
                  />
                </button>
              }
            >
              {() => (
                <TenantSelectorOverlay
                  tenantLogo={tenantLogo}
                  userRole={role}
                  onClick={handleChangeTenant}
                  tenants={tenantsData}
                  currentTenant={currentTenant}
                />
              )}
            </Dropdown.Menu>
          ))
        )}
      </div>
      {hasChildren && (
        <div className={styles.apps}>
          <Button
            aria-label="Apps"
            className={styles.grid}
            icon={<GridIcon />}
            mode="primary"
            negative
            variant="solid"
            onClick={toggleAppList}
          />

          <ul className={clsx(styles.list, { [styles.open]: open })}>
            {Children.map(children, (child) => (
              <li>{child}</li>
            ))}
          </ul>
        </div>
      )}

      <div className={styles.avatar}>
        <DropdownMenu
          placement="right-end"
          trigger={<Avatar data-cy="user-logo" user={user} />}
        >
          {({ closeDropdown }) => (
            <UserSettings user={user} onClose={closeDropdown} />
          )}
        </DropdownMenu>
      </div>
    </aside>
  );
};

export default Sidebar;
