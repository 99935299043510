import type { DomainError } from '@nstrlabs/utils';

export const fetchConfiguration = {
  REFETCH_INTERVAL: 5000,
  REFETCH_INTERVAL_NO_DATA: 50000,
  REFETCH_INTERVAL_METRICS: 60000,
};

export const refetchIntervalWhenCode =
  (code: string) =>
  ({
    state: { fetchFailureReason },
  }: {
    state: { fetchFailureReason: DomainError | null };
  }) =>
    fetchFailureReason?.code === code
      ? false
      : fetchConfiguration.REFETCH_INTERVAL;

export const retryFetchWhenCode =
  (code: string) => (_failureCount: number, failureError: DomainError) =>
    failureError.code !== code;
