import { Flex, Icon, type IconName, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import FilterSelect from '../FilterSelector';
import FilterSelectorOption from '../FilterSelectorOption';
import { type Filter, useNewFilters } from '../FiltersProvider';
import styles from './index.module.css';

export type AddFilterProps = {
  className?: string;
  icon?: boolean;
};

const AddFilter = ({ className }: AddFilterProps) => {
  const { filtersToSelect, addFilterDispatcher } = useNewFilters();
  const { t } = useTranslation();

  const handleClickFilterItem = (
    filter: Record<string, string | IconName | undefined>,
  ) => {
    const filterSelectedToSave = filtersToSelect.find(
      ({ field }) => field === filter.field,
    );
    addFilterDispatcher(filterSelectedToSave as unknown as Filter);
  };
  const filtersToSelectNotSuggested = filtersToSelect
    .filter(({ isSuggested }) => !isSuggested)
    .map(({ field, label, iconName, type }) => ({
      field,
      label,
      iconName,
      type,
    }));

  return (
    <FilterSelect
      isOpen={false}
      trigger={
        <button
          type="button"
          className={clsx(styles.filter, className)}
          disabled={!filtersToSelectNotSuggested?.length}
          data-cy="add-filter-button"
        >
          <Flex
            className={styles.filterContent}
            alignContent="center"
            justifyContent="center"
            alignItems="center"
            gap="xxs"
          >
            <Icon name="plus" className={styles.icon} />
            <Typography bold className={styles.filterButtonText}>
              {t('BUTTONS.ADD_FILTER')}
            </Typography>
          </Flex>
        </button>
      }
      options={filtersToSelectNotSuggested}
      label={t('BUTTONS.ADD_FILTER')}
      renderOption={({ option: { field, iconName, label }, onClick }) => (
        <FilterSelectorOption
          key={field}
          element={label ?? ''}
          onClick={onClick}
          iconName={iconName as IconName}
        />
      )}
      onClick={handleClickFilterItem}
    />
  );
};

export default AddFilter;
