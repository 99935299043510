import { Checkbox } from '@nstrlabs/ixel';

type FilterCheckItemProps = {
  dataTestId?: string;
  keyId?: string;
  field: Record<string, string>;
  handleClickCheckbox: (
    checked: boolean,
    field: Record<string, string>,
  ) => void;
  fieldsSelected: Record<string, boolean>;
  renderCheckboxText: React.ReactElement;
};

const FilterCheckItem = ({
  dataTestId = 'filter-categories-id',
  field,
  keyId = 'id',
  handleClickCheckbox,
  fieldsSelected,
  renderCheckboxText,
}: FilterCheckItemProps) => {
  return (
    <>
      <Checkbox
        key={`${dataTestId}-${field[keyId]}`}
        id={`group-${field[keyId]}`}
        checked={fieldsSelected[field[keyId]]}
        onChange={(e) => {
          handleClickCheckbox(e.target.checked, field);
        }}
      />
      {renderCheckboxText}
    </>
  );
};

export default FilterCheckItem;
