import clsx from 'clsx';

import Dialog, { type DialogSize } from '@nstrlabs/ixel/dist/molecules/Dialog';
import type { SquareIconProps } from '@nstrlabs/ixel/dist/molecules/SquareIcon';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

export type ConfirmDialogProps = {
  open: boolean;
  title: string;
  subtitle: string;
  headerIcon: SquareIconProps['icon'];
  headerIconColor: SquareIconProps['color'];
  footerActions?: React.ReactNode;
  content?: React.ReactNode;
  className?: string;
  size?: DialogSize;
  onClose: () => void;
};

const ConfirmDialog = ({
  open,
  title,
  subtitle,
  headerIcon,
  headerIconColor,
  footerActions,
  content,
  className,
  size = 'large',
  onClose,
}: ConfirmDialogProps) => {
  const { t } = useTranslation();
  return createPortal(
    <Dialog
      data-cy="confirm-dialog"
      className={clsx(styles.dialog, className, 'ConfirmDialog-Root')}
      open={open}
      onClose={onClose}
      size={size}
    >
      <Dialog.Header
        title={title}
        subtitle={subtitle}
        headerIcon={headerIcon}
        headerIconColor={headerIconColor}
        onClose={onClose}
        border={Boolean(content)}
      />

      {content ? <Dialog.Body content={content} /> : null}

      <Dialog.Footer
        onClose={onClose}
        footerActions={footerActions}
        cancelText={t('MODAL.ACTIONS.CANCEL')}
      />
    </Dialog>,
    document.body,
  );
};

export default ConfirmDialog;
