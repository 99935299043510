import { Flex, Toggle, Tooltip, Typography } from '@nstrlabs/ixel';
import { isPastDay } from '@nstrlabs/utils';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import TagsIndicator from '../../molecules/TagsIndicator';
import ActionMenu from './ActionMenu';
import styles from './index.module.css';

const TIME_ZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export type SecretListItemProps = {
  name: string;
  id: string;
  description: string;
  expirationDate: string;
  tags?: string[];
  status: string;
};

const SecretListItem = ({
  name,
  id,
  description,
  expirationDate,
  tags,
  status,
}: SecretListItemProps) => {
  const { t } = useTranslation();
  return (
    <Flex
      justifyContent="spaceBetween"
      alignItems="center"
      className={styles.container}
      data-cy={`secret-list-item-${name}`}
    >
      <Flex
        justifyContent="flexStart"
        gap="md"
        alignItems="flexStart"
        className={styles.titleContainer}
      >
        {/* TODO include this toggle when disable functionality will be available */}
        <Flex style={{ display: 'none' }}>
          <Toggle disabled checked={status === 'ACTIVE'} onChange={() => {}} />
        </Flex>
        <Flex
          flexDirection="column"
          className={styles.sectionNameDescription}
          gap="xxs"
        >
          <Tooltip
            content={<Typography monospace>{name}</Typography>}
            placement="bottom-start"
          >
            <Typography variant="body" bold className={styles.overflowText}>
              {name}
            </Typography>
          </Tooltip>
          <Tooltip
            content={<Typography monospace>{description}</Typography>}
            placement="bottom-start"
          >
            <Typography
              className={styles.overflowText}
              variant="body"
              color="tertiary"
            >
              {description}
            </Typography>
          </Tooltip>
        </Flex>
      </Flex>
      <Flex justifyContent="flexEnd" alignItems="center" gap="xl">
        <Flex
          flexDirection="column"
          className={styles.sectionExpirationDate}
          alignItems="flexEnd"
          gap="xxs"
        >
          <Typography variant="body" color="tertiary">
            {t('SETTINGS.ORGANIZATION_SETTINGS.SECRET_DETAIL.EXPIRATION_DATE')}
          </Typography>
          {expirationDate ? (
            <Typography
              variant="body"
              className={clsx({
                [styles.dateExpired]: isPastDay(
                  new Date(expirationDate),
                  TIME_ZONE,
                ),
              })}
            >
              {format(new Date(expirationDate), 'LLL dd, yyyy')}
            </Typography>
          ) : (
            <Typography variant="body" color="tertiary">
              -
            </Typography>
          )}
        </Flex>
        <Flex
          alignItems="center"
          gap="sm"
          className={styles.sectionTagsAndMenu}
        >
          <Flex justifyContent="center" className={styles.tagContainer}>
            {tags?.length ? (
              <TagsIndicator tags={tags} buttonSize="small" />
            ) : null}
          </Flex>
          <ActionMenu secretName={name} secretId={id} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default SecretListItem;
