import { useCanAccess } from '@nstrlabs/sdk';
import { BaseError } from '@nstrlabs/utils';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import logger from '../../context/shared/infrastructure/logger';
import { useToast } from '../useToast';

const generateNoPermissionsError = ({
  roles,
  resource,
}: {
  roles: string[];
  resource: string;
}) => {
  const message = `${roles.join(
    ', ',
  )} doesn't have access permissions in ${resource}`;
  return new BaseError({ message, code: 'WEB-00027' });
};

type WithPermissionsProps = {
  resource: string;
  action?: string;
  children: React.ReactNode;
};

const WithPermissions = ({
  resource,
  action = 'read',
  children,
}: WithPermissionsProps) => {
  const { canAccess, roles } = useCanAccess();
  const { t } = useTranslation();
  const toast = useToast();
  const hasPermission = canAccess({ action, resource });

  if (!hasPermission) {
    toast.publish({
      level: 'alert',
      content: t('WEB-00027'),
      id: 'toast-test',
    });
    generateNoPermissionsError({ roles, resource });
    logger.info("Does't have permission to view page", {
      module: 'WithPermissions',
      function: 'if hasNotPermission',
      context: { roles, resource },
    });
    return <Navigate to="/" />;
  }

  /** We need to add the fragment in this case to not
   * cause "cannot be used as a JSX component" error
   */

  return <>{children}</>;
};

export default WithPermissions;
