import { useLogger } from '@nstrlabs/sdk';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import type { ContainerUseCase } from '../../context';
import { useContainer } from '../useContainer';
import { useToast } from '../useToast';
import { useUser } from '../useUser';

export type User = ContainerUseCase<'settings:getUsers', 'result'>[number];

export const useUsers = () => {
  const toastService = useToast();
  const logger = useLogger();
  const { t } = useTranslation();
  const container = useContainer();
  const queryClient = useQueryClient();
  const user = useUser();

  const { data: users = [], isLoading } = useQuery({
    queryKey: ['users'],
    queryFn: () => container.call('settings:getUsers'),
  });

  const { mutate: deleteUsers } = useMutation({
    mutationFn: async (
      userIds: ContainerUseCase<'settings:deleteUsers', 'command'>['userIds'],
    ) =>
      container.call('settings:deleteUsers', {
        userIds,
        tenantId: user.TenantId,
      }),
    onSuccess: (_, userIds) => {
      logger.info('useUsers', {
        module: 'settings:deleteUsers',
        function: 'deleteUsers',
        context: { userIds },
      });
      toastService.publish({
        content: t('SETTINGS.TENANT.USERS.DELETED_USERS', {
          count: userIds.length,
        }),
      });
      queryClient.setQueryData(['users'], (prev: User[]) =>
        prev?.filter((u) => !userIds.includes(u.id)),
      );
    },
    onError: (_, userIds) => {
      logger.error('useUsers', {
        module: 'settings:deleteUsers',
        function: 'deleteUsers',
        context: { userIds },
      });
      toastService.publish({
        level: 'alert',
        content: t('SETTINGS.TENANT.USERS.ERROR_DELETE_USERS'),
      });
    },
  });

  const { mutate: updateRoles } = useMutation({
    mutationFn: async (
      command: ContainerUseCase<'settings:updateRoles', 'command'>,
    ) => container.call('settings:updateRoles', command),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    onSuccess: (_, command) => {
      logger.info('useUsers', {
        module: 'settings:updateRoles',
        function: 'updateRoles',
        context: { command },
      });
      toastService.publish({
        content: t('SETTINGS.TENANT.USERS.UPDATE_ROLE', {
          count: command.userIds.length,
        }),
      });
    },
    onError: (_, command) => {
      logger.error('useUsers', {
        module: 'settings:updateRoles',
        function: 'updateRoles',
        context: { command },
      });

      toastService.publish({
        level: 'alert',
        content: t('SETTINGS.TENANT.USERS.ERROR_UPDATE_ROLE'),
      });
    },
  });

  const { mutate: inviteUsers } = useMutation({
    mutationFn: async (
      command: ContainerUseCase<'settings:inviteUsers', 'command'>,
    ) => container.call('settings:inviteUsers', command),
    onSettled: () => queryClient.invalidateQueries({ queryKey: ['users'] }),
    onSuccess: (_, command) => {
      logger.info('useUsers', {
        module: 'settings:inviteUsers',
        function: 'inviteUsers',
        context: { command },
      });
      toastService.publish({
        content: t('SETTINGS.TENANT.USERS.SEND_INVITATIONS', {
          count: command.users.length,
        }),
      });
    },
    onError: (_, command) => {
      logger.error('useUsers', {
        module: 'settings:inviteUsers',
        function: 'inviteUsers',
        context: { command },
      });
      toastService.publish({
        level: 'alert',
        content: t('SETTINGS.TENANT.USERS.ERROR_SEND_INVITATIONS'),
      });
    },
  });

  const { mutate: reinviteUsers } = useMutation({
    mutationFn: async (
      ids: ContainerUseCase<'auth:resendInviteUsers', 'command'>['ids'],
    ) => container.call('auth:resendInviteUsers', { ids }),
    onSuccess: (_, ids) => {
      logger.info('useUsers', {
        module: 'auth:resendInviteUsers',
        function: 'reinviteUsers',
        context: { ids },
      });
      toastService.publish({
        content: t('SETTINGS.TENANT.USERS.SEND_INVITATIONS', {
          count: ids.length,
        }),
      });
    },
    onError: (_, ids) => {
      logger.error('useUsers', {
        module: 'auth:resendInviteUsers',
        function: 'reinviteUsers',
        context: { ids },
      });
      toastService.publish({
        level: 'alert',
        content: t('SETTINGS.TENANT.USERS.ERROR_SEND_INVITATIONS'),
      });
    },
  });

  const { data: roles = [] } = useQuery({
    queryKey: ['roles'],
    queryFn: () => container.call('settings:getRoles'),
  });

  return {
    users,
    deleteUsers,
    updateRoles,
    inviteUsers,
    reinviteUsers,
    roles,
    tenantId: user.TenantId,
    isLoading,
  };
};
