import {
  type EventBus,
  EventBusProvider,
  LoggerProvider,
  UseAccessControlProvider,
} from '@nstrlabs/sdk';
import { eventEmitter } from '@nstrlabs/utils';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { getAnalytics } from 'firebase/analytics';
import { getPerformance } from 'firebase/performance';
import jwtDecode from 'jwt-decode';
import { type FC, useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import CustomErrorBoundary from '../components/CustomErrorBoundary';
import Loading from '../components/atoms/Loading';
import UserSettingsObserver from '../components/atoms/UserSettingsObserver';
import type { ToastCommand } from '../components/providers/ToastRoot/parseToastCommand';
import ToastLive from '../components/providers/ToastRoot/toastLive';
import ToastStacked from '../components/providers/ToastRoot/toastStacked';
import { container } from '../context';
import logger from '../context/shared/infrastructure/logger';
import useLoginOnIdentityProviderTokenChange from '../hooks/auth/useLoginOnIdentityProviderTokenChange';
import { FeatureFlagsProvider } from '../hooks/featureFlags/useFeatureFlags';
import { ConsoleSettingsProvider } from '../hooks/settings/useConsoleSettings';
import { UserSettingsProvider } from '../hooks/settings/useUserSettings';
import { UseConfirmationProvider } from '../hooks/useConfirmation';
import { ContainerProvider } from '../hooks/useContainer';
import { UseToastProvider } from '../hooks/useToast';
import {
  UseUserProvider,
  type User,
  mergeUsers,
  permissionsAreEqual,
} from '../hooks/useUser';
import { firebaseApp } from '../utils/firebase';
import { router } from './Router';

logger.project = 'console';
const queryClient = new QueryClient({});
const source = eventEmitter<ToastCommand>();
const moduleName = 'App';
const LOGGER_KEEP_ALIVE_TIME = parseInt(
  process.env.VITE_LOGGER_KEEP_ALIVE_TIME ?? '30000',
  10,
);

const logRouterChange = ({
  hash,
  pathname,
  search,
}: {
  hash: string;
  pathname: string;
  search: string;
}) => {
  logger.url = pathname;
  logger.appVersion = window.currentVersion;
  logger.info('router state change', {
    module: moduleName,
    function: 'logRouterChange',
    context: { search, hash },
  });
};

const logKeepAlive = () =>
  logger.info('keep-alive', { module: moduleName, function: 'logKeepAlive' });

const App: FC<{ eventBus: EventBus }> = ({ eventBus }) => {
  // IMPORTANT -> "null" is a valid state, means user is not logged in, undefined is the initial value needed for the first render
  const [token, setToken] = useState<string | null | undefined>(undefined);
  const [user, setUser] = useState<User | null>(null);
  const [addIdentityProviderTokenChangeListener, removeListeners] =
    useLoginOnIdentityProviderTokenChange({
      onTokenChange: (authToken) => {
        setToken(authToken);
        const newUser = authToken ? jwtDecode<User>(authToken) : null;
        setUser((prevUser) => {
          if (newUser === null) {
            queryClient.getQueryCache().clear();
            return null;
          }

          if (prevUser === null) return newUser;

          if (!permissionsAreEqual(prevUser.AccessMap, newUser.AccessMap))
            queryClient.getQueryCache().clear();

          return mergeUsers(prevUser, newUser);
        });
      },
      onError: (error) => {
        source.publish({
          level: 'alert',
          content: error.message,
        });
        logger.debug('Unknown error', {
          module: moduleName,
          function: 'useLoginOnIdentityProviderTokenChange',
          context: {
            message: error.message,
            name: error.name,
          },
        });
        queryClient.getQueryCache().clear();
        setToken(null);
        setUser(null);
      },
      onUnauthorized: (error) => {
        source.publish({
          level: 'alert',
          content: error.message,
        });
        logger.debug('User unauthorized', {
          module: moduleName,
          function: 'useLoginOnIdentityProviderTokenChange',
          context: error,
        });
        queryClient.getQueryCache().clear();
        setToken(null);
        setUser(null);
      },
    });

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  useEffect(() => {
    // Init Firebase Performance (https://firebase.google.com/docs/perf-mon/get-started-web)
    getPerformance(firebaseApp);
    // Init Firebase Analytics (https://firebase.google.com/docs/analytics)
    getAnalytics(firebaseApp);

    // Send log on routes change
    logRouterChange(router.state.location);
    const unsubscribeRouteLogger = router.subscribe((state) =>
      logRouterChange(state.location),
    );

    // Send keep alive log
    logKeepAlive();
    const intervalId = setInterval(logKeepAlive, LOGGER_KEEP_ALIVE_TIME);

    /**
     * Adds an observer for changes to the signed-in user's ID token, which includes sign-in, sign-out, and token refresh events
     */
    addIdentityProviderTokenChangeListener();

    container.call('auth:logoutOnIdle');

    return () => {
      clearInterval(intervalId);
      unsubscribeRouteLogger();
      removeListeners();
    };
  }, []);

  if (token === undefined)
    return (
      <ContainerProvider value={container}>
        <UserSettingsProvider>
          <Loading />
        </UserSettingsProvider>
      </ContainerProvider>
    );

  return (
    <CustomErrorBoundary logger={logger}>
      <LoggerProvider value={logger}>
        <EventBusProvider value={eventBus}>
          <ContainerProvider value={container}>
            <QueryClientProvider client={queryClient}>
              <UseToastProvider value={source}>
                <UseConfirmationProvider>
                  <UserSettingsProvider>
                    <ConsoleSettingsProvider>
                      <UseUserProvider value={user}>
                        <FeatureFlagsProvider>
                          <UserSettingsObserver>
                            <UseAccessControlProvider value={token}>
                              <RouterProvider router={router} />
                              <ToastLive source={source} />
                              <ToastStacked source={source} />
                            </UseAccessControlProvider>
                          </UserSettingsObserver>
                        </FeatureFlagsProvider>
                      </UseUserProvider>
                    </ConsoleSettingsProvider>
                  </UserSettingsProvider>
                </UseConfirmationProvider>
              </UseToastProvider>
              {!process.env.E2E_TESTS && (
                <ReactQueryDevtools initialIsOpen={false} position="bottom" />
              )}
            </QueryClientProvider>
          </ContainerProvider>
        </EventBusProvider>
      </LoggerProvider>
    </CustomErrorBoundary>
  );
};

export default App;
