import { Button, Flex, Scroll, Typography } from '@nstrlabs/ixel';
import { useTranslation } from 'react-i18next';

import { invoke } from '@nstrlabs/utils';
import type { PipelineId } from '../../../../context/pipelines/domain/PipelineId';
import { useUsages } from '../../../../hooks/organizationSettings/secrets/useSecretUsages';
import ConfirmDialog from '../../../molecules/ConfirmDialog';
import { DatasinkListComponent } from './DatasinkList';
import { ListenerListComponent } from './ListenerList';
import { PipelineListComponent } from './PipelineList';
import styles from './index.module.css';

export const handleOpenItem = (itemPath: string) => {
  window.open(itemPath, '_blank');
};

export type ConfirmSecretActionProps = {
  open: boolean;
  secretName: string;
  secretId: string;
  isLoading?: boolean;
  requestPending?: boolean;
  onClose: () => void;
  onRemove: (pipelineIds: PipelineId[]) => void;
};

const ConfirmSecretAction = ({
  open,
  secretName,
  secretId,
  onClose,
  onRemove,
}: ConfirmSecretActionProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix:
      'SETTINGS.ORGANIZATION_SETTINGS.SECRET_DETAIL.REMOVE_CONFIRMATION',
  });

  const { isLoading, usages } = useUsages(secretId, true);
  const { pipelines, listeners, datasinks, totalUsages } = usages;

  const content =
    totalUsages > 0 ? (
      <Scroll paddingX="xl" paddingY="sm">
        <Flex flexDirection="column" gap="md">
          <Typography color="secondary">{t('LIST_TITLE')}</Typography>
          {pipelines.length > 0 ? (
            <PipelineListComponent pipelines={pipelines} />
          ) : null}
          {listeners.length > 0 ? (
            <ListenerListComponent listeners={listeners} />
          ) : null}
          {datasinks.length > 0 ? (
            <DatasinkListComponent datasinks={datasinks} />
          ) : null}
        </Flex>
      </Scroll>
    ) : null;

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      title={t('TITLE', {
        secretName,
      })}
      subtitle={totalUsages > 0 ? t('SUBTITLE') : t('SUBTITLE_NO_USAGES')}
      headerIcon="remove"
      headerIconColor="deepRed"
      className={styles.confirmDialog}
      content={content}
      footerActions={
        <Button
          data-cy="delete-secret-button"
          mode="destructive"
          aria-label="dialog-confirm"
          variant="solid"
          disabled={isLoading || totalUsages > 0}
          onClick={(event) => {
            event.stopPropagation();
            invoke(onRemove, []);
          }}
        >
          {t('CONFIRM_BUTTON')}
        </Button>
      }
    />
  );
};

export default ConfirmSecretAction;
