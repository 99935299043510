import type { ToastProps } from '@nstrlabs/ixel';
import { check } from '@nstrlabs/utils';
import type React from 'react';

export type ToastCommand = Omit<ToastProps, 'children' | 'action'> & {
  content?: string;
  channel?: 'live' | 'stacking';
  action?: Exclude<ToastProps['action'], React.ReactNode>;
  time?: number;
  id?: string;
  customRender?: React.ReactNode;
};

class InvalidToastCommandError extends Error {
  constructor() {
    super('Invalid toast command.');
  }
}

export const parseToastCommand = (rawToast: ToastCommand): ToastCommand => {
  if (typeof rawToast !== 'object' || rawToast === null) {
    throw new InvalidToastCommandError();
  }

  return {
    id: check(rawToast, 'id', 'string') ? rawToast.id : crypto.randomUUID(),
    channel: check(rawToast, 'channel', 'string')
      ? rawToast.channel
      : 'stacking',
    level:
      check(rawToast, 'level', 'string') &&
      ['default', 'alert'].includes(rawToast.level)
        ? rawToast.level
        : undefined,
    content: check(rawToast, 'content', 'string') ? rawToast.content : '',
    className: check(rawToast, 'className', 'string')
      ? rawToast.className
      : undefined,
    onClose: check(rawToast, 'onClose', 'function')
      ? (rawToast.onClose as () => void)
      : undefined,
    action:
      check(rawToast, 'action', 'object') &&
      check(rawToast.action, 'command', 'function') &&
      check(rawToast.action, 'text', 'string')
        ? (rawToast.action as Exclude<ToastCommand['action'], undefined>)
        : undefined,
    time: check(rawToast, 'time', 'number') ? rawToast.time : undefined,
    customRender: rawToast.customRender,
  };
};
