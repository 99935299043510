import { useMutation, useQueryClient } from '@tanstack/react-query';
import type { DeleteSecretCommand } from '../../../context/secrets/domain/SecretsRepository';
import logger from '../../../context/shared/infrastructure/logger';
import { useContainer } from '../../useContainer';

import { useTranslation } from 'react-i18next';
import type { ContainerUseCase } from '../../../context';
import { useToast } from '../../useToast';

type Secrets = ContainerUseCase<
  'organizationSettings:secret:getSecrets',
  'result'
>;

export function useDeleteSecret(showSuccess = true) {
  const queryClient = useQueryClient();
  const container = useContainer();
  const { t } = useTranslation();
  const toast = useToast();

  const mutation = useMutation({
    mutationKey: ['deleteSecret'],
    mutationFn: (command: DeleteSecretCommand) =>
      container.call('secrets:deleteSecret', command),
    onSuccess: (_, command) => {
      logger.info('secrets', {
        module: 'secrets:delete',
        function: 'useDeleteSecret',
        context: { id: command.id },
      });

      if (showSuccess) {
        toast.publish({
          level: 'default',
          content: t(
            'SETTINGS.ORGANIZATION_SETTINGS.SECRET_DETAIL.REMOVE_CONFIRMATION.SUCCESS_MESSAGE',
          ),
        });
      }

      queryClient.setQueryData(['secrets'], (prev: Secrets) =>
        prev?.filter((secret: { id: string }) => secret.id !== command.id),
      );
    },
    onError: (_, context) => {
      logger.error('useDeleteSecret', {
        module: 'secrets:delete',
        function: 'useDeleteSecret',
        context,
      });
      toast.publish({
        level: 'alert',
        content: t(
          'SETTINGS.ORGANIZATION_SETTINGS.SECRET_DETAIL.REMOVE_CONFIRMATION.ERROR_MESSAGE',
        ),
      });
    },
  });

  return mutation;
}
