import { Navigate, useLocation, useOutlet } from 'react-router-dom';
import { ofLocation } from '../../../hooks/useLocationFrom';
import { useUser } from '../../../hooks/useUser';
import { useCurrentUser } from '../../../hooks/users/useCurrentUser';

const AuthLayout = () => {
  const user = useUser();
  const location = useLocation();
  const outlet = useOutlet();
  const fromLocation = ofLocation(location);
  useCurrentUser();

  if (!user.isLoggedIn) {
    // Redirect them to the login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/" {...fromLocation} replace />;
  }

  return outlet;
};

export default AuthLayout;
