import { Accordion, Flex, Icon, Typography } from '@nstrlabs/ixel';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import type {
  MenuOption,
  TypeMenuSettings,
} from '../../../pages/Settings/useSettingsOptions';
import styles from './index.module.css';

type MenuSettingsProps = {
  menuSettings: TypeMenuSettings;
  activeMenuOption: {
    section: string | null;
    selectedMenuOption: string;
  };
  onChange: (section: string, menuOption: string) => void;
};
const MenuSettings = ({
  menuSettings,
  activeMenuOption,
  onChange,
}: MenuSettingsProps) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.keys(menuSettings).map((key) => {
        const MENU_OPTIONS = menuSettings[key].menu_options;
        const iconName = menuSettings[key].icon;
        const sectionLabel = menuSettings[key].label;
        if (!MENU_OPTIONS.length) return null;
        return (
          <Accordion
            key={`accordion_${key}`}
            disabled
            header={
              <Flex key={`accordion_flex_${key}`} gap="xs">
                <Icon key={`icon_${key}`} name={iconName} />{' '}
                <Typography variant="body" bold>
                  {t(sectionLabel)}
                </Typography>
              </Flex>
            }
            display="row"
            className={styles.accordion}
            hideCount
          >
            <Flex
              key={`menu_flex_${key}`}
              as="ul"
              flexDirection="column"
              className={styles.nav}
              gap="xxs"
            >
              {MENU_OPTIONS.map(({ value, label }: MenuOption) => (
                <li
                  className={clsx({
                    [styles.activeMenuOption]:
                      activeMenuOption.section === key &&
                      activeMenuOption.selectedMenuOption === value,
                  })}
                  key={`li_${value}_${key}`}
                  onClick={() => {
                    onChange(key, value);
                  }}
                >
                  <Typography variant="body" className={styles.viewSpan}>
                    {t(label)}
                  </Typography>
                </li>
              ))}
            </Flex>
          </Accordion>
        );
      })}
    </>
  );
};

export default MenuSettings;
