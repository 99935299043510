import { Either, type Value, domainError } from '@nstrlabs/utils';

export type ActivityTimestamp = Value<number | null, 'auth_activity_timestamp'>;

const invalidActivityTimestamp = domainError<{
  activityTimestamp: number | null;
}>()(
  'invalid:activityTimestamp',
  'WEB-00042',
  'Activity timestamp is invalid.',
);

export type InvalidActivityTimestamp = ReturnType<
  typeof invalidActivityTimestamp
>;

export const makeActivityTimestamp = (
  value: number | null,
): Either<InvalidActivityTimestamp, ActivityTimestamp | null> => {
  if (value === null) return Either.of(value);

  if (Number.isNaN(value) || !Number.isFinite(value)) {
    return Either.left(invalidActivityTimestamp({ activityTimestamp: value }));
  }

  return Either.of(value as ActivityTimestamp);
};
