import { useCanAccess } from '@nstrlabs/sdk';

export type ResourceOption = {
  value: string;
  resource: string;
  label: string;
  defaultOption: string;
};

const REQUIRED_READ_PERMISSIONS = ['activity'];

/**
 * The definition of the menu with the specified resource to have access to.
 * If no resource defined, the option menu have to be created.
 */
export const resourceOptions: ResourceOption[] = [
  {
    value: 'tenant',
    label: 'SETTINGS.PROFILE_MENU_SETTINGS.INVITE_USERS_TEAM_MEMBERS',
    resource: '',
    defaultOption: 'control_access_users',
  },
  {
    value: 'your_preferences',
    label: 'SETTINGS.PROFILE_MENU_SETTINGS.YOUR_PREFERENCES',
    resource: '',
    defaultOption: 'email_password',
  },
  {
    value: 'organization_settings',
    label: 'SETTINGS.PROFILE_MENU_SETTINGS.ORGANIZATION_SETTINGS',
    resource: 'secret',
    defaultOption: 'secrets_management',
  },
  {
    value: 'tenant',
    label: 'SETTINGS.PROFILE_MENU_SETTINGS.ACTIVITY',
    resource: 'activity',
    defaultOption: 'activity_log',
  },
];

/**
 * Extracted functionality to handle the testing purposes
 * Return the list of all main menu options available for the current user with the current
 * role and access settings.
 * @param canAccess hook to know whether the user can access to the action-resource
 * @returns list of all menu options available for the current user
 */
export const buildAccessOptions = (
  availableOptions: ResourceOption[],
  canAccess: (accessObj: { action: string; resource: string }) => boolean,
) =>
  availableOptions.filter(
    (option) =>
      !option.resource ||
      (REQUIRED_READ_PERMISSIONS.includes(option.resource) &&
        canAccess({ action: 'read', resource: option.resource })) ||
      canAccess({ action: 'update', resource: option.resource }),
  );

/**
 * Returns the list of menu options for the current user checking
 * the allowed resources of the current user
 * */
export const useMenuSettingsOptions = () => {
  const { canAccess } = useCanAccess();
  return buildAccessOptions(resourceOptions, canAccess);
};
