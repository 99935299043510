import { SegmentedControl } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import { useCallback, useEffect } from 'react';
import { useUserSettings } from '../../../../hooks/settings/useUserSettings';

type Density = 'comfortable' | 'compact';
export type TableDensityProps = {
  onChange?: (density: Density) => void;
};

const TableDensity = ({ onChange }: TableDensityProps) => {
  const { settings, saveSettings } = useUserSettings();

  const onChangeCheckboxGroupHandler = useCallback(
    (value: Density) => {
      void saveSettings({
        ...settings,
        tableDensity: value,
      });

      invoke(onChange, value);
    },
    [settings, saveSettings, onChange],
  );

  useEffect(() => {
    invoke(onChange, settings.tableDensity);
  }, [settings.tableDensity, onChange]);

  return (
    <SegmentedControl
      active={settings.tableDensity}
      variant="icon"
      onChange={onChangeCheckboxGroupHandler}
    >
      <SegmentedControl.Item
        aria-label="Select comfortable density"
        icon="comfortable"
        name="comfortable"
      />
      <SegmentedControl.Item
        aria-label="Select compact density"
        name="compact"
        icon="compact"
      />
    </SegmentedControl>
  );
};

export default TableDensity;
