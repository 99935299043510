import {
  Button,
  Dropdown,
  Flex,
  Icon,
  OverlayLayout,
  Tag,
  TooltipAction,
  Typography,
} from '@nstrlabs/ixel';
import { debounce, invoke, normalizeIncludes } from '@nstrlabs/utils';
import { type MouseEvent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ContainerUseCase } from '../../../context';
import { getEnvSync } from '../../../context/shared/infrastructure/Environment';
import { useUpdateMainTenant } from '../../../hooks/tenants/useUpdateMainTenant';
import { useToast } from '../../../hooks/useToast';
import { useCurrentUser } from '../../../hooks/users/useCurrentUser';
import OrganizationIcon from '../Sidebar/OrganizationIcon';
import styles from './index.module.css';

type Tenant = ContainerUseCase<'tenants:getAll', 'result'>[number];

export type TenantSelectorOverlayProps = {
  tenants: Tenant[];
  currentTenant: Tenant;
  userRole: string;
  onClick: (tenantId: Tenant['id']) => void;
  tenantLogo?: string;
};

const TenantSelectorOverlay = ({
  tenants,
  currentTenant,
  userRole,
  onClick,
  tenantLogo,
}: TenantSelectorOverlayProps) => {
  const { user } = useCurrentUser();
  const isTenantLoading = useRef<boolean>(false);
  const toast = useToast();
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState('');
  const { update, isPending } = useUpdateMainTenant();

  const { name } = currentTenant;

  const userTenants =
    user?.tenants.reduce(
      (acc, t) => ({ ...acc, [t.id]: t }),
      {} as Record<string, (typeof user.tenants)[number] | undefined>,
    ) ?? {};

  const filteredTenantList = useMemo(
    () =>
      tenants.filter(
        (tenant) =>
          (searchTerm === '' && tenant.id !== currentTenant.id) ||
          (tenant.id !== currentTenant.id &&
            normalizeIncludes(tenant.name, searchTerm)),
      ),
    [currentTenant.id, searchTerm, tenants],
  );

  const handleTenantClick = (tenantId: Tenant['id']) => () => {
    if (!isTenantLoading.current) {
      isTenantLoading.current = true;
      invoke(onClick, tenantId);
    }
  };

  const handleSearch = debounce(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      setSearchTerm(value ?? '');
    },
    200,
  );

  const handleUpdateTenant = (
    event: MouseEvent,
    id: string,
    close: () => void,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    update(id, {
      onError() {
        toast.publish({
          level: 'alert',
          content: t('TENANT_SELECTOR.ERROR_UPDATE_DEFAULT'),
        });
      },
    });
    close();
  };

  const handleCopyTenant = (
    event: MouseEvent,
    id: string,
    close: () => void,
  ) => {
    event.stopPropagation();
    event.preventDefault();
    navigator.clipboard.writeText(id);
    toast.publish({
      customRender: (
        <Flex gap="xs">
          <Icon name="check" />
          <Typography bold>{t('TOAST.ID_COPIED')}</Typography>
        </Flex>
      ),
    });
    close();
  };

  return (
    <Flex className={styles.container}>
      <OverlayLayout>
        {name ? (
          <OverlayLayout.Header
            headerActions={
              <Flex flexDirection="row" alignItems="center" gap="xs">
                <Tag text={userRole} variant="generic" />
                <Dropdown.Menu
                  placement="bottom-end"
                  inline
                  onClick={(e) => e.stopPropagation()}
                  trigger={
                    <Button
                      size="small"
                      variant="link"
                      mode="primary"
                      aria-label="actions-current-tenant"
                      icon={<Icon name="more" />}
                    />
                  }
                >
                  {({ closeDropdown }) => (
                    <Flex
                      justifyContent="center"
                      flexDirection="column"
                      className={styles.actionsList}
                    >
                      <TooltipAction
                        name={t('TENANT_SELECTOR.UPDATE_DEFAULT')}
                        disabled={
                          userTenants[currentTenant.id]?.isDefault || isPending
                        }
                        className={styles.actionItem}
                        onClick={(e) =>
                          handleUpdateTenant(e, currentTenant.id, closeDropdown)
                        }
                      />
                      <TooltipAction
                        name={t('BUTTONS.COPY_ID')}
                        className={styles.actionItem}
                        onClick={(e) =>
                          handleCopyTenant(e, currentTenant.id, closeDropdown)
                        }
                      />
                    </Flex>
                  )}
                </Dropdown.Menu>
              </Flex>
            }
          >
            <Flex flexDirection="row" gap="sm" alignItems="center">
              <OrganizationIcon
                tenantLogo={tenantLogo}
                tenantName={name}
                isDefault={userTenants[currentTenant.id]?.isDefault}
              />
              <Flex
                flexDirection="column"
                className={styles.currentInnerContainer}
              >
                <Typography bold>{name}</Typography>
                <Typography color="disabled">
                  {t('TENANT_SELECTOR.CURRENT')}
                </Typography>
              </Flex>
            </Flex>
          </OverlayLayout.Header>
        ) : null}
        {tenants.length >= 10 ? (
          <OverlayLayout.Search
            onSearch={handleSearch}
            placeholder={t('TENANT_SELECTOR.SEARCH')}
          />
        ) : null}
        {filteredTenantList && filteredTenantList.length > 0 ? (
          <OverlayLayout.Body
            border={false}
            paddingY="none"
            paddingX="none"
            padding="none"
          >
            <Flex
              as="ul"
              flexDirection="column"
              className={styles.tenantContainer}
            >
              {filteredTenantList.map(({ id, name }) => (
                <Flex
                  key={id}
                  data-cy={`tenant-selector-list-button-${id}`}
                  as="li"
                  flexDirection="row"
                  gap="xs"
                  alignItems="center"
                  className={styles.tenantItem}
                  onClick={handleTenantClick(id)}
                >
                  <OrganizationIcon
                    tenantLogo={`${getEnvSync('S3_ASSETS_URL')}/tenants/${name}.svg`}
                    tenantName={name}
                    isDefault={userTenants[id]?.isDefault}
                  />
                  <Flex
                    flexDirection="column"
                    justifyContent="spaceBetween"
                    className={styles.info}
                  >
                    <Typography
                      bold
                      data-cy={`tenant-selector-list-name-${id}`}
                    >
                      {name}
                    </Typography>
                    {userTenants[id]?.isDefault ? (
                      <Typography color="disabled">
                        {t('TENANT_SELECTOR.DEFAULT')}
                      </Typography>
                    ) : null}
                  </Flex>
                  <Dropdown.Menu
                    inline
                    placement="bottom-end"
                    onClick={(e) => e.stopPropagation()}
                    trigger={
                      <Button
                        size="small"
                        variant="link"
                        mode="primary"
                        aria-label={`actions-${id}-tenant`}
                        icon={<Icon name="more" />}
                      />
                    }
                  >
                    {({ closeDropdown }) => (
                      <Flex
                        justifyContent="center"
                        flexDirection="column"
                        className={styles.actionsList}
                      >
                        <TooltipAction
                          name={t('TENANT_SELECTOR.UPDATE_DEFAULT')}
                          disabled={userTenants[id]?.isDefault || isPending}
                          className={styles.actionItem}
                          onClick={(e) =>
                            handleUpdateTenant(e, id, closeDropdown)
                          }
                        />
                        <TooltipAction
                          name={t('BUTTONS.COPY_ID')}
                          className={styles.actionItem}
                          onClick={(e) =>
                            handleCopyTenant(e, id, closeDropdown)
                          }
                        />
                      </Flex>
                    )}
                  </Dropdown.Menu>
                </Flex>
              ))}
            </Flex>
          </OverlayLayout.Body>
        ) : null}
      </OverlayLayout>
    </Flex>
  );
};

export default TenantSelectorOverlay;
