import { emptyFn, invoke } from '@nstrlabs/utils';
import { useCallback, useMemo } from 'react';
import { container } from '../../context';
import { isUnauthorizedError } from '../../context/auth/application/onIdentityProviderTokenChange';
import type { ErrorAccountNotAuthorized } from '../../context/auth/domain/AuthRepository';
import type { Token } from '../../context/auth/domain/Token';

type Params = {
  onTokenChange: (token: Token | null) => void;
  onUnauthorized: (error: ErrorAccountNotAuthorized) => void;
  onError: (error: Error) => void;
};

type Output = [addListener: () => void, removeListeners: () => void];

const useLoginOnIdentityProviderTokenChange = ({
  onTokenChange,
  onUnauthorized = emptyFn,
  onError = emptyFn,
}: Params): Output => {
  const unsubscriptions = useMemo(() => [emptyFn], []);

  const addIdentityProviderTokenChangeListener = useCallback(() => {
    container
      .call('auth:onIdentityProviderTokenChange', {
        observer: (token, error) => {
          if (error != null) {
            if (isUnauthorizedError(error as ErrorAccountNotAuthorized)) {
              onUnauthorized(error as ErrorAccountNotAuthorized);
            } else onError(error as Error);
          } else onTokenChange(token);
        },
      })
      .then((unsubscription: () => void) => {
        unsubscriptions.push(unsubscription);
      })
      .catch((e: Error) => {
        onError(e);
      });
  }, [onError, onTokenChange, onUnauthorized, unsubscriptions]);
  const removeListeners = () => {
    unsubscriptions.forEach(invoke);
    unsubscriptions.length = 0;
  };

  return [addIdentityProviderTokenChangeListener, removeListeners];
};

export default useLoginOnIdentityProviderTokenChange;
