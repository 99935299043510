import { type ChangeEvent, useCallback, useState } from 'react';

import {
  type InputError,
  type Validations,
  validate,
} from '../utils/validations';

const DEFAULT_VALIDATIONS: Validations = [];

export type InputTextHandler = [
  string,
  (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void,
  InputError[],
];
export const useInputText = (
  defaultValue: string,
  validations = DEFAULT_VALIDATIONS,
  validateOnInit = false,
): InputTextHandler => {
  const [value, setValue] = useState(defaultValue);
  const [errors, setErrors] = useState<InputError[]>(() =>
    validateOnInit ? validate(validations, defaultValue) : [],
  );

  const handleSetValue = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      event.preventDefault();

      setValue(event.target.value);
      setErrors(validate(validations, event.target.value));
    },
    [validations],
  );

  return [value || defaultValue, handleSetValue, errors];
};
