import { Flex, useMeasure } from '@nstrlabs/ixel';
import type { BackgroundColorType } from '@nstrlabs/ixel/dist/atoms/Avatar';
import { hashCode } from '@nstrlabs/utils';
import { useMemo, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useMetadata } from '../../../hooks/actions/useMetadata';
import { useConsoleSettings } from '../../../hooks/settings/useConsoleSettings';
import { useUser } from '../../../hooks/useUser';
import Settings from '../../../pages/Settings';
import Menu from '../../organisms/Menu';
import Sidebar from '../../organisms/Sidebar';
import styles from './index.module.css';

const userColors: BackgroundColorType[] = [
  'darkViolet',
  'violet',
  'blue',
  'green',
  'gray',
  'darkGreen',
  'orange',
  'brown',
  'red',
  'pink',
];

const MainLayout = () => {
  const { consoleSettingsIsOpen } = useConsoleSettings();
  const user = useUser();
  const [allRect, allRef] = useMeasure();
  const [mainRect, mainRef] = useMeasure();
  const params = useParams();
  const [applyTransition, setApplyTransition] = useState(
    Object.keys(params).length !== 0, // true = details view, false = summary view
  );
  useMetadata();

  const usernameParts = user.UserName.split(' ');
  const firstName = usernameParts.length > 1 ? usernameParts[0] : '';
  const lastName = usernameParts.length > 1 ? usernameParts[1] : '';

  const handleTransition = (state: boolean) => setApplyTransition(state);

  const userColor: BackgroundColorType = useMemo(
    () => userColors[Math.abs(hashCode(`${user.Email}`) % userColors.length)],
    [user.Email],
  );

  return (
    <Flex className={styles.container} ref={allRef}>
      {consoleSettingsIsOpen ? <Settings /> : null}
      <Sidebar
        user={{ lastName, firstName, email: user.Email, color: userColor }}
      />
      <Menu />
      <Flex
        as="main"
        className={styles.main}
        justifyContent="flexEnd"
        ref={mainRef}
      >
        <Outlet
          context={{
            allContentWidth: allRect.width,
            mainContentWidth: mainRect.width,
            applyTransition,
            handleTransition,
          }}
        />
      </Flex>
    </Flex>
  );
};

export default MainLayout;
