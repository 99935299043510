import { Button, Flex, Typography } from '@nstrlabs/ixel';
import { useTranslation } from 'react-i18next';
import styles from './index.module.css';

type GenericErrorProps = {
  resetErrorBoundary: () => void;
};
const GenericError = ({ resetErrorBoundary }: GenericErrorProps) => {
  const { t } = useTranslation();

  return (
    <section className={styles.container}>
      <Flex
        role="alert"
        flexDirection="column"
        alignItems="flexStart"
        className={styles.notFound}
      >
        <div className={styles.content}>
          <Flex flexDirection="column" className={styles.explanation}>
            <Flex
              flexDirection="column"
              justifyContent="center"
              className={styles.info}
            >
              <Typography
                as="h2"
                className={styles.state}
                variant="header"
                size="large"
                bold
              >
                {t('GENERIC_ERROR.TITLE')}
              </Typography>
            </Flex>
            <Button
              variant="solid"
              style={{ width: 'fit-content' }}
              onClick={resetErrorBoundary}
            >
              {t('GENERIC_ERROR.BUTTONS.BACK')}
            </Button>
          </Flex>
        </div>
      </Flex>
    </section>
  );
};

export default GenericError;
