import type { IconName } from '@nstrlabs/ixel';
import { useCanAccess } from '@nstrlabs/sdk';
import {
  CONTROL_ACCESS_USERS,
  ORGANIZATION_SETTINGS_SECRET_MANAGEMENT,
  TENANT_ACTIVITY,
  YOUR_PREFERENCES_EMAIL_PASSWORD,
} from './menuOptions';

export type MenuOption = { value: string; label: string; resource: string };
type MenuSection = {
  icon: IconName;
  label: string;
  menu_options: MenuOption[];
};
export type TypeMenuSettings = Record<string, MenuSection>;

const REQUIRED_READ_PERMISSIONS = ['activity'];

/**
 * The definition of the menu with the specified resource to have access to.
 * If no resource defined, the option menu have to be created.
 */
export const resourceOptions: TypeMenuSettings = {
  your_preferences: {
    icon: 'user',
    label: 'SETTINGS.MENU_SECTIONS.YOUR_PREFERENCES',
    menu_options: [YOUR_PREFERENCES_EMAIL_PASSWORD],
  },
  organization_settings: {
    icon: 'settings',
    label: 'SETTINGS.MENU_SECTIONS.ORGANIZATION_SETTINGS',
    menu_options: [ORGANIZATION_SETTINGS_SECRET_MANAGEMENT],
  },
  tenant: {
    icon: 'home',
    label: 'SETTINGS.MENU_SECTIONS.TENANT',
    menu_options: [CONTROL_ACCESS_USERS, TENANT_ACTIVITY],
  },
};

/**
 * Extracted functionality to handle the testing purposes
 * Return the list of all main menu options available for the current user with the current
 * role and access settings.
 * @param canAccess hook to know whether the user can access to the action-resource
 * @returns list of all menu options available for the current user
 */
export const buildAccessOptions = (
  availableOptions: TypeMenuSettings,
  canAccess: (accessObj: { action: string; resource: string }) => boolean,
): TypeMenuSettings =>
  Object.entries(availableOptions).reduce((filteredOptions, [key, section]) => {
    const filteredMenuOptions = section.menu_options.filter((option) => {
      return (
        !option.resource ||
        (REQUIRED_READ_PERMISSIONS.includes(option.resource) &&
          canAccess({ action: 'read', resource: option.resource })) ||
        canAccess({ action: 'update', resource: option.resource })
      );
    });

    if (filteredMenuOptions.length > 0) {
      return {
        ...filteredOptions,
        [key]: { ...section, menu_options: filteredMenuOptions },
      };
    }
    return filteredOptions;
  }, {});

/**
 * Returns the list of menu options for the current user checking
 * the allowed resources of the current user
 * */
export const useSettingsOptions = () => {
  const { canAccess } = useCanAccess();
  return buildAccessOptions(resourceOptions, canAccess);
};
