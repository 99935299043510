const VALID_THEMES = ['light', 'dark'];
const VALID_KEYBOARD_SHORTCUTS = ['on', 'off'];
const VALID_TABLE_DENSITY = ['comfortable', 'compact'];

export interface UserSettings {
  theme: 'light' | 'dark';
  keyboardShortcuts: 'on' | 'off';
  tableDensity: 'comfortable' | 'compact';
}

export class InvalidUserSettingsError extends Error {
  constructor(key: string, value: unknown) {
    super(
      `Error: Invalid user settings, expected a non-empty valid string. Received ${key}: ${
        value as string
      }`,
    );
  }
}

export class FetchUserSettingsError extends Error {
  constructor() {
    super('Error: User settings could not be retrieved');
  }
}

export const DEFAULT_SETTINGS: UserSettings = {
  theme: 'dark',
  keyboardShortcuts: 'on',
  tableDensity: 'comfortable',
};

export type UserSettingPrimitives = {
  theme?: string;
  keyboardShortcuts?: string;
  tableDensity?: string;
};

export function fromPrimitives({
  theme,
  keyboardShortcuts,
  tableDensity,
}: UserSettingPrimitives): UserSettings {
  if (typeof theme !== 'string' || !VALID_THEMES.includes(theme)) {
    throw new InvalidUserSettingsError('theme', theme);
  }

  if (
    typeof keyboardShortcuts !== 'string' ||
    !VALID_KEYBOARD_SHORTCUTS.includes(keyboardShortcuts)
  ) {
    throw new InvalidUserSettingsError('keyboardShortcuts', keyboardShortcuts);
  }

  if (
    typeof tableDensity !== 'string' ||
    !VALID_TABLE_DENSITY.includes(tableDensity)
  ) {
    throw new InvalidUserSettingsError('tableDensity', tableDensity);
  }

  return {
    theme,
    keyboardShortcuts,
    tableDensity,
  } as UserSettings;
}
