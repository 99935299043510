import { Toast } from '@nstrlabs/ixel';
import { invoke } from '@nstrlabs/utils';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import styles from './index.module.css';
import { type ToastCommand, parseToastCommand } from './parseToastCommand';

export type ToastLiveProps = {
  className?: string;
  selector?: string;
  source: {
    subscribe: (fn: (toast: unknown) => void) => () => void;
  };
};

/**
 * Pass a customized id in case you want
 * your toast not repeat on the same action
 */
const ToastLive = ({
  className: containerClassName,
  selector,
  source,
}: ToastLiveProps) => {
  const [toastStack, setToastStack] = useState<ToastCommand[]>([]);
  const root = useMemo(
    () => (selector ? document.querySelector(selector) : null),
    [selector],
  );
  const removeToast = (toasts: ToastCommand[], toastCommand: ToastCommand) =>
    toasts.filter(({ id }) => id !== toastCommand.id);
  const mergeDuplicated = (
    commands: ToastCommand[],
    newCommand: ToastCommand,
  ) =>
    commands.reduce(
      (acc, current) =>
        current.id === newCommand.id ? [...acc] : [...acc, current],
      [],
    );

  // biome-ignore lint/correctness/useExhaustiveDependencies: to maintain the operation
  useEffect(
    () =>
      source.subscribe((toast) => {
        const { onClose, action, channel, ...validCommand } = parseToastCommand(
          toast as ToastCommand,
        );
        if (channel === 'live') {
          const removeFromStack = () => {
            setToastStack((commands) => removeToast(commands, validCommand));
            invoke(onClose);
          };
          setToastStack((commands) => {
            if (action?.text === 'remove')
              return removeToast(commands, validCommand);

            return [
              ...mergeDuplicated(commands, validCommand),
              { ...validCommand, onClose: removeFromStack },
            ];
          });
        }
      }),
    [source],
  );

  const rootClassName = clsx(
    styles.appToastSectionLive,
    styles.toastRoot,
    containerClassName,
  );

  const render = (
    <section role="alert" className={rootClassName}>
      {toastStack.map(
        ({ id, level, action, onClose, className, customRender, content }) => (
          <Toast
            variant="live"
            key={id}
            data-testid={id}
            level={level}
            className={className}
            onClose={onClose}
            action={action}
          >
            {content || customRender}
          </Toast>
        ),
      )}
    </section>
  );

  if (root !== null) {
    return createPortal(render, root);
  }

  return render;
};

export default ToastLive;
