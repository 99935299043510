export type FeatureFlags = FeatureFlagsPrimitives;

export class FetchFeatureFlagsError extends Error {
  constructor() {
    super('Error: Feature flags could not be retrieved');
  }
}

export type FeatureFlagsPrimitives = Record<string, boolean>;

export function fromPrimitives(
  featureFlags: FeatureFlagsPrimitives,
): FeatureFlags {
  return featureFlags;
}
