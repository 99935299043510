import { useUserSettings } from '../../../hooks/settings/useUserSettings';
import styles from './index.module.css';
import { loaderDark, loaderLight } from './logo';

const Loading = () => {
  const { settings } = useUserSettings();
  const icon = settings.theme === 'dark' ? loaderDark : loaderLight;

  return (
    <div className={styles.container} data-testid="loading" data-theme="dark">
      <icon.ReactComponent viewBox="0 0 140 140" width="100" height="100" />
    </div>
  );
};

export default Loading;
