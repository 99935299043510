import { Either } from '@nstrlabs/utils';
import {
  GoogleAuthProvider,
  OAuthProvider,
  type User,
  getAuth,
  getRedirectResult,
  onIdTokenChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from 'firebase/auth';

import jwtDecode from 'jwt-decode';
import { firebaseApp } from '../../../utils/firebase';
import type { HeaderMiddleware } from '../../shared/infrastructure/customFetch';
import {
  type AuthRepository,
  errorAccountNotAuthorized,
  errorAlreadyExistsWithDifferentCredential,
  errorUserAndPasswordNotMatch,
} from '../domain/AuthRepository';
import { makeToken } from '../domain/Token';

const getFirebaseAuth = () => getAuth(firebaseApp);

function getGoogleProvider() {
  const provider = new GoogleAuthProvider();
  provider.addScope('profile');
  provider.addScope('email');
  return provider;
}

function getMicrosoftProvider() {
  const provider = new OAuthProvider('microsoft.com');
  provider.setCustomParameters({
    prompt: 'consent',
  });
  return provider;
}

type Providers = {
  [index: string]: GoogleAuthProvider | OAuthProvider;
  google: GoogleAuthProvider;
  microsoft: OAuthProvider;
};
export const PROVIDERS: Providers = {
  google: getGoogleProvider(),
  microsoft: getMicrosoftProvider(),
};

export const firebaseLoginWithEmailAndPassword: AuthRepository['loginWithEmailAndPassword'] =
  (email, password) =>
    signInWithEmailAndPassword(getFirebaseAuth(), email, password)
      .catch(() => Promise.reject(errorUserAndPasswordNotMatch()))
      .then(async (credentials) =>
        Either.toPromise(makeToken(await credentials.user.getIdToken())),
      );

export const firebaseLoginWithIdentityProvider: AuthRepository['loginWithIdentityProvider'] =
  (provider) =>
    signInWithPopup(getFirebaseAuth(), PROVIDERS[provider]).then(() =>
      Promise.resolve(),
    );

export const firebaseLogout: AuthRepository['logout'] = () =>
  signOut(getFirebaseAuth());

export const firebaseLoginRedirectResult: AuthRepository['loginRedirectResult'] =
  () =>
    getRedirectResult(getFirebaseAuth())
      .catch((error) => {
        const ERROR_ALREADY_USED = [
          'auth/credential-already-in-use',
          'auth/account-exists-with-different-credential',
          'auth/email-already-in-use',
        ];

        if (ERROR_ALREADY_USED.includes(error.code))
          return Promise.reject(errorAlreadyExistsWithDifferentCredential());
        return Promise.reject(errorAccountNotAuthorized());
      })
      .then(async (credentials) => {
        if (credentials == null) return null;
        return Either.toPromise(makeToken(await credentials.user.getIdToken()));
      });

export const firebaseOnIdentityProviderTokenChange: AuthRepository['onIdentityProviderTokenChange'] =
  (observer) => {
    let timeoutId: NodeJS.Timeout;
    const unsubscribe = onIdTokenChanged(getFirebaseAuth(), (user: User) => {
      if (user == null) observer(null);
      else {
        user.getIdToken().then((accessToken) => {
          const maybeToken = makeToken(accessToken);
          const { exp } = jwtDecode<{ exp: number }>(accessToken);
          const expTimeout = exp * 1000 - Date.now() - 5000;

          timeoutId = setTimeout(
            () => user.getIdToken(true),
            expTimeout > 0 ? expTimeout : 100,
          );

          if (!Either.isLeft(maybeToken)) observer(maybeToken.getValue());
        });
      }
    });

    return () => {
      clearTimeout(timeoutId);
      unsubscribe();
    };
  };

export const firebaseTokenMiddleware: HeaderMiddleware = async (header) => {
  const firebaseToken = await getAuth().currentUser?.getIdToken();

  if (firebaseToken === undefined) {
    throw new Error();
  }

  header.set('Authorization', `Bearer ${firebaseToken}`);
  return header;
};
