import FilterGenericNumber from '../FilterGenericNumber';
import type { FilterWithOperation } from '../FiltersProvider';

type Operator = {
  value: 'float_equals';
  label: '=';
};

type Operators = Operator[];

const options: Operators = [{ value: 'float_equals', label: '=' }];

export type FilterFloatProps = React.HTMLAttributes<HTMLDivElement> & {
  filter: FilterWithOperation<'float', number>;
  buttonName: string;
  onDelete: () => void;
  handleSaveFilters: (
    filterToSave: FilterWithOperation<'float', number>,
  ) => void;
};

const FilterFloat = ({
  filter,
  buttonName,
  onDelete,
  handleSaveFilters,
}: FilterFloatProps) => (
  <FilterGenericNumber
    filter={filter}
    buttonName={buttonName}
    onDelete={onDelete}
    handleSaveFilters={handleSaveFilters}
    options={options}
  />
);

export default FilterFloat;
