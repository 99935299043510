import { useQuery, useQueryClient } from '@tanstack/react-query';
import type { ContainerUseCase } from '../../../context';
import type { SecretsNotFoundError } from '../../../context/settings/domain/Secret';
import { fetchConfiguration } from '../../../utils/fetchConfiguration';
import { useContainer } from '../../useContainer';

export type UseSecrets = {
  secrets: ContainerUseCase<'organizationSettings:secret:getSecrets', 'result'>;
  isLoading: boolean;
  cancelSecrets: () => Promise<void>;
};

export function useSecrets(loadQuery = true): UseSecrets {
  const container = useContainer();
  const queryClient = useQueryClient();
  const { data = [], isLoading } = useQuery({
    enabled: loadQuery,
    queryKey: ['secrets'],
    queryFn: () => container.call('organizationSettings:secret:getSecrets'),
    // TODO Remove refetchInterval&retry when backend sends a 200 status on empty
    refetchInterval: ({ state: { fetchFailureReason } }) =>
      (fetchFailureReason as unknown as SecretsNotFoundError)?.context
        ?.cause === 404
        ? false
        : fetchConfiguration.REFETCH_INTERVAL,
    retry: (_failureCount, failureError) =>
      (failureError as unknown as SecretsNotFoundError).context?.cause !== 404,
  });

  return {
    secrets: data,
    isLoading,
    cancelSecrets: () => queryClient.cancelQueries({ queryKey: ['secrets'] }),
  };
}
